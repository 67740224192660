<div *ngIf="course" fxLayout="column" fxLayoutGap="20px" class="component-main">
    <app-image-header
        [hashTags]="course.hashTag"
        [creationDate]="course.created"
        [imageUrl]="course.imageUrl"
        [header]="course.header"
        [title]="course.name"
        [footer]="course.footer"
        [description]="course.description"
        [price]="getCoursePrice()">
    </app-image-header>
    <div>
        <div fxLayout="row" style="width: 80vw; margin: 0 auto;">
            <div fxFlex="40" fxLayoutAlign="start center" fxLayoutGap="30px">
                <button mat-stroked-button (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ 'Back' | translate }}
                </button>
                <mat-form-field style="margin-top: 15px;" *ngIf="course.mode === 0" color="primary">
                    <mat-label>{{ 'Order' | translate }}</mat-label>
                    <mat-select [(ngModel)]="filter" (selectionChange)="sortContent()">
                        <mat-option [value]="0">{{ 'Priority' | translate }}</mat-option>
                        <mat-option [value]="1">{{ 'Name' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="center center" fxLayoutGap="30px" fxFlex>
                <button mat-fab color="primary" (click)="getContent()" [matTooltip]="'Refresh' | translate">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button mat-fab (click)="contentsMode = (contentsMode === 'cards' ? 'table' : 'cards')" [matTooltip]="'Change contents view' | translate" 
                    color="primary">
                    <mat-icon>view_list</mat-icon>
                </button>
            </div>
            <div fxLayoutAlign="end center" fxFlex="40">
                <button mat-stroked-button [cdkCopyToClipboard]="href">
                    <mat-icon>content_copy</mat-icon>
                    {{ 'Copy link' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
        
        <button mat-fab extended color="primary" (click)="addSubscription()" *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled">   
            <mat-icon>monetization_on</mat-icon>
            {{ (course.idSubscription ? 'Edit price' : 'Set price')  | translate }}
        </button>

        <button mat-fab extended color="accent"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled"
            (click)="addContent()"
            [matTooltip]="'Add content' | translate">
            <mat-icon>add</mat-icon>
            {{ 'Content' | translate }}
        </button>
        <button mat-fab extended color="accent"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled && course.mode === 0"
            (click)="addContent('folder')"
            [matTooltip]="'Add folder' | translate">
            <mat-icon>add</mat-icon>
            {{ 'Folder' | translate }}
        </button>
        <button mat-fab extended color="accent"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled"
            [disabled]="true"
            (click)="addContent('scorm')"
            [matTooltip]="'Add scorm' | translate">
            <mat-icon>add</mat-icon>
            Scorm
        </button>
        <button mat-fab extended color="primary"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled"
            (click)="addSurvey()"
            [matTooltip]="'Add survey' | translate">
            <mat-icon>add</mat-icon>
            {{ 'Survey' | translate }}
        </button>
        <mat-slide-toggle color="primary" [checked]="course.state ? true : false" [disabled]="changingState"
            (change)="toggleCourse()"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled">
            {{ course.state ? 'Online' : 'Offline' }}
        </mat-slide-toggle>
        <button mat-fab color="primary"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled"
            (click)="copyCourse()"
            [matTooltip]="'Copy' | translate">
            <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-fab color="primary" (click)="updateCourse()" [matTooltip]="'Edit' | translate"
            *ngIf="currentUser.id === course.idAuthor && currentUser.coursesEnabled">
            <mat-icon matListIcon>edit</mat-icon>
        </button>
        <button mat-fab color="warn" (click)="deleteCourse()"  [matTooltip]="'Delete' | translate"
            *ngIf="(currentUser.id === course.idAuthor  && currentUser.coursesEnabled)">
            <mat-icon matListIcon>delete</mat-icon>
        </button>
        <!--button mat-fab (click)="deleteCoursePermanent()" [matTooltip]="'Delete permanently' | translate"
            *ngIf="currentUser.isAdmin" style="background-color: black;">
            <mat-icon matListIcon style="color: white;">warning</mat-icon>
        </button-->
        <button mat-fab color="primary" (click)="analyticsCourse(course.id)" [matTooltip]="'Go to report' | translate"
            [disabled]="course.state !== 1"
            *ngIf="currentUser.id === course.idAuthor">
            <mat-icon>bar_chart</mat-icon>
        </button>
    </div>

    <div *ngIf="contentsMode === 'cards'" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">

        <app-content-card *ngFor="let content of contents"
            [courseAuthorId]="course.idAuthor"
            [courseMode]="course.mode"
            [courseState]="course.state"
            [contents]="contents"
            [content]="content"
            (onDelete)="getContent()">
        </app-content-card>

    </div>

    <app-content-table *ngIf="contentsMode === 'table'"
        [courseAuthorId]="course.idAuthor"
        [courseMode]="course.mode"
        [courseState]="course.state"
        [contents]="contents"
        (onSave)="getContent()"
        (onDelete)="getContent()">
    </app-content-table>

    <div *ngIf="contents.length == 0" fxLayoutAlign="center center">
        <app-error-message customClass="warning"
            [errorTitle]="'There are no contents' | translate"
            [errorContent]="'In this course there are no contents, try adding some using the yellow button' | translate">
        </app-error-message>
    </div>
</div>
