import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";

export class EnvironmentVariables {
    apiUrl: string;
    production: boolean;
    mode: 'aixp' | 'saratoga';
    appVersion: string;
    appDate: string;
    supportsBidirectional: boolean;
    useNativePip4Student: boolean;
    loginBackgroundPicture: string;
    applicationName: string;
    applicationNameShort: string;
    applicationMotto: string;
    applicationFavicon: string;
    applicationCompanyName: string;
    applicationCompanyUrl: string;
    applicationCompanyPhone: string;
    applicationCompanyLogo: string;
    applicationCompanyLogoWhite: string;
    applicationPlayStoreUrl: string;
    applicationAppStoreUrl: string;
    applicationPrivacyPolicy: string;
    applicationCookiePolicy: string;
    mainPage: string;
    surveyJsKey: string;
    facebookAppId: string;
    googleClientId: string;
    configs: EnvironmentCustomerConfig[] = [];    
}

export class EnvironmentCustomerConfig {
    id: EnvironmentCustomerType;
    title?: string = undefined;
    url?: string = undefined;
    name?: string = undefined;
    phone?: string = undefined;
    icon?: string = undefined;
    logo?: string = undefined;
    logoDark?: string = undefined;
    logoLight?: string = undefined;
    backgroundImage?: string = undefined;
    backgroundImageMobile?: string = undefined;
    privacyPolicy?: string = undefined;
    cookiePolicy?: string = undefined;
    playStore?: string = undefined;
    appStore?: string = undefined;
}

export enum EnvironmentCustomerType {
    GammaDonna,
    DentalTrey,
    ViqiRobot,
    Sunlight,
    SmartCabinet,
    Fitxp,
    Hro,
    HuFriedy,
    AixpAcademy
}

export enum EnvironmentParameterType {
    Title,
    Url,
    Name,
    Phone,
    Icon,
    Logo,
    LogoDark,
    LogoLight,
    BackgroundImage,
    BackgroundImageMobile,
    Privacy,
    Cookie,
    PlayStore,
    AppStore
}

export class EnvironmentHelper {

    public static isGD() {
        return window.location.origin.startsWith('https://gammadonna.aixp.io');
    }

    public static isDT() {
        return window.location.origin.startsWith('https://dentaltreyelearning.aixp.io');
    }

    public static isVR() {
        return window.location.origin.startsWith('https://viqirobot.aixp.io');
    }

    public static isSL() {
        return window.location.origin.startsWith('https://sunlightacademy.aixp.io');
    }

    public static isSC360() {
        return window.location.origin.startsWith('https://smartcabinet360.aixp.io');
    }

    public static isFitxp() {
        return window.location.origin.startsWith('https://fitxp.io');
    }

    public static isHRO() {
        return window.location.origin.startsWith('https://hroconsulting.aixp.io');
    }

    public static isHF() {
        return window.location.origin.startsWith('https://trackandtrace.aixp.io');
    }

    public static isAA() {
        return window.location.origin.startsWith('https://academy.aixp.io');
    }

    public static isDev() {
        return (window.location.origin.startsWith('http://dev.s-teach.com') || window.location.origin.startsWith('https://dev2.s-teach.com'));
    }

    public static getConfig(code: EnvironmentCustomerType, param: EnvironmentParameterType) {

        let config = environment.configs.find(c => c.id === code);

        if (param === EnvironmentParameterType.Title)
            return config?.title ?? environment.applicationName;

        if (param === EnvironmentParameterType.Url)
            return config?.url ?? environment.applicationCompanyUrl;

        if (param === EnvironmentParameterType.Name)
            return config?.name ?? environment.applicationCompanyName;

        if (param === EnvironmentParameterType.Phone)
            return config?.phone ?? environment.applicationCompanyPhone;

        if (param === EnvironmentParameterType.Icon)
            return config?.icon ?? environment.applicationFavicon;

        if (param === EnvironmentParameterType.Logo)
            return config?.logo ?? environment.applicationCompanyLogo;

        if (param === EnvironmentParameterType.LogoDark)
            return config?.logoDark ?? environment.applicationCompanyLogo;

        if (param === EnvironmentParameterType.LogoLight)
            return config?.logoLight ?? environment.applicationCompanyLogoWhite;

        if (param === EnvironmentParameterType.BackgroundImage)
            return config?.backgroundImage ?? environment.loginBackgroundPicture;
        
        if (param === EnvironmentParameterType.BackgroundImageMobile)
            return config?.backgroundImageMobile ?? environment.loginBackgroundPicture;

        if (param === EnvironmentParameterType.Privacy)
            return config?.privacyPolicy ?? environment.applicationPrivacyPolicy;

        if (param === EnvironmentParameterType.Cookie)
            return config?.cookiePolicy ?? environment.applicationCookiePolicy;

        if (param === EnvironmentParameterType.PlayStore)
            return config?.playStore ?? environment.applicationPlayStoreUrl;

        if (param === EnvironmentParameterType.AppStore)
            return config?.appStore ?? environment.applicationAppStoreUrl;

        return undefined;

    }

    public static getConfigAuto(auth: AuthService, param: EnvironmentParameterType) {

        let code: EnvironmentCustomerType = auth.isGD()
                                          ? EnvironmentCustomerType.GammaDonna
                                          : auth.isDT()
                                          ? EnvironmentCustomerType.DentalTrey
                                          : auth.isVR()
                                          ? EnvironmentCustomerType.ViqiRobot
                                          : auth.isSL()
                                          ? EnvironmentCustomerType.Sunlight
                                          : auth.isSC360()
                                          ? EnvironmentCustomerType.SmartCabinet
                                          : auth.isFitxp()
                                          ? EnvironmentCustomerType.Fitxp
                                          : auth.isHRO()
                                          ? EnvironmentCustomerType.Hro
                                          : auth.isHR()
                                          ? EnvironmentCustomerType.HuFriedy
                                          : auth.isAA()
                                          ? EnvironmentCustomerType.AixpAcademy
                                          : undefined;

        return EnvironmentHelper.getConfig(code, param);

    }

    public static isAixp() {
        return environment.mode === 'aixp';
    }

    public static isSaratoga() {
        return environment.mode === 'saratoga';
    }

}
