<div class="component-main">
    <div style="text-align: center">
        <h2>{{ 'My masters' | translate }}</h2>
    </div>
    <div class="row-arrange">
        <mat-form-field color="primary">
            <mat-label>{{ 'Search' | translate }}</mat-label>
            <input matInput [formControl]="searchWordForm" (keyup)="applyFilter()">
            <button matSuffix mat-icon-button
                (click)="searchWordForm.setValue(undefined); applyFilter();"
                [disabled]="!searchWordForm.value"
                [matTooltip]="'Clear' | translate">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'Author' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="authorForm">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="-1">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let author of masterAuthors | search: searchForm.value: ['name']" [value]="author.id">
                    {{ author.name }} {{ author.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'Type' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="typeForm">
                <mat-option [value]="-1">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let type of types" [value]="type.value">
                    {{ type.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'State' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="stateForm">
                <mat-option [value]="-1">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let state of states" [value]="state.value">
                    {{ state.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-table #table multiTemplateDataRows #mastersSort="matSort" matSort [dataSource]="dataSource">

        <ng-container matColumnDef="plannedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Planned date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.startDate | date: 'dd/MM/yyyy' }} - {{ element.stopDate | date: 'dd/MM/yyyy' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Author' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.author.name }} {{ element.author.surname }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.name }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Description' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getTruncateString(element.description, "", 100) }}</mat-cell> 
        </ng-container>
    
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Price' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getPrice(element.subscription) | translate }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getType(element.type) | translate }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'State' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;" [style.color]="getState(element.state, 'color')">
                <span class="truncate-text">{{ getState(element.state, 'name') | translate }}</span>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="view">
            <mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">
                <button [matTooltip]="'View' | translate" (click)="goToMaster(element.id)" mat-icon-button>
                    <mat-icon>settings</mat-icon>
                </button>
                <button mat-icon-button
                    *ngIf="element.derivedMaster?.length > 0"
                    (click)="showDerivedMasters(element)"
                    [matTooltip]="(selectedMaster?.id === element?.id ? 'Collapse' : 'Expand') | translate">
                    <mat-icon>{{ selectedMaster?.id === element?.id ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div *ngIf="selectedMaster" class="element-detail" [@detailExpand]="selectedMaster.id === element.id ? 'expanded' : 'collapsed'">

                    <mat-table #table [dataSource]="derivedDataSource" style="padding: 20px 0 20px 0">

                        <ng-container matColumnDef="dates">
                            <mat-header-cell *matHeaderCellDef>{{ 'Planned date' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let element;">{{ element.startDate | date: 'dd/MM/yyyy' }} - {{ element.stopDate | date: 'dd/MM/yyyy' }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="user">
                            <mat-header-cell *matHeaderCellDef>{{ 'User' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let element;">{{ element.classroom.classroomContent[0].user.name + ' ' + element.classroom.classroomContent[0].user.surname }}</mat-cell> 
                        </ng-container>

                        <ng-container matColumnDef="derivedType">
                            <mat-header-cell *matHeaderCellDef>{{ 'Type' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let element;">{{ getType(element.type) | translate }}</mat-cell> 
                        </ng-container>
                
                        <ng-container matColumnDef="derivedState">
                            <mat-header-cell *matHeaderCellDef>{{ 'State' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let element;" [style.color]="getState(element.state, 'color')">
                                <span class="truncate-text">{{ getState(element.state, 'name') | translate }}</span>
                            </mat-cell> 
                        </ng-container>
                
                        <ng-container matColumnDef="view">
                            <mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let element;">
                                <button [matTooltip]="'View' | translate" (click)="goToMaster(element.id)" mat-icon-button>
                                    <mat-icon>settings</mat-icon>
                                </button>
                            </mat-cell> 
                        </ng-container>
            
                        <mat-header-row *matHeaderRowDef="derivedDisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: derivedDisplayedColumns;"></mat-row>
                    </mat-table>

                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>

        <tr class="mat-row" style="height: 40px" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns.length" style="vertical-align: middle">
                <span style="margin: 10px">
                    {{ 'No data' | translate }}
                </span>
            </td>
        </tr>

    </mat-table>

    <mat-paginator #mastersPaginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>
