<h1 mat-dialog-title>{{ 'Start your Easy Lesson' | translate }}</h1>
<div mat-dialog-content>
    <mat-tab-group [(selectedIndex)]="tabGlobalIndex" (selectedIndexChange)="tabChangePage($event)" color="accent">
        <mat-tab label="Standard">
            <div fxLayout="column" fxLayoutAlign="center stretch" class="row" *ngIf="!isScreenShare()">
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                    <label>{{ 'Quality' | translate }}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                    <mat-radio-group [formControl]="qualityForm" name="quality" id="quality" required>
                        <mat-radio-button *ngFor="let res of resolutions" [value]="res.key" [title]="res.value">
                            {{ res.title }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                    <label>{{ 'Streaming Options' | translate}}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                    <mat-checkbox *ngIf="!isScreenShare()" [(ngModel)]="joinWithVideo" id="joinWithVideo">Video</mat-checkbox>
                    <mat-checkbox [(ngModel)]="joinWithAudio" name="joinWithAudio">Audio</mat-checkbox>
                </div>
            </div>
            <br>
            <div fxLayout="column" fxLayoutAlign="center stretch" class="row" *ngIf="isEasyLesson">
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                    <mat-form-field style="width: 100%" color="accent">
                        <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
                        <mat-select [formControl]="classroomForm" (selectionChange)="checkClassLesson()" required>
                            <mat-option *ngFor="let class of classrooms" [value]="class.id">
                                {{ class.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="lessonOpenInClass != undefined" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" style="margin-bottom: 10px;">
                    <mat-error *ngIf="lessonOpenInClass != undefined" style="font-size: 12px;">
                        {{ 'Please select another classroom' | translate }},<br>
                        {{ 'there is a lesson already open by' | translate }} {{ lessonOpenInClass.teacher.surname + ' ' + lessonOpenInClass.teacher.name }} {{ 'in this classroom' | translate }}
                    </mat-error>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                    <mat-form-field style="width: 100%" color="accent">
                        <mat-label>{{ 'Video Device' | translate }}</mat-label>
                        <mat-select [formControl]="videoForm1" required>
                            <mat-option *ngFor="let dev of videoDeviceArr" [value]="dev.deviceId">
                                {{ dev.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                    <mat-form-field style="width: 100%" color="accent">
                        <mat-label>{{ 'Audio Device' | translate }}</mat-label>
                        <mat-select [formControl]="audioForm" required>
                            <mat-option *ngFor="let dev of audioDeviceArr" [value]="dev.deviceId">
                                {{ dev.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Scenario" [disabled]="isEasyLesson">
            <mat-tab-group [(selectedIndex)]="tabScenarioIndex" (selectedIndexChange)="tabScenarioIndex = $event" color="accent">
                <mat-tab [label]="'Global settings' | translate">
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row" style="margin-top: 10px;">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-form-field style="width: 100%" color="primary">
                                <mat-label>Scenario</mat-label>
                                <mat-select [formControl]="scenarioForm" required>
                                    <mat-option [value]="2">{{ 'Two sources' | translate }}</mat-option>
                                    <mat-option [value]="3">{{ 'Three sources' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                            <label>{{ 'Quality' | translate }}</label>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-radio-group [formControl]="qualityForm" name="quality1" id="quality1" required>
                                <mat-radio-button *ngFor="let res of resolutions" [value]="res.key" [title]="res.value">
                                    {{ res.title }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                            <label>{{ 'Streaming Options' | translate }}</label>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-checkbox *ngIf="!isScreenShare()" [(ngModel)]="joinWithVideo" id="joinWithVideo">Video</mat-checkbox>
                            <mat-checkbox [(ngModel)]="joinWithAudio" name="joinWithAudio">Audio</mat-checkbox>
                        </div>
                    </div>
                    <br>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-form-field style="width: 100%" color="primary">
                                <mat-label>{{ 'Audio Device' | translate }}</mat-label>
                                <mat-select [formControl]="audioForm" required>
                                    <mat-option *ngFor="let dev of audioDeviceArr" [value]="dev.deviceId">
                                        {{ dev.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxFlex class="btnNext">
                            <button mat-flat-button color="primary" (click)="tabScenarioIndex = tabScenarioIndex + 1" [disabled]="!qualityForm.valid || !scenarioForm.valid">{{ 'Next' | translate }}</button>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="('Video Options' | translate) + ' 1'">
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-form-field style="width: 100%" color="primary">
                                <mat-label>{{ 'Video Device' | translate }}</mat-label>
                                <mat-select [formControl]="videoForm1" required>
                                    <mat-option *ngFor="let dev of videoDeviceArr" [value]="dev.deviceId">
                                        {{ dev.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutAlign="center stretch">
                            <div fxFlex="50%" class="btnPrev">
                                <button mat-stroked-button (click)="tabScenarioIndex = tabScenarioIndex - 1">{{ 'Previous' | translate }}</button>
                            </div>
                            <div fxFlex class="btnNext">
                                <button mat-flat-button color="primary" (click)="tabScenarioIndex = tabScenarioIndex + 1" [disabled]="!videoForm1.valid">{{ 'Next' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="('Video Options' | translate) + ' 2'">
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row" *ngIf="scenarioForm.value > 1">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                            <label>{{ 'Video Options' | translate }} 2</label>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-form-field style="width: 100%" color="primary">
                                <mat-label>{{ 'Video Device' | translate }}</mat-label>
                                <mat-select [formControl]="videoForm2" required>
                                    <mat-option *ngFor="let dev of videoDeviceArr" [value]="dev.deviceId">
                                        {{ dev.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutAlign="center stretch">
                            <div fxFlex class="btnPrev">
                                <button mat-stroked-button (click)="tabScenarioIndex = tabScenarioIndex - 1">{{ 'Previous' | translate }}</button>
                            </div>
                            <div fxFlex="50%" class="btnNext" *ngIf="scenarioForm.value == 3">
                                <button mat-flat-button color="primary" (click)="tabScenarioIndex = tabScenarioIndex + 1" [disabled]="!videoForm2.valid">{{ 'Next' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="('Video Options' | translate) + ' 3'" [disabled]="scenarioForm.value === 2">
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row" *ngIf="scenarioForm.value > 2">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
                            <label>{{ 'Video Options' | translate }} 3</label>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                            <mat-form-field style="width: 100%" color="primary">
                                <mat-label>{{ 'Video Device' | translate }}</mat-label>
                                <mat-select [formControl]="videoForm3" required>
                                    <mat-option *ngFor="let dev of videoDeviceArr" [value]="dev.deviceId">
                                        {{ dev.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxFlex class="btnPrev">
                            <button mat-stroked-button (click)="tabScenarioIndex = tabScenarioIndex - 1">{{ 'Previous' | translate }}</button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close type="button" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" [disabled]="okBtnDisabled()" (click)="joinSession()">Ok</button>
</div>
