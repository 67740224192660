import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Connection, ConnectionEvent, Device, OpenVidu, Publisher, Session, SessionDisconnectedEvent, SignalEvent, StreamEvent, StreamManager } from 'openvidu-browser';
import { firstValueFrom } from 'rxjs';
import { NetworkInformation } from 'src/app/helpers/networkInformation';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { MIN_OPERATION_LOCK_TIME, STREAMING_QUALITY_CHECK_INTERVAL } from 'src/app/models/conference-session/conferenceConstants';
import { LessonContentEdit, LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { SourceSelection } from '../conference-session/source-selection/source-selection.component';
import { differenceInMinutes } from 'date-fns';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { MatDrawer } from '@angular/material/sidenav';
import { JoinSession } from 'src/app/models/joinSession';
import { BidirectionService } from 'src/app/services/bidirectional.service';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { ContentStream } from 'src/app/models/conference-session/contentStream';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

const CONTENT_TIME_ERROR: number = 1; //s
const CHECK_PUBLISHER_CONNECTED_TIMEOUT: number = 2000; //ms

@Component({
  selector: 'app-lesson-session',
  templateUrl: './lesson-session.component.html',
  styleUrl: './lesson-session.component.scss'
})
export class LessonSessionComponent implements OnInit, OnDestroy {

  id: number;
	currentUser: User;
  lesson: LessonSessionDTO;
  lessonStartDate: Date;

  OV: OpenVidu;
	session: Session;
  token: string;
  videoDevices: Device[] = [];
	audioDevices: Device[] = [];
	localParticipant: ClientStream;
  teachers: ClientStream[] = [];
  participants: ClientStream[] = [];
	localParticipantVideoDeviceId: string | false = null;
	localParticipantAudioDeviceId: string | false = null;
  localParticipantMirror: boolean = false;
  leaving: boolean = false;
  totalClients: number = 0;

  streamingQualityInterval: any = undefined;
  streamingQualityValues: string[] = [];
  validStreamingQualities: string[] = ['2g', '3g', '4g'];

  OVShare: OpenVidu[] = [undefined, undefined];
	sessionShare: Session[] = [undefined, undefined];
	localParticipantShare: ClientStream[] = [undefined, undefined];
	publisherShare: ClientStream[] = [undefined, undefined];
	localParticipantShareVideoDeviceId: string[] = [undefined, undefined];

  visualType: string;
	isFullScreen: boolean = false;
	hasScreenShareCapabilities: boolean = false;
  isRecording: boolean = false;

  initialSelection: boolean = true;
	lockJoin: boolean = true;
	switchPublisherLock: boolean = false;
  publisherAlreadyPresent: boolean = true;
  resolution: string = '1280x720';

  newPublisherMesagges: number = 0;
  newParticipantsMessages: number = 0;
	newHandNotifications: number = 0;

  publisherChatOpened: boolean = false;
  participantsChatOpened: boolean = false;
  participantsListOpened: boolean = false;

  contents: CourseContentDTO[] = [];
  currentContent: CourseContentDTO = null;
  currentContentMarker: number = 0;
  currentContentTime: number = 0;
  currentContentPause: boolean = false;

  private _isSpeaking: boolean = false;
	
  isHandRaised: boolean = false;
  currentChats: number = 0;

  @ViewChild('directionalChat') public directionalChat: MatDrawer;

  constructor(
    private routeActive: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private videoSessionService: VideoSessionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
    private navBar: NavBarService,
    private calendar: CalendarService,
    private platform: Platform,
    private bidirectionalService: BidirectionService,
    private darkThemeService: DarkThemeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.navBar.hide('all');
    this.auth.stopInactivityCheck();

    this.id = Number(this.routeActive.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();

    this.OV = new OpenVidu();

    try {

      this.lesson = await firstValueFrom(this.videoSessionService.getLesson(this.id));

      if (this.lesson.stopDate != null) {
        this.leave();
        return;
      }

      this.lessonStartDate = new Date(this.lesson.startDate);
      this.isRecording = this.lesson.recodingPlanned;
      
      if (this.isLessonPublisher() || this.isClassPublisher()) {

        this.contents = await firstValueFrom(this.calendar.getLessonContents(this.id));

        //this.publisherAlreadyPresent = this.routeActive.snapshot.queryParamMap.has('publisher');
        this.publisherAlreadyPresent = await this.checkForPublisherAlreadyConnected();

        if (!this.publisherAlreadyPresent) {
          let devices = await this.OV.getDevices();
  
          this.videoDevices = [{ kind: 'videoinput', deviceId: 'screen', label: 'Screen sharing' }, ...devices.filter(d => d.kind === "videoinput")];
          this.audioDevices = devices.filter(d => d.kind === "audioinput");
        }

      }

      this.token = await this.getToken();

      this.lockJoin = false;
      
    } catch (e) {
      console.error(e);

      this.snackBar.open('lesson not found', 'Dismiss', {
        duration: 5000,
        verticalPosition: 'top'
      });

      this.leave();
    }

    if (this.routeActive.snapshot.queryParamMap.has('resolution'))
      this.resolution = this.routeActive.snapshot.queryParamMap.get('resolution');

    let videoDevices: string[] = [];
    let audioDevice: string = undefined;
    let enableVideo: boolean = false;
    let enableAudio: boolean = false;

    if (this.routeActive.snapshot.queryParamMap.has('audioDev0'))
      audioDevice = this.routeActive.snapshot.queryParamMap.get('audioDev0');

    if (this.routeActive.snapshot.queryParamMap.has('videoDev0'))
      videoDevices.push(this.routeActive.snapshot.queryParamMap.get('videoDev0'));

    if (this.routeActive.snapshot.queryParamMap.has('videoDev1'))
      videoDevices.push(this.routeActive.snapshot.queryParamMap.get('videoDev1'));

    if (this.routeActive.snapshot.queryParamMap.has('videoDev2'))
      videoDevices.push(this.routeActive.snapshot.queryParamMap.get('videoDev2'));

    if (this.routeActive.snapshot.queryParamMap.has('enableVideo'))
      enableVideo = this.routeActive.snapshot.queryParamMap.get('enableVideo') === 'true';

    if (this.routeActive.snapshot.queryParamMap.has('enableAudio'))
      enableAudio = this.routeActive.snapshot.queryParamMap.get('enableAudio') === 'true';

    window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${(window.innerHeight - 40) * 0.01}px`));

    await this.joinLesson({
      videoDeviceId: videoDevices[0],
      audioDeviceId: audioDevice,
      videoEnabled: enableVideo,
      audioEnabled: enableAudio,
      mirror: false
    });

    if (videoDevices.length > 1)
      await this.startShare(videoDevices[1], 0);

    if (videoDevices.length > 2)
      await this.startShare(videoDevices[2], 1);
  }

  async ngOnDestroy(): Promise<void> {
    if (this.isFullScreen)
      this.isFullScreen = false;

    this.bidirectionalService.dispose();

    this.resetContent();

    await this.disposePublisher(this.localParticipant?.manager as Publisher);
		this.localParticipant = null;

    for (let i = 0; i < this.OVShare.length; i++) {

      await this.disposePublisher(this.localParticipantShare[i]?.manager as Publisher);

		  this.localParticipantShare[i] = null;
      this.localParticipantShareVideoDeviceId[i] = null;

      this.sessionShare[i]?.disconnect();

      this.totalClients = 0;

      delete this.localParticipantShare[i];
      delete this.publisherShare[i];
      delete this.sessionShare[i];
      delete this.OVShare[i];

    }

    this.session?.disconnect();

    this.teachers = [];
    this.participants = [];

    this.localParticipantVideoDeviceId = null;
    this.localParticipantAudioDeviceId = null;
    this.localParticipantMirror = null;

    delete this.localParticipant;
    delete this.session;
    delete this.OV;

    this.stopStreamingQualityCheck();
    this.auth.startInactivityCheck();
    this.auth.lostConnection = false;

    this.navBar.show('topbar');
    this.navBar.show('bottombar');
    this.dialog.closeAll();
  }

  async leave(fromUser: boolean = false, route: any[] = ['/lessons']) {
    if (fromUser) {

      const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Leave lesson')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to leave lesson name?', { value: this.lesson?.name ?? '' }))
        }
      });

      if (!(await firstValueFrom(dialogRef.afterClosed())))
        return;

    }

    //if (this.isPublishing())
    //	this.setPublisherReplacement();

    await this.leaveLessonStream();
    this.router.navigate(route);
  }

  isPublisher(clientData: ClientData = this.localParticipant) {
		return clientData?.role === 'publisher';
	}

  isLessonPublisher(userId: number = this.currentUser.id) {
    return this.lesson?.teacherId === userId;
  }

  isClassPublisher(userId: number = this.currentUser.id) {
    return this.lesson?.classroom?.teacherClassroom.some(t => t.userId === userId && t.publishAbility === 1) ?? false;
  }

  isPublishing(userId: number = this.currentUser.id) {
		return this.teachers.some(t => t.userId === userId);
	}

  isSharing(userId: number = this.currentUser.id, index?: number) {
    return this.publisherShare.some((ps, i) => (index == undefined || index === i) && ps?.userId === userId);
  }

  async startShare(deviceId: string, index: number) {
    if (!this.OVShare[index])
      this.OVShare[index] = new OpenVidu();
    
    let prevStreamId: string = this.localParticipantShare[index]
                             ? this.localParticipantShare[index].manager.stream.streamId
                             : null;

    await this.stopShare(index);

    this.sessionShare[index] = this.OVShare[index].initSession();

    let token = await this.getToken();

    let clientData = new ClientData(this.currentUser.id,
                                    this.getUsername(),
                                    this.currentUser.profilePictureUrl,
                                    this.getRole(this.publisherAlreadyPresent),
                                    'share',
                                    deviceId === 'screen' ? 'screen' : 'camera',
                                    undefined,
                                    undefined,
                                    index);

    let videoSource: string | boolean | MediaStreamTrack = deviceId;
    let audioSource: string | boolean | MediaStreamTrack = false;

    try {

      if (deviceId === 'screen') {

        let screen = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
  
        videoSource = screen.getVideoTracks()[0];

        if (screen.getAudioTracks()?.length > 0)
          audioSource = screen.getAudioTracks()[0];
      }

      let localParticipantShare = this.OVShare[index].initPublisher(undefined, {
        audioSource: audioSource, // The source of audio. If undefined default microphone
        videoSource: videoSource, // The source of video. If undefined default webcam
        publishAudio: audioSource != null && audioSource != false, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: true, // Whether you want to start publishing with your video enabled or not
        resolution: this.resolution, // The resolution of your video
        frameRate: 25, // The frame rate of your video
        mirror: false
      });

      this.localParticipantShare[index] = new ClientStream(localParticipantShare, clientData);
      this.localParticipantShareVideoDeviceId[index] = deviceId;

      if (deviceId === 'screen') {

        localParticipantShare.once('accessDenied', async () => {
          console.log('Screen share access denied');
          
          //await this.stopShare();
          this.leave();
        });

        localParticipantShare.once('accessAllowed', async () => {
          console.log('Screen share access allowed');
  
          localParticipantShare.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', async () => {
            console.log('Screen share stop');
  
            //await this.stopShare();
            this.leave();
          });

          try {

            await this.sessionShare[index].connect(token, clientData);

            await this.sessionShare[index].publish(localParticipantShare);
    
            await this.joinLessonStream(this.sessionShare[index].connection.connectionId,
                                        this.sessionShare[index].connection?.stream?.streamId,
                                        index + 1,
                                        prevStreamId);

          } catch (e) {
            console.error(e);

            //await this.stopShare(index);
            this.leave();
          }
  
        });

      } else {

        await this.sessionShare[index].connect(token, clientData);
  
        await this.sessionShare[index].publish(localParticipantShare);
  
        await this.joinLessonStream(this.sessionShare[index].connection.connectionId,
                                    this.sessionShare[index].connection?.stream?.streamId,
                                    index + 1,
                                    prevStreamId);
  
      }

    } catch (e) {
      console.error(e);

      //await this.stopShare(index);
      this.leave();
    }

  }

  async stopShare(index?: number) {
    if (!this.isSharing(undefined, index))
      return;

    for (let i = 0; i < this.OVShare.length; i++) {

      if (index != undefined && index !== i)
        continue;

      if (this.localParticipantShare[i]?.manager?.stream?.isLocalStreamPublished ?? false) {
        let connectionId: string = this.localParticipantShare[i].manager.stream.connection.connectionId;
        let streamId: string = this.localParticipantShare[i].manager.stream.streamId;
  
        //await this.sessionShare?.unpublish(<Publisher>this.localParticipantShare.manager);
  
        await this.leaveLessonStream(connectionId);
      }

      this.sessionShare[i]?.disconnect();

      this.sessionShare[i] = null;
  
      await this.disposePublisher(this.localParticipantShare[i]?.manager as Publisher);
  
      this.localParticipantShare[i] = null;
      this.localParticipantShareVideoDeviceId[i] = null;
  
      await this.setPublisherShare(null, index);

    }
  }

  getUsername() {
    return `${this.currentUser.name} ${this.currentUser.surname}`;
  }

  async joinLesson(streamConfig: SourceSelection) {

    this.lockJoin = true;

    this.InitOpenviduSession();

    let clientData = new ClientData(this.currentUser.id,
                                    this.getUsername(),
                                    this.currentUser.profilePictureUrl,
                                    this.getRole(this.publisherAlreadyPresent),
                                    'subject',
                                    streamConfig.videoDeviceId === 'screen' ? 'screen' : 'camera');

    let localParticipant: Publisher | undefined = undefined;

    if ((this.isLessonPublisher() || this.isClassPublisher()) && !this.publisherAlreadyPresent) {

      localParticipant = this.OV.initPublisher(undefined, {
        audioSource: streamConfig.audioDeviceId, // The source of audio. If undefined default microphone
        videoSource: streamConfig.videoDeviceId, // The source of video. If undefined default webcam
        publishAudio: streamConfig.audioEnabled, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: streamConfig.videoEnabled, // Whether you want to start publishing with your video enabled or not
        resolution: this.resolution, // The resolution of your video
        frameRate: 25, // The frame rate of your video
        mirror: streamConfig.mirror 
      });

      this.localParticipantVideoDeviceId = streamConfig.videoDeviceId;
      this.localParticipantAudioDeviceId = streamConfig.audioDeviceId;
      this.localParticipantMirror = streamConfig.mirror;

      this.hasScreenShareCapabilities = this.OV.checkScreenSharingCapabilities();

      (<StreamManager>localParticipant).on('publisherStopSpeaking', () => this._isSpeaking = false);
      (<StreamManager>localParticipant).on('publisherStartSpeaking', () => this._isSpeaking = true);

      if (streamConfig.videoDeviceId === 'screen') {

        localParticipant.once('accessDenied', async () => {
          console.log('Screen share access denied');
          
          this.leave();
        });

        localParticipant.once('accessAllowed', async () => {
          console.log('Screen share access allowed');
  
          localParticipant.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', async () => {
            console.log('Screen share stop');
  
            this.leave();
          });

          this.localParticipant = new ClientStream(localParticipant, clientData);

          await this.session.connect(this.token, clientData)
            .then(async () => {

              this.initialSelection = false;
              this.lockJoin = false;

              if (localParticipant)
                await this.session.publish(localParticipant);

              await this.joinLessonStream(this.session.connection.connectionId,
                                          this.session.connection?.stream?.streamId,
                                          0);

              this.startStreamingQualityCheck();

              this.bidirectionalService.init(this.id, 'subscriber');

            }).catch(error => {
              console.log('There was an error connecting to the session', error.code, error.message);
              this.ngOnInit();
            });
  
        });

        return;

      }

    }

    this.localParticipant = new ClientStream(localParticipant, clientData);

    await this.session.connect(this.token, clientData)
      .then(async () => {

        this.initialSelection = false;
        this.lockJoin = false;

        if (localParticipant)
          await this.session.publish(localParticipant);

        await this.joinLessonStream(this.session.connection.connectionId,
                                    this.session.connection?.stream?.streamId,
                                    0);

        this.startStreamingQualityCheck();

        this.bidirectionalService.init(this.id, this.isPublisher() ? 'subscriber' : 'publisher');

      }).catch(error => {
        console.log('There was an error connecting to the session', error.code, error.message);
        this.ngOnInit();
      });

  }

  async changeSource(deviceId: string | false, mirror: boolean, type: 'video' | 'audio') {

    try {

			let audioDevice = type === 'audio' ? deviceId : this.localParticipantAudioDeviceId;
			let videoDevice = type === 'video' ? deviceId : this.localParticipantVideoDeviceId;

			let hasAudioActive = this.localParticipant.manager.stream.audioActive;
			let hasVideoActive = this.localParticipant.manager.stream.videoActive;

      let prevStreamId = (this.localParticipant.manager as Publisher).stream.streamId;

      await this.session.unpublish(this.localParticipant.manager as Publisher);

			await this.disposePublisher(this.localParticipant.manager as Publisher);

			this.localParticipant.manager = this.OV.initPublisher(undefined, {
				audioSource: audioDevice, // The source of audio. If undefined default microphone
				videoSource: videoDevice, // The source of video. If undefined default webcam
				publishAudio: hasAudioActive, // Whether you want to start publishing with your audio unmuted or not
				publishVideo: hasVideoActive, // Whether you want to start publishing with your video enabled or not
				resolution: this.resolution, // The resolution of your video
				frameRate: 25, // The frame rate of your video
				mirror: mirror 
			});

			this.localParticipantAudioDeviceId = audioDevice;
			this.localParticipantVideoDeviceId = videoDevice;
      this.localParticipantMirror = mirror;

      await this.session.publish(this.localParticipant.manager as Publisher);

      await this.joinLessonStream(this.session.connection.connectionId,
                                  this.session.connection.stream.streamId,
                                  0,
                                  prevStreamId);

		} catch (err) {
			console.error(err);

			this.snackBar.open(`error changing ${type} source`, 'Dismiss', {
				duration: 5000,
				verticalPosition: 'top'
			});
		}
    
  }

  toggleShareVideo(value: boolean, index: number) {
    if (!this.localParticipantShare[index] || !(this.localParticipantShare[index]?.manager?.stream?.hasVideo ?? false))
      return;

    (this.localParticipantShare[index].manager as Publisher).publishVideo(value);
  }

  toggleShareAudio(value: boolean, index: number) {
    if (!this.localParticipantShare[index] || !(this.localParticipantShare[index]?.manager?.stream?.hasAudio ?? false))
      return;

    (this.localParticipantShare[index].manager as Publisher).publishAudio(value);
  }

  toggleHand(userId: number, raise: boolean) {
    let data: SignalRaiseHand = {
      userId: userId,
      raise: raise
    };

    this.sendData(JSON.stringify(data), [], SignalType.toggleHand);
  }

  getPresenterHeight() {
    let perc = 100;
    let gap = 0;

    if (this.teachers.length > 1) {
      perc /= this.teachers.length;
      gap = (5 * (this.teachers.length - 1)) / this.teachers.length;
    }

    return `calc(var(--vh, 1vh) * ${perc} - ${gap}px)`;
  }

  lessonElapsedMinutes() {
    if (!this.lesson)
      return 0;

    let minutes = differenceInMinutes(new Date(), this.lessonStartDate);

    return minutes < 0
         ? 0
         : minutes;
  }

  getDevices(type: 'video' | 'audio', mode: 'subject' | 'share' | 'all') {
    let devices = type === 'video'
                ? this.videoDevices
                : type === 'audio'
                ? this.audioDevices
                : [];

    if (mode === 'subject')
      return devices.filter(d => !this.localParticipantShareVideoDeviceId.some(ld => ld === d.deviceId));

    if (mode === 'share')
      return devices.filter(d => d.deviceId !== this.localParticipantVideoDeviceId
                              && d.deviceId !== this.localParticipantAudioDeviceId);

    return devices;
  }

  async kickParticipant(connectionId: string) {
    if (!connectionId)
      return;

    let participant = this.teachers.find(p => p.manager?.stream?.connection?.connectionId === connectionId);

    if (!participant)
      participant = this.participants.find(p => p.manager?.stream?.connection?.connectionId === connectionId);

    if (!participant)
      return;

    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Kick user')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to kick user?', { name: participant.name }))
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;
  
        this.videoSessionService.kickUser(connectionId)
          .catch(err => {

            console.error(err);
            this.snackBar.open(err.error.Message, undefined, { duration: 3000, verticalPosition: 'top' });

          });
      });
  }

  toggleChats(type: 'publisher' | 'participants') {

    if (type === 'publisher') {

      this.publisherChatOpened = !this.publisherChatOpened;

      if (this.publisherChatOpened)
        this.participantsChatOpened = false;

    }

    if (type === 'participants') {

      this.participantsChatOpened = !this.participantsChatOpened;

      if (this.participantsChatOpened)
        this.publisherChatOpened = false;

    }

    if (this.publisherChatOpened || this.participantsChatOpened)
      this.directionalChat.open();

    if (!this.publisherChatOpened && !this.participantsChatOpened)
      this.directionalChat.close();
  }

  closeChats(toggle: boolean) {
    if (!toggle) {
      this.publisherChatOpened = false;
      this.participantsChatOpened = false;
    }
  }

  lostConnection() {
    return this.auth.lostConnection;
  }

  shareStreams() {
    return this.publisherShare.filter(p => p != undefined);
  }

  bidirectionalClient() {
    if (this.bidirectionalService.status !== 'connected')
      return undefined;

    return this.bidirectionalService.mode === 'publisher'
         ? this.bidirectionalService.localClient
         : this.bidirectionalService.remoteClient;
  }

  stopBidirectional() {
    this.bidirectionalService.mode === 'publisher' ?
    this.bidirectionalService.dispose('Camera closed', false) :
    this.bidirectionalService.askToStop(this.bidirectionalService.remoteClient.userId);
  }

  setContent(contentId: number, enable: boolean) {
    let newContent: LessonContentEdit = {
      idContent: contentId,
      idConnection: this.session.connection.connectionId,
      playMode: enable ? 1 : 0
    };

    this.calendar.setCurrentLessonContent(this.id, newContent)
      .subscribe({
        next: res => {

          let contentToSet: ContentStream = {
            content: res,
            marker: this.currentContentTime,
            pause: false,
            type: enable ? 'set' : 'remove'
          };

          this.sendData(JSON.stringify(contentToSet), [], SignalType.setCourseContent);

        },
        error: err => {
          console.error(err);
          this.snackBar.open('Cannot set content for current lesson', 'Dismiss', { duration: 3000 });
        }
      });
  }

  updateContent(marker: number, pause: boolean = this.currentContentPause) {
    if (!this.currentContent)
      return;

    let contentToUpdate: ContentStream = {
      content: this.currentContent,
      marker: marker,
      pause: pause,
      type: 'update'
    };

    this.sendData(JSON.stringify(contentToUpdate), [], SignalType.setCourseContent);
  }

  isDark() {
    return this.darkThemeService.isSetDark;
  }

  private sendData(data: string, remoteConnections: Connection[], signalType: SignalType) {
    if (!remoteConnections || data == null)
      return;

    this.session.signal({ data: data, to: remoteConnections, type: signalType })
      .catch(err => console.error(err));
  }

  private async getToken(): Promise<string> {

		if (this.isLessonPublisher() || this.isClassPublisher())
			await this.videoSessionService.createSession(this.id);

    try {

      return (await firstValueFrom(this.videoSessionService.generateToken(this.id, undefined, this.publisherAlreadyPresent))).token;

    } catch (e) {

      console.error(e);

      if (e.status === 507) {

				this.dialog.open(GenericPopupComponent,
				{
					width: '400px',
					data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Warning')),
            body: e.error,
            hideCancelBtn: true,
            btnAlign: 'center'
					}
				});

			}

    }

  }

  private InitOpenviduSession() {

    this.session = this.OV.initSession();

    this.session.on('streamCreated', async (event: StreamEvent) => {
      console.warn('STREAM CREATED!');
      console.warn(event);

      let clientData = <ClientData>JSON.parse(event.stream.connection.data);

      if (clientData.hidden)
				return;

      if (clientData.userId === this.currentUser.id)
				return;

      if (clientData.mode === 'bidirectional')
        return;

      if (clientData.mode === 'share') {

        if (this.publisherShare[clientData.index])
          this.publisherShare[clientData.index].manager = this.session.subscribe(event.stream, undefined);

        return;

      } else {

        let index = this.participants.findIndex(p => p.userId === clientData.userId);

        if (index !== -1) {
          this.participants[index].manager = this.session.subscribe(event.stream, undefined);
          return;
        }
  
        index = this.teachers.findIndex(p => p.userId === clientData.userId);
  
        if (index !== -1) {
          this.teachers[index].manager = this.session.subscribe(event.stream, undefined);
          return;
        }

      }
      
    });

    this.session.on('streamDestroyed', async (event: StreamEvent) => {
      console.warn('STREAM DESTROYED!');

      let clientData = <ClientData>JSON.parse(event.stream.connection.data);

      if (clientData.hidden)
				return;

      if (clientData.mode === 'bidirectional')
        return;

      if (this.publisherShare[clientData.index]?.manager?.stream.streamId === event.stream.streamId) {
        this.publisherShare[clientData.index].manager = undefined;
        return;
      }

      let index = this.participants.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);

      if (index !== -1) {
        this.participants[index].manager = undefined;
        return;
      }

      index = this.teachers.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);

      if (index !== -1) {
        this.teachers[index].manager = undefined;
        return;
      }

    });

    this.session.on('connectionCreated', async (event: ConnectionEvent) => {
      console.warn(event.connection.connectionId === this.session.connection.connectionId ?
                   'YOUR OWN CONNECTION CREATED!' :
                   'OTHER USER\'S CONNECTION CREATED!');

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.hidden)
				return;

      if (clientData.mode === 'bidirectional')
        return;

      this.totalClients++;

      if (clientData.mode === 'share') {

        //Il connectionId di sessionShare risulta ancora a null in questo punto quindi identifico per userId
        let participant = clientData.userId === this.currentUser.id
                        ? this.localParticipantShare[clientData.index]
                        : new ClientStream(undefined, clientData);

        await this.setPublisherShare(participant, clientData.index);

      } else {

        let participant = event.connection.connectionId === this.session.connection.connectionId
                        ? this.localParticipant
                        : new ClientStream(undefined, clientData);

        if (this.isPublisher(clientData) && !this.publisherShare.some(ps => ps != undefined)) {

          this.setPublisher(participant);
  
        } else {

          this.participants.push(participant);

        }

        if (clientData.userId !== this.currentUser.id) {

          if (this.isHandRaised)
            setTimeout(() => this.toggleHand(this.currentUser.id, this.isHandRaised), MIN_OPERATION_LOCK_TIME);

          if (this.currentContent && this.isPublisher())
            setTimeout(() => this.setContent(this.currentContent.id, true));
  
        }

      }
      
    });

    this.session.on('connectionDestroyed', async (event: ConnectionEvent) => {
      console.warn(event.connection.connectionId === this.session.connection.connectionId ?
                   'YOUR OWN CONNECTION DESTROYED!' :
                   'OTHER USER\'S CONNECTION DESTROYED!');

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.hidden)
				return;

      if (clientData.mode === 'bidirectional')
        return;

      if (this.totalClients > 0)
				this.totalClients--;

      if (clientData.mode === 'share') {

        await this.setPublisherShare(null);

      } else {

        let index = this.participants.findIndex(p => p.userId === clientData.userId);

        if (index !== -1)
          this.participants.splice(index, 1);

        index = this.teachers.findIndex(t => t.userId === clientData.userId);

        if (index !== -1) {
          this.publisherLeft(this.teachers.splice(index, 1)[0].name);
          this.resetContent();
        }

      }
      
    });

    this.session.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
      console.warn('SESSION DISCONNECT!');

      if (event.reason !== "disconnect" && !this.leaving) {

        let msg = event.reason === "networkDisconnect"
                ? await firstValueFrom(this.translate.get('No connection. Go to an area with a better connection'))
                : `Disconnected from lesson: ${event.reason}`;
        
        this.snackBar.open(msg, 'Dismiss', {
          duration: 10000,
          verticalPosition: 'top'
        });

        this.leave();
      
      }

      this.leaving = true;

    });

    /* Se pianificato la lezione normale è sempre in recording
    this.session.on('recordingStarted', () => {
			console.warn('RECORDING STARTED');

			this.isRecording = true;

      this.snackBar.open(this.translate.instant('Recording started'), undefined, { duration: 6000, verticalPosition: 'top' });
		});

		this.session.on('recordingStopped', () => {
			console.warn('RECORDING STOPPED');

			this.isRecording = false;

      this.snackBar.open(this.translate.instant('Recording stopped'), undefined, { duration: 6000, verticalPosition: 'top' });
		});
    */

    this.session.on("reconnecting", () => {
      console.warn('RECONNECTING!');

      this.auth.lostConnection = true;
    });

    this.session.on('reconnected', () => {
      console.warn('RECONNECTED!');

      this.auth.lostConnection = false;
    });

    this.session.on(SignalType.setCourseContent, (event: SignalEvent) => {

      let content = <ContentStream>JSON.parse(event.data);

      if (!content)
        return;

      if (content.type === 'set') {

        if (content.content?.id === this.currentContent?.id)
          return;

        this.currentContent = content.content;
        this.currentContentMarker = content.marker;
        this.currentContentPause = content.pause;

      } else if (content.type === 'update') {

        if (event.from.connectionId === this.session.connection.connectionId)
          return;

        if (content.content?.id !== this.currentContent?.id)
          this.currentContent = content.content

        if (this.currentContent.type !== 1 || (this.currentContent.type === 1 && Math.abs(content.marker - this.currentContentTime) > CONTENT_TIME_ERROR))
          this.currentContentMarker = content.marker;

        this.currentContentPause = content.pause;

      } else {

        this.resetContent();

      }

    });

  }

  private async joinLessonStream(connectionId: string, streamId: string, position: number, prevStreamId: string = null) {
    try {
      await this.videoSessionService.joinLesson(
        this.lesson.id,
        <JoinSession>{
          role: streamId != undefined ? 'PUBLISHER' : 'SUBSCRIBER',
          connectionId: connectionId,
          streamId: streamId,
          position: position,
          prevStreamId: prevStreamId
        });
    } catch (e) {
      console.error(e);
    }
  }

  private async leaveLessonStream(connectionId?: string) {
    try {

      this.leaving = true;

      await this.videoSessionService.leaveLesson(this.id, connectionId);

    } catch (e) {
      console.error(e);
    }
  }

  private getRole(foundPublisher: boolean = false): "publisher" | "participant" {
    return (this.isLessonPublisher() || this.isClassPublisher()) && !foundPublisher
         ? 'publisher'
         : 'participant';
  }

  private async setPublisher(newPublisher: ClientStream) {
    //let isNewPublisher = this.teachers.length > 0 || this.teachers.some(p => p.userId === newPublisher.userId);

    //if (newPublisher != null) {

      //this.setPublishingInfo(newPublisher, isNewPublisher);

    //} else {

      //let index = this.publisherShare.findIndex(p => p != undefined);

      //if (index !== -1)
        //this.setPublishingInfo(this.publisherShare[index], isNewPublisher);

    //}

    this.teachers = newPublisher ? [newPublisher] : [];

    //this.publishingInfo = null;
  }

  private async setPublisherShare(newPublisherShare: ClientStream, index?: number) {
    //let isNewPublisher = this.publisherShare[index]?.userId !== newPublisherShare?.userId;

    this.publisherShare[index] = newPublisherShare;
    //this.publishingInfo = null;

    //if (this.publisherShare != null)
    //  this.setPublishingInfo(newPublisherShare, isNewPublisher);
  }

  private publisherLeft(name: string) {
    this.snackBar.open(`Teacher ${name} has left the lesson`, 'Dismiss', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private getRoleString(role: "publisher" | "moderator" | "presenter" | "participant") {
    return role === 'publisher' || role === 'moderator' || role === 'presenter'
         ? 'Teacher'
         : 'Participant';
  }

  private startStreamingQualityCheck() {
    this.stopStreamingQualityCheck();

    this.calendar.addStreamingQuality(this.id)
      .subscribe({
        next: () => console.log('Stream quality check'),
        error: err => console.error(err)
      });

    this.streamingQualityInterval = setInterval(() => {

      if (this.platform.BLINK) { // Se Chrome o derivato

        let connType = NetworkInformation.connectionType();

        if (this.validStreamingQualities.includes(connType)) {
  
          this.streamingQualityValues = [];
  
        } else {
  
          if (this.streamingQualityValues.length >= 3)
            this.leave();
  
          this.streamingQualityValues.push(connType);
  
        }

      }

      this.calendar.addStreamingQuality(this.id)
        .subscribe({
          next: () => console.log('Stream quality check'),
          error: err => console.error(err)
        });

    }, STREAMING_QUALITY_CHECK_INTERVAL);

    console.log('Stream quality check start');
  }

  private stopStreamingQualityCheck() {
    if (this.streamingQualityInterval)
      clearInterval(this.streamingQualityInterval);

    this.streamingQualityInterval = undefined;
    this.streamingQualityValues = [];

    console.log('Stream quality check stop');
  }

  private async checkForPublisherAlreadyConnected(): Promise<boolean> {

		let previewOV: OpenVidu = undefined;
		let previewSession: Session = undefined;
    let previewToken: string = undefined;

		try {

			previewToken = (await firstValueFrom(this.videoSessionService.generateToken(this.id, undefined, true))).token;

			previewOV = new OpenVidu();

			previewSession = previewOV.initSession();

		} catch (e) {
			console.error(e);

			previewSession?.disconnect();

			previewSession = undefined;
			previewOV = undefined;

			return false;
		}

		return new Promise<boolean>(async (resolve, reject) => {

			let checkTimeout = setTimeout(() => {

				previewSession?.disconnect();

				previewSession = undefined;
				previewOV = undefined;

				resolve(false);

			}, CHECK_PUBLISHER_CONNECTED_TIMEOUT);

			previewSession.on('connectionCreated', (event: ConnectionEvent) => {
				let clientData = <ClientData>JSON.parse(event.connection.data);

				if (clientData.hidden)
          return;

        if (clientData.userId !== this.currentUser.id &&
            clientData.role === 'publisher' &&
            clientData.mode === 'subject') {

					clearTimeout(checkTimeout);

					previewSession?.disconnect();

					previewSession = undefined;
					previewOV = undefined;

					resolve(true);

				}
			});

      await previewSession.connect(
        previewToken,
        new ClientData(
          this.currentUser.id,
          `${this.currentUser.name} ${this.currentUser.surname}`,
          this.currentUser.profilePictureUrl,
          'publisher',
          'subject',
          'camera',
          undefined,
          true
        )
      );

		});

	}

  private async disposePublisher(publisher: Publisher) {
		if (publisher?.stream.getMediaStream() == undefined)
			return;

		if (publisher.stream.hasVideo)
			await publisher.publishVideo(false, true);

		publisher.stream.getMediaStream()?.getTracks().forEach(t => t.stop());
	}

  private resetContent() {
    this.currentContent = undefined;
    this.currentContentMarker = 0;
    this.currentContentTime = 0;
    this.currentContentPause = false;
  }

}
