<div fxLayout="column" fxLayoutAlign="start center" style="margin-top: 20px; margin: 10px;">

  <button mat-button
    [routerLink]="['/showcase']"
    [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
    (click)="itemSelected.emit(true)"
    class="showcaseBtn">
    {{ 'Welcome to' | translate }} <br *ngIf="isSL()"> {{ title }}
  </button>

  <mat-action-list>
   
    <mat-list-item (click)="addCustomer()" *ngIf="currentUser.isAdmin">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Customer' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addSubscriptionLevel()" *ngIf="currentUser.isAdmin">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Subscription' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addLesson()" [disabled]="!currentUser.streamingEnabled" *ngIf="currentUser.isTutor || currentUser.isTeacher">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Live Lesson' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addPublicConference()" [disabled]="!currentUser.streamingEnabled" *ngIf="currentUser.isTutor || currentUser.isTeacher || currentUser.isSpeaker">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Public Conference' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addPrivateConference()" [disabled]="!currentUser.streamingEnabled" *ngIf="currentUser.isTutor || currentUser.isTeacher || currentUser.isSpeaker">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Private Conference' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addWebinar()" [disabled]="!currentUser.streamingEnabled" *ngIf="currentUser.isTutor">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Webinar' | translate }}
    </mat-list-item>
    
    <mat-list-item (click)="addSurvey()" *ngIf="currentUser.isAuthor || currentUser.isCustomerAdmin">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Survey' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addCourse()" *ngIf="(currentUser.isAuthor || currentUser.isCustomerAdmin) && currentUser.coursesEnabled">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Training Course' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addMaster()" *ngIf="(currentUser.isAuthor || currentUser.isCustomerAdmin) && currentUser.mastersEnabled">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Live Digital Academy' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addTemplateMaster()" *ngIf="(currentUser.isAuthor || currentUser.isCustomerAdmin) && currentUser.mastersEnabled">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Personal Live Digital Academy' | translate }}
    </mat-list-item>

    <mat-list-item (click)="addPackage()" *ngIf="(currentUser.isAuthor || currentUser.isCustomerAdmin) && (currentUser.coursesEnabled || currentUser.mastersEnabled)">
      <mat-icon matListItemIcon>
        add
      </mat-icon>
      {{ 'Package' | translate }}
    </mat-list-item>

    <mat-list-item (click)="importFromFile()" *ngIf="(currentUser.isCustomerAdmin || currentUser.isAdmin) && currentUser.importEnabled">
      <mat-icon matListItemIcon>
        upload
      </mat-icon>
      {{ 'Data import' | translate }}
    </mat-list-item>

  </mat-action-list>

  <div mat-subheader class="sectionHeader">
    {{ ((currentUser.isTutor || currentUser.isTeacher ? 'My activities' : 'My subscriptions') | translate).toUpperCase() }}
  </div>
  <mat-accordion class="my-headers-align">

    <mat-expansion-panel [hideToggle]="true" class="mat-elevation-z0">
      <mat-expansion-panel-header
        [routerLink]="['/masters-user']"
        [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
        (click)="itemSelected.emit(true)">
        <mat-panel-title>
          <mat-icon>people</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'My masters' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="currentUser.isCustomerAdmin || currentUser.isTutor" [hideToggle]="true" class="mat-elevation-z0">
      <mat-expansion-panel-header
        [routerLink]="['/webinar-customer']"
        [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
        (click)="itemSelected.emit(true)">
        <mat-panel-title>
          <mat-icon>interpreter_mode</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'My webinar' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0"
      *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || currentUser.isTeacher || currentUser.isStudent"
      [disabled]="sideNav.lessons.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>school</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Upcoming Live Lessons' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list [style.color]="darkService.isSetDark ? 'white' : ''" >
          <a mat-list-item *ngFor="let lesson of sideNav.lessons"
            [routerLink]="['/lessons']"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"ù
            [matTooltip]="lesson.name"
            (click)="itemSelected.emit(true)">
            <mat-icon matListItemIcon>
              school
            </mat-icon>
            <p matLine class="truncate-text">
              {{ lesson.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

    <!--mat-expansion-panel class="mat-elevation-z0" [disabled]="sideNav.webinars.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>interpreter_mode</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Webinar' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list [style.color]="darkService.isSetDark ? 'white' : ''" >
          <a mat-list-item *ngFor="let webinar of sideNav.webinars"
            [routerLink]="['/webinar-showcase', webinar.id]"
            [queryParams]="{page: 'showcase'}"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            (click)="itemSelected.emit(true)">
            <img matListItemAvatar [src]="webinar.imageUrl" [alt]="webinar.name" loading="lazy" style="object-fit: cover;">
            <p matLine>
              {{ webinar.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel-->

    <mat-expansion-panel class="mat-elevation-z0" [disabled]="sideNav.courses.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>book</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Training Courses' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list>
          <a mat-list-item *ngFor="let course of sideNav.courses"
            [routerLink]="['/course', course.id]"
            [queryParams]="{ page: 'sidenav' }"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            [matTooltip]="course.name"
            (click)="itemSelected.emit(true)">
            <img matListItemAvatar [src]="course.imageUrl" [alt]="course.name" loading="lazy" style="object-fit: cover;">
            <p matLine class="truncate-text">
              {{ course.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" [disabled]="sideNav.masters.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>people</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Live Digital Academy' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list>
          <a mat-list-item *ngFor="let master of sideNav.masters"
            [routerLink]="['/master', master.id]"
            [queryParams]="{ page: 'sidenav' }"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            [matTooltip]="master.name"
            (click)="itemSelected.emit(true)">
            <img matListItemAvatar [src]="master.imageUrl" [alt]="master.name" loading="lazy" style="object-fit: cover;">
            <p matLine class="truncate-text">
              {{ master.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" [disabled]="sideNav.oneToOneMasters.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>person</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Personal Live Digital Academy' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list *ngIf="currentUser.isStudent; else masterWithOrigin">
          <a mat-list-item *ngFor="let master of sideNav.oneToOneMasters"
            [routerLink]="['/master', master.id]"
            [queryParams]="{ page: 'sidenav' }"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            [matTooltip]="master.name"
            (click)="itemSelected.emit(true)">
            <img matListItemAvatar [src]="master.imageUrl" [alt]="master.name" loading="lazy" style="object-fit: cover;">
            <p matLine class="truncate-text">
              {{ master.name }}
            </p>
          </a>
        </mat-nav-list>

        <ng-template #masterWithOrigin>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let template of sideNav.oneToOneMasters" class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-expansion-panel-header-title style="min-width: 0">
                  <a [routerLink]="['/master', template.id]"
                    [queryParams]="{ page: 'sidenav' }"
                    [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
                    [matTooltip]="template.name"
                    fxLayout="row" fxLayoutGap="16px">
                    <img [src]="template.imageUrl" [alt]="template.name" class="expansionPanelAvatar" loading="lazy">
                    <p matLine class="truncate-text">
                      {{ template.name }}
                    </p>
                  </a>
                </mat-expansion-panel-header-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <mat-nav-list>
                  <a mat-list-item *ngFor="let master of template.derivedMaster"
                    [routerLink]="['/master', master.id]"
                    [queryParams]="{ page: 'sidenav' }"
                    [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
                    [matTooltip]="master.classroom.classroomContent[0].user.name + ' ' + master.classroom.classroomContent[0].user.surname"
                    [class.disabled]="null"
                    (click)="itemSelected.emit(true)">
                    <app-profile-picture
                      matListItemAvatar
                      [url]="master.classroom.classroomContent[0].user.profilePictureUrl"
                      size="40px"
                      style="margin-right: 16px;">
                    </app-profile-picture>
                    <p matLine class="truncate-text" style="font-size: 14px">
                      {{ master.classroom.classroomContent[0].user.name + ' ' + master.classroom.classroomContent[0].user.surname }}
                    </p>
                  </a>
                </mat-nav-list>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
        <!--mat-nav-list>
          <a mat-list-item *ngFor="let master of sideNav.masterTemplates"
          [routerLink]="['/master', master.id]"
          [queryParams]="{ page: 'sidenav' }"
          [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
          (click)="itemSelected.emit(true)">
          <img matListAvatar [src]="master.imageUrl" [alt]="master.name" loading="lazy">
          <p matLine>
            {{ master.name }}
          </p>
          </a>
        </mat-nav-list-->
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" [disabled]="sideNav.favourites.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>favorite</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Favourites' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list>
          <a mat-list-item *ngFor="let fav of sideNav.favourites"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            [matTooltip]="fav.name"
            (click)="goToFavourite(fav)">
            <img matListItemAvatar [src]="fav.headerImageUrl" [alt]="fav.name" loading="lazy" style="object-fit: cover;">
            <p matLine class="truncate-text">
              {{ fav.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" *ngIf="currentUser.isAuthor" [disabled]="sideNav.packages.length === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>inventory_2</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'Packages' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list> <!--[routerLink]="['/package', pack.id]"-->
          <a mat-list-item *ngFor="let pack of sideNav.packages"
            [routerLink]="['/package', pack.id]"
            [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
            [matTooltip]="pack.name"
            (click)="itemSelected.emit(true)">
            <img matListItemAvatar [src]="pack.imageUrl" [alt]="pack.name" loading="lazy" style="object-fit: cover;">
            <p matLine class="truncate-text">
              {{ pack.name }}
            </p>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

  </mat-accordion>

  <div mat-subheader class="sectionHeader">
    <!-- <mat-icon class="listHeader">
      more_horiz
    </mat-icon> -->
    {{ ('Settings' | translate).toUpperCase() }}
  </div>
  <mat-action-list style="padding-top: 0px">
    
    <mat-list-item *ngIf="currentUser.isTeacher || currentUser.isTutor"
      [routerLink]="['/surveys-template']"
      [routerLinkActive]="[darkService.isSetDark ? 'selectedDark' : 'selectedLight']"
      (click)="itemSelected.emit(true)">
      <mat-icon matListItemIcon>
        assignment
      </mat-icon>
      <div class="listItemText">{{ 'Survey Templates' | translate }}</div>
    </mat-list-item>

    <mat-list-item>

      <mat-icon matListItemIcon>
        {{ darkService.isSetDark ? "nightlight" : "light_mode" }}
      </mat-icon>

      <div matListItemTitle style="display: flex; justify-content: space-between;">
        <span class="listItemText">{{ "Theme" | translate }}</span>
        <mat-slide-toggle [ngModel]="darkService.isSetDark" (change)="ThemeSelection()"></mat-slide-toggle>
      </div>

    </mat-list-item>

  </mat-action-list>

</div>
