<div style="height: 100%;" fxLayout="column" [class]="isDarkClass()">
  <mat-toolbar *ngIf="isBarVisible('topbar')" role="heading" class="mat-elevation-z6" >

    <button mat-icon-button *ngIf="isAuthenticated()" (click)="toggleSidenav()" style="margin-right: 10px">
      <mat-icon>
        menu
      </mat-icon>
    </button>

    <div matRipple (click)="reloadPage()" style="display: flex; align-items: center; width: 200px; height: 100%;cursor: pointer;" >
      <div *ngIf="isGD(); else standardLogo">
        <img [src]="getLogo()" fxFlex="30" loading="lazy"/>
      </div>
      <ng-template #standardLogo>
        <img [src]="getLogo()" style="max-height: 48px; float: left;" loading="lazy"/>
      </ng-template>
    </div>

    <span class="fill-remaining-space" style="text-align: center"> </span>

    <div *ngIf="isAuthenticated(); else signUp">

      <button mat-button (click)="goToShowcase()" *ngIf="!isAdmin() && !isCustomerAdmin() && eduPlanEnabled()">
        {{ "Shop" | translate }}
      </button>

      <button mat-button (click)="goEasyTeachOnTrayDownload()" *ngIf="isCustomerAdmin() && smilyEnabled()">
        {{ "EasyOnTray" | translate }}
      </button>

      <button mat-button (click)="goToWall()">
        {{ "Social Wall" | translate }}
      </button>

      <button mat-button (click)="goToWebinarCustomer()" *ngIf="isCustomerAdmin() || isTutor()">
        {{ "My webinar" | translate }}
      </button>

      <button mat-button (click)="goToMasters()">
        {{ "My masters" | translate }}
      </button>

      <button mat-button (click)="goToLesson()" *ngIf="isAdmin() || isCustomerAdmin() || isTutor() || isTeacher() || isStudent()">
        {{ "Live Lessons" | translate }}
      </button>

      <button mat-button *ngIf="!isDT()" (click)="goToConference()">
        {{ "Conferences" | translate }}
      </button>

      <button mat-button *ngIf="isAdmin()" [matMenuTriggerFor]="dashboardMenu">
        {{ 'Dashboards' | translate }}
      </button>

      <mat-menu #dashboardMenu="matMenu">

        <button mat-menu-item *ngIf="isAdmin()" (click)="goToDashboard()">
          {{ 'Customers Dashboard' | translate }}
        </button>

        <button mat-menu-item *ngIf="isCustomerAdmin() || isAdmin()" (click)="goToCustomersDashboard()">
          {{ 'Dashboard' | translate }}
        </button>

      </mat-menu>

      <button mat-button *ngIf="isCustomerAdmin()" (click)="goToCustomersDashboard()">
        {{ 'Dashboard' | translate }}
      </button>

      <button mat-button (click)="goToCalendar()" *ngIf="isTutor() || isTeacher() || isSpeaker() || isStudent()">
        {{ "Calendar" | translate }}
      </button>

      <button mat-button *ngIf="isStudent() || isSpeaker()" (click)="goToClassroom()">
        {{ "Virtual Classrooms" | translate }}
      </button>

      <button mat-button *ngIf="!isStudent() && !isSpeaker()" [matMenuTriggerFor]="configurationMenu">
        {{ "Configuration" | translate }}
      </button>

      <mat-menu #configurationMenu="matMenu">

        <button mat-menu-item (click)="goToClassroom()">
          {{ "Virtual Classrooms" | translate }}
        </button>

        <button mat-menu-item (click)="goToUsers()">
          {{ "Users" | translate }}
        </button>

        <button mat-menu-item *ngIf="isAdmin()" (click)="goToCustomers()">
          {{ "Administrator Users" | translate }}
        </button>

        <button mat-menu-item (click)="goToPlans()" *ngIf="(isAdmin() || isCustomerAdmin()) && eduPlanEnabled()">
          {{ "Educational plans" | translate }}
        </button>

      </mat-menu>

      <button mat-button [matMenuTriggerFor]="paymentsMenu">
        {{ "Payments" | translate }}
      </button>

      <mat-menu #paymentsMenu="matMenu">

        <button mat-menu-item *ngIf="(isCustomerAdmin() || isAdmin()) && isStripeAble()" (click)="goToPayments()">
          {{ "Payments" | translate }}
        </button>

        <button mat-menu-item *ngIf="isCustomerAdmin() || isAdmin()" (click)="goToPaymentsList()">
          {{ "Payments list" | translate }}
        </button>

        <button mat-menu-item *ngIf="!isStudent() && !isSpeaker()" (click)="goToOrders()">
          {{ "Received Orders" | translate }}
        </button>

        <button mat-menu-item *ngIf="isStudent() || isSpeaker() || isTeacher() || isTutor()" (click)="goToCart()">
          {{ "Cart" | translate }}
        </button>

        <button mat-menu-item *ngIf="isStudent() || isSpeaker() || isTeacher() || isTutor()" (click)="goToPurchased()">
          {{ "Purchased" | translate }}
        </button>

      </mat-menu>

      <button mat-button *ngIf="isAdmin()" (click)="goToStreaming()">
        {{ 'System Status' | translate }}
      </button>

      <button mat-button (click)="goToStations()" *ngIf="!isStudent() && !isSpeaker() && !isTutor() && smilyEnabled()">
        Smily
      </button>

      <app-user-account
        [matTooltip]="'Your role is' | translate: { value: (getRole() | translate) }"
        [matMenuTriggerFor]="beforeMenu">
      </app-user-account>

      <mat-menu #beforeMenu="matMenu" xPosition="before" backdropClass="mat-menu-font">

        <button mat-menu-item (click)="goToAccount()">
          <mat-icon>account_circle</mat-icon>
          Account
        </button>

        <button mat-menu-item (click)="goToWarnings()">
          <mat-icon
            [matBadge]="warningService.warnings.length"
            [matBadgeHidden]="warningService.warnings.length === 0"
            [matBadgeOverlap]="true"
            matBadgeSize="small"
            matBadgeColor="warn"
            aria-hidden="false">
            notifications
          </mat-icon>
          {{ 'Warnings' | translate }}
        </button>

        <button mat-menu-item (click)="goToRegistry()"  *ngIf="(isTeacher() || isCustomerAdmin()) && importEnabled()">
          <mat-icon>menu_book</mat-icon>
          {{ "Registry" | translate }}
        </button>

        <button mat-menu-item (click)="goToRegistry(true)" *ngIf="(isTeacher() || isCustomerAdmin()) && importEnabled()">
          <mat-icon>event</mat-icon>
          {{ "Events" | translate }}
        </button>

        <button mat-menu-item *ngIf="isAuthor() && (coursesEnabled() || mastersEnabled())" (click)="goToTranslations()">
          <mat-icon>translate</mat-icon>
          {{ "Translations" | translate }}
        </button>

        <button mat-menu-item (click)="openPrivacy()">
          <mat-icon>policy</mat-icon>
          {{ "Privacy Policy" | translate }}
        </button>

        <button mat-menu-item (click)="openCookies()">
          <mat-icon>cookie</mat-icon>
          {{ "Cookie Policy" | translate }}
        </button>

        <button mat-menu-item (click)="goToChangelog()">
          <mat-icon>info</mat-icon>
          <span>{{ appInfo }}</span>
        </button>

        <button mat-menu-item (click)="logOut()">
          <mat-icon>logout</mat-icon>
          {{ "Log out" | translate }}
        </button>
        
      </mat-menu>

    </div>

    <ng-template #signUp>
      <app-user-account *ngIf="!isLoginPage()" (click)="goToLogin()"></app-user-account>
    </ng-template>

  </mat-toolbar>
  <mat-sidenav-container fxFlex hasBackdrop="false">

    <mat-sidenav #mainsidenav mode="push">
      <app-sidenav *ngIf="isBarVisible('sidenav')" (itemSelected)="toggleSidenav()"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div style="position: fixed; bottom: 2vh; right: 2vw; z-index: 10000" 
        *ngIf="isAuthenticated() &&
              (azureService.showCurrentUploads || selectionService.showSelection)">
        <app-uploads-viewer *ngIf="azureService.showCurrentUploads"></app-uploads-viewer>
        <app-selection-viewer *ngIf="selectionService.showSelection"></app-selection-viewer>
      </div>

	    <app-banner *ngIf="isAuthenticated() && isBarVisible('topbar')"></app-banner>
	  
      <main [@routerTransition]="o.isActivated && o.activatedRoute.routeConfig.path">
        <router-outlet #o="outlet"></router-outlet>
      </main>

      <mat-toolbar class="mat-elevation-z6" *ngIf="isBarVisible('bottombar')">

        <div>
          <a [href]="getUrl()" 
             [matTooltip]="'Go to website' | translate"
             matTooltipPosition="above"
             target="_blank"
             class="footer-text">
            <strong>© {{ getName() }}</strong> {{ today.getFullYear() }}
          </a>
        </div>
      
        <span class="fill-remaining-space"></span>

        <div *ngIf="getPhone()" class="footer-text">
          {{ 'Contact us at' | translate }} {{ getPhone() }}
        </div>

        <button mat-button
          *ngIf="isAuthenticated()"
          [matMenuTriggerFor]="languageMenu"
          (menuOpened)="setIcon(true)"
          (menuClosed)="setIcon(false)">
          <mat-icon>
            {{ icon }}
          </mat-icon>
          {{ getLanguage() }}
        </button>
        
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" backdropClass="mat-menu-font">
  <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang.code)">{{ lang.description }}</button>
</mat-menu>
