<div class="component-main center-item">
  <h2 style="text-align-last: center">{{ 'Customers Dashboard' | translate }}</h2>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px">
    <mat-form-field color="primary">
      <mat-label>{{ 'Search' | translate }}...</mat-label>
      <input matInput [(ngModel)]="value" (ngModelChange)="resetCustomers(false)">
      <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Clear' | translate" (click)="resetCustomers(true)">
        <mat-icon>clear</mat-icon>
      </button>  
    </mat-form-field>
    <button mat-fab color="primary" (click)="ngOnInit()" [matTooltip]="'Refresh' | translate">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div fxLayout="column" class="center-item">
    <mat-accordion class="my-headers-align">
      <mat-expansion-panel
        *ngFor="let customer of customers | slice:pageStart:pageEnd"
        (opened)="selectedCustomer = customer; expansionHeader(customer);"
        (closed)="selectedCustomer = undefined; changePage();"
        style="margin-bottom: 10px">

        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="25" fxLayoutAlign="start">
              {{ customer.name }}
            </div>
            <div fxFlex fxLayoutAlign="start" class="truncate-text">
              {{ customer.description }}
            </div>
            <div fxFlex="20" fxLayoutAlign="end">
              {{ 'Created' | translate }} {{ customer.created | date: 'dd/MM/yyyy' }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div *ngIf="selectedCustomer" fxFlex fxLayout="column" fxLayoutGap="20px">

            <mat-card appearance="outlined">
              <mat-card-header style="margin-bottom: 10px">
                <mat-card-title>{{ 'Authorisations' | translate }}</mat-card-title>
              </mat-card-header>

              <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-evenly" fxLayoutGap="20px grid">

                <div *ngIf="selectedCustomer.isDemo == 1">
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Demo')"
                    [style.backgroundColor]="remainingDays <= 0 ? 'red' : 'yellowgreen'">
                    Demo
                  </button>
                </div>

                <div *ngIf="selectedCustomer.isDemo == 1">
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Reset demo')"
                    [disabled]="remainingDays >= selectedCustomer.demoDuration"
                    color="primary">
                    Reset demo
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Courses')"
                    [style.backgroundColor]="!selectedCustomer.coursesEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.coursesEnabled ? 'Courses enable': 'Courses disable') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Authorized')"
                    [style.backgroundColor]="!selectedCustomer.authorized ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.authorized ? 'Authorized' : 'Not authorized') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Masters')"
                    [style.backgroundColor]="!selectedCustomer.mastersEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.mastersEnabled ? 'Masters enable' : 'Masters disable') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Smily')"
                    [style.backgroundColor]="!selectedCustomer.smilyEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.smilyEnabled ? 'Smily enable' : 'Smily disable') | translate }}
                  </button>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Educational plans')"
                    [style.backgroundColor]="!selectedCustomer.eduPlanEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.eduPlanEnabled ? 'Educational plans enable' : 'Educational plans disable') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Streaming')"
                    [style.backgroundColor]="!selectedCustomer.streamingEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.streamingEnabled ? 'Streaming enable' : 'Streaming disable') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Billing')"
                    [matTooltip]="(selectedCustomer.invoicePerMonth === 0 ? 'Enable billing' : 'Disable billing') | translate"
                    [style.background-color]="selectedCustomer.invoicePerMonth === 1 || selectedCustomer.invoicePerMonth === 2 ? 'yellowgreen' : 'red'">
                    {{ (selectedCustomer.invoicePerMonth === 1 || selectedCustomer.invoicePerMonth === 2 ? 'Billing enabled' : 'Billing disabled') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Billing period')"
                    [disabled]="selectedCustomer.invoicePerMonth === 0"
                    [matTooltip]="'Change billing period' | translate"
                    [color]="selectedCustomer.invoicePerMonth === 1 ? 'primary' : 'accent'">
                    {{ (selectedCustomer.invoicePerMonth === 1 ? 'Monthly billing' : selectedCustomer.invoicePerMonth === 2 ? 'Quarterly billing' : 'Monthly billing') | translate }}
                  </button>
                </div>
                
                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Low resolution')"
                    [style.backgroundColor]="!selectedCustomer.lowResEnabled ? 'red' : 'yellowgreen'">
                    {{ (selectedCustomer.lowResEnabled ? 'Low resolution enable' : 'Low resolution disable') | translate }}
                  </button>
                </div>
                
              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined">
              <mat-card-header style="margin-bottom: 10px">
                <mat-card-title>{{ 'Options' | translate }}</mat-card-title>
              </mat-card-header>

              <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-evenly" fxLayoutGap="20px grid">

                <div *ngIf="selectedCustomer.isDemo == 1">
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Demo duration')">
                    {{ selectedCustomer.demoDuration }} {{ 'days' | translate }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Demo duration' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max active users')">
                    {{ selectedCustomer.maxActiveUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max active users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max passive users')">
                    {{ selectedCustomer.maxPassiveUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max passive users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max webinar users')">
                    {{ selectedCustomer.maxWebinarUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max webinar users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max conference users')">
                    {{ selectedCustomer.maxConferenceUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max conference users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max webinar registered users')">
                    {{ selectedCustomer.maxWebinarRegisteredUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max webinar registered users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max conference registered users')">
                    {{ selectedCustomer.maxConferenceRegisteredUsers }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max conference registered users' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Storage size')">
                    {{ selectedCustomer.maxCourseSize }} GB
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Storage size' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Max recording time')">
                    {{ selectedCustomer.maxRecordingTimeHours }} {{ 'hours' | translate }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Max recording time' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Font size')">
                    {{ selectedCustomer.fontSize }} px
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Font size' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Font name')">
                    {{ selectedCustomer.fontName }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Font name' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Write in overlay')">
                    {{ selectedCustomer.logoText }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Write in overlay' | translate }}</p>
                </div>

                <div>
                  <button mat-flat-button
                    (click)="popupOpen(selectedCustomer, 'Customer code')">
                    {{ selectedCustomer.iButtonUserName }}
                    <mat-icon>edit</mat-icon>
                  </button>
                  <p>{{ 'Customer code' | translate }}</p>
                </div>

              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined">
              
              <mat-card-header style="margin-bottom: 10px">
                <mat-card-title>Stripe</mat-card-title>
              </mat-card-header>

              <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-evenly" fxLayoutGap="20px grid">

                <div>
                  <button mat-fab
                    [matTooltip]="getStripeStatus(selectedCustomer.stripeApiKey, 'text')"
                    [style.background-color]="getStripeStatus(selectedCustomer.stripeApiKey, 'color')"
                    class="mat-elevation-z0 stripe-icons">
                    <mat-icon style="color: white;">
                      {{ getStripeStatus(selectedCustomer.stripeApiKey, 'icon') }}
                    </mat-icon>
                  </button>

                  <p>Stripe Key</p>
                </div>
                
                <div>
                  <button mat-fab
                    [matTooltip]="getStripeStatus(selectedCustomer.stripeApiKeySecret, 'text')"
                    [style.background-color]="getStripeStatus(selectedCustomer.stripeApiKeySecret, 'color')"
                    class="mat-elevation-z0 stripe-icons">
                    <mat-icon style="color: white;">
                      {{ getStripeStatus(selectedCustomer.stripeApiKeySecret, 'icon') }}
                    </mat-icon>
                  </button>

                  <p>Stripe key secret</p>
                </div>

                <div>
                  <button mat-fab
                    [matTooltip]="getStripeStatus(selectedCustomer.stripeWebhookSecret, 'text')"
                    [style.background-color]="getStripeStatus(selectedCustomer.stripeWebhookSecret, 'color')"
                    class="mat-elevation-z0 stripe-icons">
                    <mat-icon style="color: white;">
                      {{ getStripeStatus(selectedCustomer.stripeWebhookSecret, 'icon') }}
                    </mat-icon>
                  </button>

                  <p>Stripe webhook</p>
                </div>

                <div>
                  <button mat-fab
                    (click)="stripeSettingsPopup(selectedCustomer)"
                    [matTooltip]="'Edit' | translate"
                    color="primary"
                    class="mat-elevation-z0 stripe-icons">
                    <mat-icon>
                      edit
                    </mat-icon>
                  </button>

                  <p>{{ 'Edit' | translate }}</p>
                </div>

              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" *ngIf="selectedCustomer.isDemo == 1">
              <mat-card-header>
                <mat-card-title>{{ 'Demo' | translate }} {{ 'validity time' | translate }}</mat-card-title>
              </mat-card-header>

              <mat-card-content style="margin-top: 10px">
                <div>
                  <mat-progress-bar color="primary" mode="determinate" [value]="remainingDaysPerc"></mat-progress-bar>
                </div>
                <div fxLayoutAlign="space-between end" style="margin-top: 10px; font-size: 14px;">
                  <div>{{ selectedCustomer.created | date: 'dd/MM/yyyy' }}</div>
                  <div>{{ remainingDays }} {{ 'Days left' | translate }}</div>
                  <div>{{ expirationDate | date: 'dd/MM/yyyy' }}</div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{ 'Activity' | translate }}</mat-card-title>
              </mat-card-header>

              <mat-card-content fxLayout="row" fxLayoutGap="4%" style="margin-top: 10px">
                <div fxFlex="48" fxLayoutAlign="center center">

                  <div fxLayout="column" fxFlex fxLayoutAlign="center center" [ngClass]="{'opacità': nullGraph[1]}">
                    <span style="font-size: 14px;">{{ 'Users activity' | translate }} ({{ typeTime[1] }})</span>
                    <span *ngIf="nullGraph[1]" style="position: absolute">No Data</span>
                    <canvas baseChart 
                      width="400px" 
                      height="110px" 
                      [datasets]="lineChartData[1]"
                      [labels]="lineChartLabels[1]"
                      [options]="lineChartOptions"
                      [type]="lineChartType">
                    </canvas>
                  </div>
                  
                </div>

                <div fxFlex="48" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
                  <span style="font-size: 14px">
                    {{ 'Registered passive users' | translate }}: {{ selectedCustomer.activeUsers }} ({{ activeUsersPerc }}% {{ 'Used' | translate }})
                  </span>
                  <div fxLayout="column" fxLayoutGap="10px">
                    <mat-progress-bar color="primary" mode="determinate" [value]="activeUsersPerc"></mat-progress-bar>
                    <div fxLayout="row" fxLayoutAlign="space-between end" style="font-size: 16px">
                      <div>0</div>
                      <div>{{ selectedCustomer.maxPassiveUsers }}</div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined">
              <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
                <mat-card-title>Streaming</mat-card-title>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
                  <button mat-mini-fab color="primary" 
                    (click)="exportStreamingTimeToCsv(selectedCustomer.id, selectedCustomer.name)"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                  </button>
                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'Chart type' | translate }}</mat-label>
                    <mat-select [(value)]="selectedType">
                      <mat-option *ngFor="let type of types" [value]="type">
                        {{ type | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-card-header>

              <mat-card-content fxLayout="row" fxLayoutGap="4%" style="margin-top: 10px">

                <div fxFlex="48" fxLayoutAlign="center center">

                  <div fxLayout="column" fxFlex fxLayoutAlign="center center" [ngClass]="{'opacità': nullGraph[0]}">
                    <span style="font-size: 14px;">{{ 'Trasmitted streaming time' | translate }} ({{ typeTime[0] }})</span>
                    <span *ngIf="nullGraph[0]" style="position: absolute">No Data</span>
                    <canvas baseChart 
                      width="400px" 
                      height="110px" 
                      [datasets]="lineChartData[0]"
                      [labels]="lineChartLabels[0]"
                      [options]="lineChartOptions"
                      [type]="selectedType">
                    </canvas>
                  </div>
                  
                </div>

                <div fxFlex="48" fxLayoutAlign="center center">

                  <div fxLayout="column" fxFlex fxLayoutAlign="center center" [ngClass]="{'opacità': nullGraph[2]}">
                    <span style="font-size: 14px;">{{ 'Total streaming time' | translate }} ({{ typeTime[2] }})</span>
                    <span *ngIf="nullGraph[2]" style="position: absolute">No Data</span>
                    <canvas baseChart 
                      width="400px" 
                      height="110px" 
                      [datasets]="lineChartData[2]"
                      [labels]="lineChartLabels[2]"
                      [options]="lineChartOptions"
                      [type]="selectedType">
                    </canvas>
                  </div>
                  
                </div>

              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" fxLayout="column" *ngIf="customerData != null">
              <mat-card-header>
                <mat-card-title>{{ 'Storage' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayoutAlign="center center" fxFlex="25">
                  {{ ('There are' | translate) + ' ' + customerData.blobCount + ' files ' + ('available' | translate) }}
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxFlex="25">
                  <div fxLayout="row" style="margin-bottom: 10px;">
                    {{ 'Space used' | translate }}
                  </div>
                  <app-spinner-container
                    color="primary"
                    [diameter]="75"
                    [strokeWidth]="5"
                    [value]="getStorageValue(customerData) | number: '1.0-2'"
                    [displayWith]="spinnerValue">
                  </app-spinner-container>
                </div>
                <div fxLayoutAlign="center center" fxFlex>
                  {{ ('The customer' | translate) + ' ' + customerData.customer.name + ' ' + ('has at his disposal' | translate) + ' ' + ((customerData.maxSize - customerData.currentSize) | number: '1.0-2') + 'GB ' + ('of the total' | translate) + ' ' + customerData.maxSize + 'GB' }}
                </div>
              </mat-card-content>
            </mat-card>

          </div>
        </ng-template>

      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="customers.length == 0">
      <app-error-message customClass="warning"
        [errorTitle]="'There isnt any data' | translate"
        [errorContent]="'There arent customers with courses enabled' | translate">
      </app-error-message>
    </div>
  </div>

  <mat-paginator 
    *ngIf="customers.length > 0"
    [showFirstLastButtons]="true"
    [length]="customers.length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="changePage($event)"
    class="mat-elevation-z3">
  </mat-paginator>
</div>
