import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { routerTransition } from './router.animation';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { fromEvent, Subscription, SubscriptionLike } from 'rxjs';
import { NavBarService } from './services/nav-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { AzureStorageService } from './services/storage.service';
import { Title } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { DarkThemeService } from './services/dark-theme.service';
import { LANGUAGES, Language, LanguageCodes, TranslationService } from './services/translation.service';
import { SelectionService } from './services/selection.service';
import { WarningService } from './services/warning.service';
import { Location } from '@angular/common';
import { SignalRService } from './services/signalr.service';
import { DeviceCloudSettings } from './models/deviceCloudSettings';
import { Helper } from './helpers/helper';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentHelper, EnvironmentParameterType } from './models/environmentVariables';
import { RolePipe } from './pipes/rolePipe';
import { UserRole } from './models/userRole';

export const DEVICE_CONFIG_KEY: string = "stationConfig";

const ALLOWED_MESSAGE_ORIGIN: string = "file://";
const DEVICE_CONFIG_ACTIVATE: string = "config_activate";
const DEVICE_CONFIG_DEACTIVATE: string = "config_deactivate";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerTransition]

})
export class AppComponent implements OnInit, AfterViewInit {

    icon: string = 'expand_less';
    today: Date = new Date();
    appInfo: string = `${environment.appVersion} - ${environment.appDate}`;
    languages: Language[] = LANGUAGES;

    locationSub: SubscriptionLike;
    paramsSub: Subscription;
    routerSub: Subscription;
    mouseSub: Subscription;
    
    @ViewChild('mainsidenav') public mainsidenav: MatSidenav;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private auth: AuthService,
        private titleService: Title,
        private navBar: NavBarService,
        private snackBar: MatSnackBar,
        public azureService: AzureStorageService, // Non eliminare azureService, serve per popup degli uploads attivi
        public selectionService: SelectionService,// Non eliminare lessonContent, serve per popup della selezione contenuti per la lezione
        private translationService: TranslationService,
        public warningService: WarningService,
        private darkService: DarkThemeService,
        private signalR: SignalRService,
        private cookieService: CookieService,
        private rolePipe: RolePipe) {
        
        // Imposta il titolo vicino al favicon
        this.titleService.setTitle(EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Title));
   
        document.getElementById("appIcon").setAttribute('href', EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Icon)); // Cambia la favicon

        this.setLanguage();

        this.darkService.storeThemeSelection(false);

        //Chiamata con location.back() oppure location.forward()
        this.locationSub = this.location.subscribe(ev => {

            if (this.auth.isAuthenticated() && (ev.url === 'login' || ev.url === '/login' || ev.url === '/login-dt' || ev.url === '/login-sunlight'))
                this.logOut();

        });

        this.routerSub = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {

                let currentPage = event.url.split('/')[1];

                /*  ETTORE 29-09-20 commentato per evitare i re-login */
                if (!router.navigated) {

                    // RIABILITARE PER SOCIAL SHARE
                    if (window.location.pathname !== '/login' && window.location.pathname !== '/login-dt')
                        this.auth.setRequestedPath(window.location.pathname);

                    if (this.auth.isAuthenticated())
                        this.logOut();

                }
                /*  ETTORE 29-09-20 commentato per evitare i re-login */

                if (currentPage !== 'aula' && currentPage !== 'smily-dashboard')
                    this.signalR.stop();

            }

        });

        window.addEventListener('message', (event) => {

            if (event.origin === ALLOWED_MESSAGE_ORIGIN) {

                console.log('Electron event detected');
                console.log(event);

                let message: { type: string, data?: DeviceCloudSettings } = event.data;

                if (message.type === DEVICE_CONFIG_ACTIVATE) {
                    console.log('Adding config');
                    localStorage.setItem(DEVICE_CONFIG_KEY, JSON.stringify(message.data));
                }

                if (message.type === DEVICE_CONFIG_DEACTIVATE) {
                    console.log('Removing config');
                    localStorage.removeItem(DEVICE_CONFIG_KEY);
                }

            }

        }, false);

        this.mouseSub = fromEvent(document, 'mousemove')
            .subscribe(() => {
                this.auth.resetInactivityCheck();
            });
    }

    async ngOnInit () {

        let params = new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop as string) });

        await Helper.sleep(2000);

        if (params['conference'] && window.location.pathname === '/login')
            this.auth.setRequestedPath('/conference', params['conference']);

        if (params['webinar'] && (window.location.pathname === '/login' || window.location.pathname === '/login-dt' || window.location.pathname === '/login-sunlight'))
            this.auth.setRequestedPath('/webinar', params['webinar']);

        if (params['User']) {

            let redirect = window.location.pathname === '/success'
                         ? '/success'
                         : window.location.pathname === '/failed'
                         ? '/failed'
                         : null;

            if (redirect != null) {

                //let username = params['User'];

                let usr: any = JSON.parse(sessionStorage.getItem('forrelogin'));
                let config: DeviceCloudSettings = Helper.getDeviceConfig();

                try {

                    await (!config ? this.auth.login(usr.UserName, usr.Password) : this.auth.loginStation(usr.UserName, usr.Password, config));

                    this.router.navigateByUrl(redirect);

                }
                catch (e) {
                    console.log(e);
                }

            }

        }

        if (localStorage.getItem('cookie_policy') != 'true')
            this.openCookieSnackbar();

        if (environment.mainPage !== 'login' && this.cookieService.check('auth_token'))
            this.router.navigate(['login']);
        
    }

    ngOnDestroy () {

        this.locationSub.unsubscribe();
        this.paramsSub.unsubscribe();
        this.routerSub.unsubscribe();
        this.mouseSub.unsubscribe();

    }

    async ngAfterViewInit () {

        this.navBar.setSidenav(this.mainsidenav);

    }

    logOut () {
        this.auth.logout();
    }

    isAuthenticated () {
        return this.auth.isAuthenticated();
    }

    isAdmin () {
        return this.auth.isAdmin();
    }

    isCustomerAdmin () {
        return this.auth.isCustomerAdmin();
    }

    isTutor () {
        return this.auth.isTutor();
    }

    isTeacher () {
        return this.auth.isTeacher();
    }

    isSpeaker () {
        return this.auth.isSpeaker();
    }

    isStudent () {
        return this.auth.isStudent();
    }

    isAuthor() {
        return this.auth.getCurrentUser()?.isAuthor;
    }

    isStripeAble () {
        return this.auth.isStripeAble();
    }

    coursesEnabled() {
        return this.auth.getCurrentUser()?.coursesEnabled;
    }

    mastersEnabled() {
        return this.auth.getCurrentUser()?.mastersEnabled;
    }

    smilyEnabled () {
        return this.auth.getCurrentUser()?.smilyEnabled;
    }

    streamingEnabled() {
        return this.auth.getCurrentUser()?.streamingEnabled;
    }

    importEnabled () {
        return this.auth.getCurrentUser()?.importEnabled;
    }

    reloadPage () {
        this.router.navigateByUrl(this.isAuthenticated() ? this.auth.mainPage : environment.mainPage);
    }

    goToLesson () {
        this.router.navigate(['/lessons']);
    }

    goToConference () {
        this.router.navigate(['/conferences']);
    }

    goToWebinarCustomer() {
        this.router.navigate(['/webinar-customer']);
    }

    goToMasters() {
        this.router.navigate(['/masters-user']);
    }

    goToUsers () {
        this.router.navigate(['/users']);
    }

    goToClassroom () {
        this.router.navigate(['/classrooms/']);
    }

    goToCalendar () {
        this.router.navigate(['/calendar']);
    }

    goToWall () {
        this.router.navigate(['/wall-page']);
    }

    goToSubscription () {
        this.router.navigate(['/subscription']);
    }

    goToRegistry (events: boolean = false) {
        let params = null;

        if (events)
            params = { mode: 'export' };

        this.router.navigate(['/registry'], { queryParams: params });
    }

    goToStations () {
        this.router.navigate(['/aula']);
    }

    goToChangelog () {
        if (this.isStudent())
            return;

        this.router.navigate(['/changelog']);
    }

    goToCustomers () {
        this.router.navigate(['/customers']);
    }

    goToAccount () {
        this.router.navigate(['/profile']);
    }

    goToTranslations () {
        this.router.navigate(['/translations']);
    }

    goToStreaming () {
        this.router.navigate(['/streamings']);
    }

    goToDashboard () {
        this.router.navigate(['/dashboard']);
    }

    goToCustomersDashboard () {
        this.router.navigate(['/dashboard-customers']);
    }

    goToCart () {
        this.router.navigate(['/cart']);
    }

    goToPurchased () {
        this.router.navigate(['/purchased']);
    }

    goToPayments () {
        this.router.navigate(['/onboard']);
    }

    goToPaymentsList () {
        this.router.navigate(['/payments']);
    }

    goToOrders () {
        this.router.navigate(['/orders']);
    }

    goEasyTeachOnTrayDownload () {
        window.open(`${environment.apiUrl}/app/publish.htm`, "_blank");
    }

    goToSurvey () {
        this.router.navigate(['/survey-list']);
    }

    goToWarnings() {
        this.router.navigate(['/warnings']);
    }

    goToLogin() {
        this.router.navigate(['/login']);
    }
    
    goToPlans() {
        this.router.navigate(['/eduplan-list']);
    }

    goToShowcase() {
        this.router.navigate(['/showcase']);
    }

    getLanguage() {
        return this.translationService.currentLang.description
    }

    setLanguage(lang?: string) {
        this.translationService.setLang(lang as LanguageCodes);
    }

    setIcon (open: boolean) {
        this.icon = open ? 'expand_more' : 'expand_less';
    }

    isGD() {
        return this.auth.isGD();
    }

    isDT() {
        return this.auth.isDT();
    }

    isSL() {
        return this.auth.isSL();
    }

    openCookieSnackbar () {
        this.snackBar.openFromComponent(CookieBannerComponent, { panelClass: 'cookie-banner' });
    }

    isLoginPage() {
        return window.location.pathname === '/login';
    }
    
    eduPlanEnabled() {
        if (!this.isAdmin() && !this.isCustomerAdmin())
            return this.auth.mainPage === 'eduplans';

        return this.auth.getCurrentUser()?.eduPlanEnabled;
    }

    getRole() {
       return this.rolePipe.transform(UserRole.role(this.auth.getCurrentUser()));
    }

    openPrivacy() {
        window.open(EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Privacy), "_blank");
    }

    openCookies() {
        window.open(EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Cookie), "_blank");
    }

    getLogo() {
        if (this.auth.getCurrentUser()?.platformLogoUrl != null)
            return this.auth.getCurrentUser().platformLogoUrl;

        return EnvironmentHelper.getConfigAuto(this.auth, this.isDark() ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
    }

    getUrl() {
        return EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Url);
    }

    getName() {
        return EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Name);
    }

    getPhone() {
        return EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Phone);
    }

    isDark() {
        return this.darkService.isSetDark;
    }

    isDarkClass() {
        return this.darkService.isSetDarkClass;
    }

    isBarVisible(bar: 'sidenav' | 'topbar' | 'bottombar') {
        let status = bar === 'sidenav'
                   ? this.navBar.sidenavStatus
                   : bar === 'topbar'
                   ? this.navBar.topbarStatus
                   : bar === 'bottombar'
                   ? this.navBar.bottombarStatus
                   : undefined;

        return status === 'visible';
    }

    toggleSidenav() {
        this.navBar.toggle('sidenav');
    }

}
