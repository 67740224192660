<h1 mat-dialog-title>
  {{ data.type | translate }}
</h1>
<div mat-dialog-content>
  <div *ngIf="popupType === 'boolean'"> 
    {{ 'Are you sure to edit the section' | translate }} {{ data.type | translate }} in {{ value | translate }}?
  </div>
  <div *ngIf="popupType === 'textformandcheckbox'">
    <mat-form-field>
      <input matInput type="text" [formControl]="valueForm">
    </mat-form-field>
    <mat-checkbox
      [(value)]="valueCheckbox"
      [checked]="valueForm.value == undefined"
      (change)="checkEnable($event)">
      {{ 'Disable' | translate }}
    </mat-checkbox>
  </div>
  <div *ngIf="popupType === 'numberform'">
    <mat-form-field>
      <input matInput type="number" [formControl]="valueForm">
    </mat-form-field>
  </div>
  <div *ngIf="popupType === 'fontselect'">
    <mat-form-field>
      <mat-select [formControl]="valueForm">
        <mat-option value="Sans">Sans</mat-option>
        <mat-option value="Tahoma">Tahoma</mat-option>
        <mat-option value="Verdana">Verdana</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="popupType === 'textform'">
    <mat-form-field>
      <input matInput type="text" [formControl]="valueForm">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
  <button mat-flat-button color="accent" (click)="onSave()" [disabled]="disableBtn()">Ok</button>
</div>
