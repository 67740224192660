<div class="bar-container"
    *ngIf="mode === 'webinar' && !refresh"
    [class]="participantsClass"
    [class.overlay-container]="overlay"
    [class.mat-elevation-z24]="overlay"
    [class.standard-height]="!overlay"
    [class.drag-container]="isDragging"
    (cdkDragStarted)="dragStart($event)"
    (cdkDragEnded)="dragEnd($event)"
    [cdkDragBoundary]="dragBoundary"
    [cdkDragDisabled]="!overlay"
    cdkDrag>

    <button mat-fab [style.display]="isDragging ? 'block' : 'none'">
        <mat-icon>people</mat-icon>
    </button>

    <div id="content-container"
        [style.display]="isDragging ? 'none' : 'block'"
        [fxLayout]="participantsClass === 'top-container' ? 'row' : 'column'"
        fxLayoutGap="5px"
        fxFlex>

        <div [class]="participantsClass === 'top-container' ? 'participants-list-toolbar-horizontal' : 'participants-list-toolbar-vertical'"
            [fxLayout]="participantsClass === 'top-container' ? 'column' : 'row'"
            *ngIf="overlay || lowerAllHandsActive"
            fxLayoutAlign="space-between center">

            <button mat-icon-button *ngIf="overlay" [matTooltip]="'Move' | translate" class="buttons" cdkDragHandle>
                <mat-icon>open_with</mat-icon>
            </button>

            <button mat-icon-button *ngIf="lowerAllHandsActive" (click)="onLowerAllHands.emit(true)" [matTooltip]="'Lower all hands' | translate" class="buttons">
                <mat-icon>do_not_touch</mat-icon>
            </button>

            <button mat-icon-button *ngIf="overlay" (click)="close()" [matTooltip]="'Close' | translate" class="buttons">
                <mat-icon>close</mat-icon>
            </button>

        </div>

        <div [class]="participantsClass === 'top-container' ? 'participants-list-horizontal' : 'participants-list-vertical'"
            [fxLayout]="participantsClass === 'top-container' ? 'row' : 'column'"
            fxLayoutAlign="start center"
            fxLayoutGap="5px">

            <div *ngIf="currentParticipant" class="participant-stream mat-elevation-z4">
                <app-user-video
                    [streamManager]="currentParticipant?.manager"
                    [name]="currentParticipant?.name"
                    [color]="currentParticipant?.color"
                    [visualType]="visualType"
                    [displayOutline]="true"
                    [handRaised]="currentParticipant?.handRaised"
                    [compactMode]="true"
                    [lowerHandEnabled]="lowerHandEnabled"
                    (onLowerHand)="lowerHand(currentParticipant?.userId)">

                    <ng-container *ngTemplateOutlet="
                        optionMenuBtn;
                        context: {
                            participant: currentParticipant,
                            compactMode: true,
                            disableOptions: false,
                            disableSetAs: false,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>

                </app-user-video>
            </div>

            <div *ngFor="let presenter of getOtherParticipants('presenters')" [style.height]="selectionEnabled ? '130px' : '90px'" class="participant-stream">
                <app-user-video
                    [streamManager]="presenter.manager"
                    [name]="presenter.name"
                    [color]="presenter.color"
                    [visualType]="visualType"
                    [handRaised]="presenter.handRaised"
                    [compactMode]="true"
                    [lowerHandEnabled]="lowerHandEnabled"
                    (onLowerHand)="lowerHand(presenter.userId)"
                    class="mat-elevation-z4">

                    <ng-container *ngTemplateOutlet="
                        optionMenuBtn;
                        context: {
                            participant: presenter,
                            compactMode: true,
                            disableOptions: false,
                            disableSetAs: false,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>

                </app-user-video>
            </div>

            <div *ngFor="let participant of getOtherParticipants('participants')" [style.height]="selectionEnabled ? '130px' : '90px'" class="participant-stream">
                <app-user-video
                    [streamManager]="participant.manager"
                    [name]="participant.name"
                    [color]="participant.color"
                    [visualType]="visualType"
                    [handRaised]="participant.handRaised"
                    [compactMode]="true"
                    [lowerHandEnabled]="lowerHandEnabled"
                    (onLowerHand)="lowerHand(participant.userId)"
                    class="mat-elevation-z4">

                    <ng-container *ngTemplateOutlet="
                        optionMenuBtn;
                        context: {
                            participant: participant,
                            compactMode: true,
                            disableOptions: false,
                            disableSetAs: true,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>

                </app-user-video>
            </div>

        </div>

    </div>
    
</div>

<div id="grid-container" *ngIf="mode === 'conference'">

    <mat-grid-list
        [cols]="gridRowLength"
        [rowHeight]="gridElementHeight"
        [gutterSize]="gridElementPadding"
        class="grid">

        <mat-grid-tile
            *ngFor="let participant of participants; let i = index;"
            [colspan]="isLastRowElement(i) ? gridElementsLengthLast : gridElementsLength"
            [rowspan]="1">

            <div class="grid-stream"
                (click)="onSelectedParticipant(participant.userId)"
                [class.grid-stream-border]="participant.userId === currentUser.id"
                [class.grid-stream-selection]="isSelectedParticipant(participant.userId)"
                [class.grid-stream-selection-hover]="selectionEnabled && currentUser.id !== participant.userId && !isSelectedParticipant(participant.userId)">

                <app-user-video
                    [streamManager]="participant.manager"
                    [name]="participant.name"
                    [color]="participant.color"
                    [visualType]="visualType"
                    [handRaised]="participant.handRaised"
                    [lowerHandEnabled]="lowerHandEnabled"
                    (onLowerHand)="lowerHand(participant.userId)"> <!-- [displayOutline]="participant.userId === currentUser.id" -->
    
                    <ng-container *ngTemplateOutlet="
                        optionMenuBtn;
                        context: {
                            participant: participant,
                            compactMode: false,
                            disableOptions: false,
                            disableSetAs: false,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>
    
                </app-user-video>

            </div>

        </mat-grid-tile>

    </mat-grid-list>

</div>
