import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { LoginComponent } from './pages/logins/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ClassroomComponent } from './pages/classroom/classroom.component';
import { LessonsComponent } from './pages/lessons/lessons.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { ClassroomIdComponent } from './pages/classroom-id/classroom-id.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CalendarUserListComponent } from './pages/calendar-user-list/calendar-user-list.component';
import { RegistryComponent } from './pages/registry/registry.component';
import { ClassroomStatusComponent } from './pages/classroom-status/classroom-status.component';
import { UserStatusComponent } from './pages/user-status/user-status.component';
import { LessonVideoComponent } from './pages/lesson-video/lesson-video.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { AuthorprofileComponent } from './pages/authorprofile/authorprofile.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { WallComponent } from './wall/wall.component';
import { WallPageComponent } from './wall/wall-page/wall-page.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { SubscriptionHomeComponent } from './ecommerce/subscription-home.component';
import { SubscriptionSellerComponent } from './ecommerce/subscription-seller/subscription-seller.component';
import { SubscriptionBuyerComponent } from './ecommerce/subscription-buyer/subscription-buyer.component';
import { CartPageComponent } from './ecommerce/cart-page/cart-page.component';
import { SuccessComponent } from './ecommerce/success/success.component';
import { FailedComponent } from './ecommerce/failed/failed.component';
import { PurchasedComponent } from './ecommerce/purchased/purchased.component';
import { OrdersComponent } from './ecommerce/orders/orders.component';
import { StartOnboardingComponent } from './ecommerce/start-onboarding/start-onboarding.component';
import { ReauthenticateComponent } from './ecommerce/reauthenticate/reauthenticate.component';
import { ReturnstripeComponent } from './ecommerce/returnstripe/returnstripe.component';
import { DataDeletionComponent } from './pages/data-deletion/data-deletion.component';
import { WarningListComponent } from './pages/warning-list/warning-list.component';
import { SmilyRecordingComponent } from './pages/smily-recording/smily-recording.component';
import { CourseListComponent } from './pages/course/course-list/course-list.component';
import { CourseProfileComponent } from './pages/course/course-profile/course-profile.component';
import { CourseVideoComponent } from './pages/course/course-video/course-video.component';
import { CourseViewComponent } from './pages/course/course-video/course-view/course-view.component';
import { CourseAnalyticsComponent } from './pages/course/course-analytics/course-analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StreamingInfoComponent } from './pages/streaming-info/streaming-info.component';
import { MasterListComponent } from './pages/master/master-list/master-list.component';
import { MasterProfileComponent } from './pages/master/master-profile/master-profile.component';
import { StanzaComponent } from './pages/stanza/stanza.component';
import { PublicUserConfirmComponent } from './pages/public-user-confirm/public-user-confirm.component';
import { SurveyPageComponent } from './survey/survey-pages/survey-page/survey-page.component';
import { PackageComponent } from './pages/package/package.component';
import { SmilyDashboardComponent } from './pages/smily-dashboard/smily-dashboard.component';
import { SubscriptionLevelComponent } from './pages/subscription-level/subscription-level.component';
import { EduplanListComponent } from './pages/eduplan-list/eduplan-list.component';
import { EduplanShowcaseComponent } from './pages/eduplan-showcase/eduplan-showcase.component';
import { environment } from 'src/environments/environment';
import { ContentScormViewComponent } from './pages/course/course-video/content-scorm-view/content-scorm-view.component';
import { ConferenceSessionComponent } from './pages/conference-session/conference-session.component';
import { ConferencesComponent } from './pages/conferences/conferences.component';
import { DashboardCustomersComponent } from './pages/dashboard/dashboard-customers/dashboard-customers.component';
import { ConferenceReportComponent } from './pages/conferences/conference-report/conference-report.component'
import { CustomerConfirmComponent } from './pages/customer-confirm/customer-confirm.component';
import { DentaltreyLoginComponent } from './pages/logins/dentaltrey-login/dentaltrey-login.component';
import { WebinarSessionComponent } from './pages/webinar-session/webinar-session.component';
import { WebinarShowcaseComponent } from './pages/webinars/webinar-showcase/webinar-showcase.component';
import { WebinarListComponent } from './pages/webinars/webinar-list/webinar-list.component';
import { ViqirobotLoginComponent } from './pages/logins/viqirobot-login/viqirobot-login.component';
import { SurveyResultsPageComponent } from './survey/survey-pages/survey-results-page/survey-results-page.component';
import { SurveyResultPageComponent } from './survey/survey-pages/survey-result-page/survey-result-page.component';
import { SurveyCreatorPageComponent } from './survey/survey-pages/survey-creator-page/survey-creator-page.component';
import { SurveyTemplateListPageComponent } from './survey/survey-pages/survey-template-list-page/survey-template-list-page.component';
import { PaymentsComponent } from './ecommerce/payments/payments.component';
import { WebinarCustomerComponent } from './pages/webinars/webinar-customer/webinar-customer.component';
import { SunlightLoginComponent } from './pages/logins/sunlight-login/sunlight-login.component';
import { Smartcabinet360LoginComponent } from './pages/logins/smartcabinet360-login/smartcabinet360-login.component';
import { RouteReusePath } from './models/routeReusePath';
import { FitxpLoginComponent } from './pages/logins/fitxp-login/fitxp-login.component';
import { GammadonnaLoginComponent } from './pages/logins/gammadonna-login/gammadonna-login.component';
import { MobileAppLinkComponent } from './components/mobile-app-link/mobile-app-link.component';
import { HroconsultingLoginComponent } from './pages/logins/hroconsulting-login/hroconsulting-login.component';
import { SurveyAnalyticsComponent } from './survey/survey-pages/survey-analytics/survey-analytics.component';
import { HufriedyLoginComponent } from './pages/logins/hufriedy-login/hufriedy-login.component';
import { AixpacademyLoginComponent } from './pages/logins/aixpacademy-login/aixpacademy-login.component';
import { MasterListUserComponent } from './pages/master/master-list-user/master-list-user.component';
import { LessonSessionComponent } from './pages/lesson-session/lesson-session.component';

const routes: Routes = [
    { path: 'classrooms', component: ClassroomComponent, canActivate: [AuthGuard] },
    { path: 'classroom/:id', component: ClassroomIdComponent, canActivate: [AuthGuard] },
    { path: 'lessons', component: LessonsComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: ProfileUserComponent, canActivate: [AuthGuard] },
    { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
    { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },
    { path: 'calendar/:id', component: CalendarUserListComponent, canActivate: [AuthGuard] },
    { path: 'registry', component: RegistryComponent, canActivate: [AuthGuard] },
    { path: 'classroomstatus/:id', component: ClassroomStatusComponent, canActivate: [AuthGuard] },
    { path: 'userstatus/:id', component: UserStatusComponent, canActivate: [AuthGuard] },
    { path: 'lessonvideo', component: LessonVideoComponent, canActivate: [AuthGuard] },
    { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
    { path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard] },
    { path: 'courses', component: CourseListComponent, canActivate: [AuthGuard] },
    { path: 'course/:id', component: CourseProfileComponent, canActivate: [AuthGuard] },
    { path: 'course-content/:id', component: CourseVideoComponent, canActivate: [AuthGuard] },
    { path: 'coursetracker/:id', component: CourseViewComponent, canActivate: [AuthGuard] },
    { path: 'course-analytics/:id', component: CourseAnalyticsComponent, canActivate: [AuthGuard] },
    { path: 'authorprofile/:id', component: AuthorprofileComponent, canActivate: [AuthGuard] },
    { path: 'translations', component: TranslationsComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-customers', component: DashboardCustomersComponent, canActivate: [AuthGuard] },
    { path: 'streamings', component: StreamingInfoComponent, canActivate: [AuthGuard] },
    { path: 'masters', component: MasterListComponent, canActivate: [AuthGuard] },
    { path: 'master/:id', component: MasterProfileComponent, canActivate: [AuthGuard] },
    { path: 'aula', component: StanzaComponent, canActivate: [AuthGuard] },
    { path: 'showcase', component: ShowcaseComponent, canActivate: [AuthGuard] },
    { path: 'wall', component: WallComponent, canActivate: [AuthGuard] },
    { path: 'wall-page', component: WallPageComponent, canActivate: [AuthGuard] },
    { path: 'subscription', component: SubscriptionHomeComponent, canActivate: [AuthGuard] },
    { path: 'subscription-seller', component: SubscriptionSellerComponent, canActivate: [AuthGuard] },
    { path: 'subscription-buyer', component: SubscriptionBuyerComponent, canActivate: [AuthGuard] },
    { path: 'forgot', component: ForgotPasswordComponent },
    { path: 'forgot/:token', component: ForgotPasswordComponent },
    { path: 'confirm/:type/:token', component: PublicUserConfirmComponent },
    { path: 'cart', component: CartPageComponent },
    { path: 'success', component: SuccessComponent },
    { path: 'failed', component: FailedComponent },
    { path: 'purchased', component: PurchasedComponent },
    { path: 'orders', component: OrdersComponent , canActivate: [AuthGuard] },
    { path: 'onboard', component: StartOnboardingComponent , canActivate: [AuthGuard] },
    { path: 'reauthenticate', component: ReauthenticateComponent },
    { path: 'returnstripe', component: ReturnstripeComponent },
    { path: 'survey-creator', component: SurveyCreatorPageComponent, canActivate: [AuthGuard] },
    { path: 'survey-creator/:id', component: SurveyCreatorPageComponent, canActivate: [AuthGuard] },
    { path: 'surveys-template', component: SurveyTemplateListPageComponent, canActivate: [AuthGuard] },
    { path: 'survey/:id', component: SurveyPageComponent, canActivate: [AuthGuard] },
    { path: 'survey-result/:id', component: SurveyResultPageComponent, canActivate: [AuthGuard] },
    { path: 'survey-results/:id', component: SurveyResultsPageComponent, canActivate: [AuthGuard] },
    { path: 'survey-analytics/:id', component: SurveyAnalyticsComponent, canActivate: [AuthGuard] },
    { path: 'deletion/:id', component: DataDeletionComponent },
    { path: 'package/:id', component: PackageComponent, canActivate: [AuthGuard] },
    { path: 'warnings', component: WarningListComponent, canActivate: [AuthGuard] },
    { path: 'smily-recordings/:id', component: SmilyRecordingComponent, canActivate: [AuthGuard] },
    { path: 'smily-dashboard/:id', component: SmilyDashboardComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'level/:key', component: SubscriptionLevelComponent },
    { path: 'eduplan-list', component: EduplanListComponent, canActivate: [AuthGuard] },
    { path: 'eduplans', component: EduplanShowcaseComponent, canActivate: [AuthGuard] },
    { path: 'content-scorm-view/:id', component: ContentScormViewComponent, canActivate: [AuthGuard] },
    { path: 'conference/:id', component: ConferenceSessionComponent, canActivate: [AuthGuard] },
    { path: 'conference-report/:id', component: ConferenceReportComponent, canActivate: [AuthGuard] },
    { path: 'conferences', component: ConferencesComponent, canActivate: [AuthGuard]},
    { path: 'customer-confirm/:type/:id/:token', component: CustomerConfirmComponent },
    { path: 'login-dt', component: DentaltreyLoginComponent },
    { path: 'webinar/:id', component: WebinarSessionComponent, canActivate: [AuthGuard] },
    { path: 'webinar-showcase/:id', component: WebinarShowcaseComponent, canActivate: [AuthGuard] },
    { path: 'webinars', component: WebinarListComponent, canActivate: [AuthGuard] },
    { path: 'login-viqirobot', component: ViqirobotLoginComponent },
    { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard] },
    { path: 'webinar-customer', component: WebinarCustomerComponent, canActivate: [AuthGuard] },
    { path: 'login-sunlight', component: SunlightLoginComponent},
    { path: 'login-smartcabinet360', component: Smartcabinet360LoginComponent },
    { path: 'login-fitxp', component: FitxpLoginComponent },
    { path: 'login-gammadonna', component: GammadonnaLoginComponent },
    { path: 'mobile-app-link/:type/:id', component: MobileAppLinkComponent },
    { path: 'login-hro', component: HroconsultingLoginComponent },
    { path: 'login-hf', component: HufriedyLoginComponent },
    { path: 'login-aa', component: AixpacademyLoginComponent },
    { path: 'masters-user', component: MasterListUserComponent, canActivate: [AuthGuard] },
    { path: 'lesson/:id', component: LessonSessionComponent, canActivate: [AuthGuard] },
    {
        path: '**', redirectTo: environment.mainPage, pathMatch:"full"
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [{ provide: RouteReuseStrategy, useClass: RouteReusePath }]
})
export class AppRoutingModule { }
