import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { DemoPopUpComponent } from 'src/app/popup/demo-pop-up/demo-pop-up.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    mode: 'login' | 'signup' = 'login';
    isMobile: boolean = false;

    name: string = EnvironmentHelper.getConfig(undefined, EnvironmentParameterType.Name);
    background: string = EnvironmentHelper.getConfig(undefined, EnvironmentParameterType.BackgroundImage);
    motto: string = environment.applicationMotto;

    constructor(
        private auth: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private themeService: DarkThemeService,
        private deviceService: DeviceDetectorService
    ) {
        if (EnvironmentHelper.isDT())
            this.router.navigate(['/login-dt']);
        else if (EnvironmentHelper.isVR())
            this.router.navigate(['/login-viqirobot']);
        else if (EnvironmentHelper.isSL())
            this.router.navigate(['/login-sunlight']);
        else if (EnvironmentHelper.isSC360())
            this.router.navigate(['/login-smartcabinet360']);
        else if (EnvironmentHelper.isFitxp())
            this.router.navigate(['/login-fitxp']);
        else if (EnvironmentHelper.isGD())
            this.router.navigate(['/login-gammadonna']);
        else if (EnvironmentHelper.isHRO())
            this.router.navigate(['/login-hro']);
        else if (EnvironmentHelper.isHF())
            this.router.navigate(['/login-hf']);
        else if (EnvironmentHelper.isAA())
            this.router.navigate(['/login-aa']);
        else if (EnvironmentHelper.isLicar())
            this.router.navigate(['/login-lcr']);
        else if (EnvironmentHelper.isIdeandum())
            this.router.navigate(['/login-idn']);

        this.isMobile = this.deviceService.isMobile();
        this.themeService.onLogOut();
    }

    changeMode(newMode: 'login' | 'signup') {
        this.mode = newMode;
    }

    isLoginMode() {
        return this.mode === 'login';
    }

    isSignUpMode() {
        return this.mode === 'signup';
    }

    openRequireDemoPopup(){
        this.dialog.open(DemoPopUpComponent, { width: '450px' });
    }

}
