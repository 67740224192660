<div fxLayout="column">

    <div class="title" fxLayoutAlign="space-between center">
        <h3>{{ title | translate }}</h3>
        <div>
            <button mat-icon-button
                (click)="toggleNotificationSound()"
                [matTooltip]="(emitNotificationSound ? 'Disable notification' : 'Enable Notification') | translate">
                <mat-icon>{{ emitNotificationSound ? 'volume_up' : 'volume_off' }}</mat-icon>
            </button>
            <button mat-icon-button (click)="close()" [matTooltip]="'Close' | translate">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <mat-tab-group [selectedIndex]="currentChat" (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let chat of chats; let i = index" [label]="chat.type === 'direct' ? chat.data.name : ('All' | translate)">

            <ng-template mat-tab-label>
                <span [matBadge]="chat.unreadMessages" [matBadgeHidden]="chat.unreadMessages === 0">
                    {{ chat.type === 'direct' ? chat.data.name : ('All' | translate) }}
                </span>
            </ng-template>

            <mat-list role="list" class="message-list" #list>
   
                <mat-list-item *ngFor="let msg of chat.messages; let j = index;"
                    [class.message-forwarded]="msg.forwarded"
                    [class.message-hidden]="msg.hide"
                    [class.message-new]="msg.read === false"
                    [style.display]="msg.hide && !showHiddenMessages ? 'none' : 'block'"
                    class="message-item"
                    role="listitem">

                    <div fxLayout="row" fxLayoutGap="10px" fxFlex>
                        <app-profile-picture fxFlex="48px"
                            [style.visibility]="msg.userId === chat.messages[j-1]?.userId ? 'hidden' : 'visible'"
                            [url]="msg.picture"
                            size="40px">
                        </app-profile-picture>
                        <div fxLayout="column" fxFlex>
                            <div *ngIf="msg.userId !== chat.messages[j-1]?.userId">
                                <strong>{{ msg.userId === currentUser.id ? ('Me' | translate) : msg.username }}</strong>
                            </div>
                            <div [innerHtml]="msg.message" style="word-break: break-word; white-space: normal;">
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="end end" fxFlex="15">
                            <button mat-icon-button
                                *ngIf="enableOptions(msg, i)"
                                [matMenuTriggerFor]="optionsMenu"
                                [matMenuTriggerData]="{ msg: msg, i: i }">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <div>
                                {{ msg.date | date: 'HH:mm' }}
                            </div>
                        </div>
                    </div>
                    
                </mat-list-item>

            </mat-list>

        </mat-tab>
    </mat-tab-group>

    <div class="input-text" fxLayoutGap="10px">
        <mat-form-field
            (keydown)="onKeydownEvent($event, message.valid && isChatActive())"
            [style.padding-right.px]="hideActive ? 0 : 20"
            color="accent"
            appearance="fill"
            fxFlex>
            <mat-label>{{ inputText | translate }}</mat-label>
            <textarea matInput [formControl]="message" cdkFocusInitial></textarea>
            <button mat-icon-button matSuffix
                (click)="sendMessage()"
                [disabled]="!message.valid || !isChatActive()">
                <mat-icon>send</mat-icon>
            </button>
            <mat-error>
                {{ 'Message should not exceed value characters' | translate: { value: 300 } }}
            </mat-error>
        </mat-form-field>
        <div fxLayoutAlign="center center" *ngIf="hideActive" style="padding-right: 10px">
            <button mat-icon-button
                (click)="showHiddenMessages = !showHiddenMessages"
                [matTooltip]="(showHiddenMessages ? 'Hide' : 'Show') | translate">
                <mat-icon>{{ showHiddenMessages ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
        </div>
    </div>

</div>

<mat-menu #optionsMenu="matMenu" xPosition="before" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-msg="msg" let-i="i">
        <button mat-menu-item
            *ngIf="enableOptions(msg, i, 'reply')"
            (click)="replyTo(msg.userId)">
            <mat-icon>reply</mat-icon>
            {{ 'Reply' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="enableOptions(msg, i, 'forward')"
            [disabled]="!forwardChats || forwardChats.length === 0"
            [matMenuTriggerFor]="forwardMenu"
            [matMenuTriggerData]="{ cts: forwardChats, msg: msg }">
            <mat-icon>forward</mat-icon>
            {{ 'Forward' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="enableOptions(msg, i, 'hide')"
            (click)="msg.hide = !msg.hide">
            <mat-icon>{{ msg.hide ? 'visibility' : 'visibility_off' }}</mat-icon>
            {{ (msg.hide ? 'Show' : 'Hide') | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #forwardMenu="matMenu" xPosition="before" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-cts="cts" let-msg="msg">
        <button mat-menu-item *ngFor="let c of cts; let j = index"
            (click)="forwardMessage(j, msg)">
            {{ c.type === 'direct' ? c.data.name : ('All' | translate) }}
        </button>
    </ng-template>
</mat-menu>
