import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { CustomerUpdateEntity } from 'src/app/models/dto/customerUpdateEntity';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.scss']
})
export class DashboardPopupComponent implements OnInit {

  customer: CustomerDTO = undefined;

  valueForm: FormControl<string | number> = new FormControl<string | number>(undefined);
  valueCheckbox: boolean = false;
  value: string = undefined;

  popupType: 'boolean' | 'textformandcheckbox' | 'numberform' | 'fontselect' |  'textform' = 'boolean';

  constructor(
    public dialogRef: MatDialogRef<DashboardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private calendar: CalendarService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.customer = this.data.customer;

    this.setValues();
  }

  onSave() {
    let customerPut: CustomerUpdateEntity = {
      name: this.customer.name,
      description: this.customer.description,
      isDemo: this.customer.isDemo,
      authorized: this.customer.authorized,
      demoDuration: this.customer.demoDuration,
      coursesEnabled: this.customer.coursesEnabled,
      mastersEnabled: this.customer.mastersEnabled,
      eduPlanEnabled: this.customer.eduPlanEnabled,
      smilyEnabled: this.customer.smilyEnabled,
      maxActiveUsers: this.customer.maxActiveUsers,
      maxPassiveUsers: this.customer.maxPassiveUsers,
      maxWebinarUsers: this.customer.maxWebinarUsers,
      maxConferenceUsers: this.customer.maxConferenceUsers,
      maxWebinarRegisteredUsers: this.customer.maxWebinarRegisteredUsers,
      maxConferenceRegisteredUsers: this.customer.maxConferenceRegisteredUsers,
      maxCourseSize: this.customer.maxCourseSize,
      maxRecordingTimeHours: this.customer.maxRecordingTimeHours,
      logoText: this.customer.logoText,
      streamingEnabled: this.customer.streamingEnabled,
      lowRes: this.customer.lowResEnabled,
      fontSize: this.customer.fontSize,
      customerCode: this.customer.iButtonUserName,
      fontName: this.customer.fontName,
      invoicePerMonth: this.customer.invoicePerMonth,
      renewDemo: this.data.type == "Reset demo"
    };
    
    if (this.data.type == "Demo")
      customerPut.isDemo = this.customer.isDemo == 0 ? 1 : 0;

    if (this.data.type == "Demo duration")
      customerPut.demoDuration = this.valueForm.value as number;

    if (this.data.type == "Billing period")
      customerPut.invoicePerMonth = this.customer.invoicePerMonth == 1 ? 2 : 1;

    if (this.data.type == "Billing")
      customerPut.invoicePerMonth = this.customer.invoicePerMonth == 0 ? 1 : 0;

    if (this.data.type == "Courses")
      customerPut.coursesEnabled = this.customer.coursesEnabled == 0 ? 1 : 0;

    if (this.data.type == "Authorized")
      customerPut.authorized = this.customer.authorized == 0 ? 1 : 0;

    if (this.data.type == "Masters")
      customerPut.mastersEnabled = this.customer.mastersEnabled == 0 ? 1 : 0;

    if (this.data.type == "Smily")
      customerPut.smilyEnabled = this.customer.smilyEnabled == 0 ? 1 : 0;

    if (this.data.type == "Educational plans")
      customerPut.eduPlanEnabled = this.customer.eduPlanEnabled == 0 ? 1 : 0;

    if (this.data.type == "Streaming")
      customerPut.streamingEnabled = this.customer.streamingEnabled == 0 ? 1 : 0;

    if (this.data.type == "Low resolution")
      customerPut.lowRes = this.customer.lowResEnabled == 0 ? 1 : 0;

    if (this.data.type == "Write in overlay")
      customerPut.logoText = this.valueForm.value as string;

    if (this.data.type == "Font size")
      customerPut.fontSize = this.valueForm.value as number;

    if (this.data.type == "Max active users")
      customerPut.maxActiveUsers = this.valueForm.value as number;

    if (this.data.type == "Max passive users")
      customerPut.maxPassiveUsers = this.valueForm.value as number;

    if (this.data.type == "Max webinar users")
      customerPut.maxWebinarUsers = this.valueForm.value as number;

    if (this.data.type == "Max conference users")
      customerPut.maxConferenceUsers = this.valueForm.value as number;

    if (this.data.type == "Max webinar registered users")
      customerPut.maxWebinarRegisteredUsers = this.valueForm.value as number;

    if (this.data.type == "Max conference registered users")
      customerPut.maxConferenceRegisteredUsers = this.valueForm.value as number;

    if (this.data.type == "Storage size")
      customerPut.maxCourseSize = this.valueForm.value as number;

    if (this.data.type == "Max recording time")
      customerPut.maxRecordingTimeHours = this.valueForm.value as number;

    if (this.data.type == "Customer code")
      customerPut.customerCode = this.valueForm.value as string;

    if (this.data.type == "Font name")
      customerPut.fontName = this.valueForm.value as string;

    firstValueFrom(this.calendar.putCustomer(customerPut, this.customer.id))
      .then(() => {
        this.snackBar.open(`Customer ${customerPut.name} updated!`, '', { duration: 3000 });
        this.dialogRef.close(true);
      })
      .catch(error => {
        console.log(error);

        this.snackBar.open(`Error updating customer ${customerPut.name}`, '', { duration: 5000 });
        this.dialogRef.close(true);
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  setValues() {
    switch (this.data.type) {
      case "Demo":
        this.popupType = 'boolean';

        this.value = this.customer.isDemo === 1 ? "disabled" : "enabled";
        break;

      case "Demo duration":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(1), Validators.max(365)]);
        this.valueForm.setValue(this.customer.demoDuration);

        if (this.customer.isDemo == 0)
          this.valueForm.disable();

        break;

      case "Reset demo":
        this.popupType = 'boolean';

        this.value = "reset";
        break;

      case "Courses":
        this.popupType = 'boolean';

        this.value = this.customer.coursesEnabled === 1 ? "disabled" : "enabled";
        break;

      case "Authorized":
        this.popupType = 'boolean';

        this.value = this.customer.authorized === 1 ? "disabled" : "enabled";
        break;

      case "Masters":
        this.popupType = 'boolean';

        this.value = this.customer.mastersEnabled === 1 ? "disabled" : "enabled";
        break;

      case "Smily":
        this.popupType = 'boolean';

        this.value = this.customer.smilyEnabled === 1 ? "disabled" : "enabled";
        break;

      case "Educational plans":
        this.popupType = 'boolean';

        this.value = this.customer.eduPlanEnabled === 1 ? "disabled" : "enabled";
        break;

      case "Streaming":
        this.popupType = 'boolean';

        this.value = this.customer.streamingEnabled === 1 ? "disabled" : "enabled";
        break;

      case "Low resolution":
        this.popupType = 'boolean';

        this.value = this.customer.lowResEnabled === 1 ? "disabled" : "enabled";
        break;
      
      case "Write in overlay":
        this.popupType = 'textformandcheckbox';

        this.valueForm.setValidators([Validators.maxLength(24)]);
        this.valueForm.setValue(this.customer.logoText);

        if (Helper.isNullOrEmpty(this.customer.logoText))
          this.valueForm.disable();

        break;

      case "Customer code":
        this.popupType = 'textform';

        this.valueForm.setValidators([Validators.minLength(3),Validators.maxLength(50)]);
        this.valueForm.setValue(this.customer.iButtonUserName);
        break;

      case "Font size":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(8),Validators.max(24)]);
        this.valueForm.setValue(this.customer.fontSize);

        if (this.valueForm.value == undefined)
          this.valueForm.setValue(12);

        break;

      case "Max active users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(500)]);
        this.valueForm.setValue(this.customer.maxActiveUsers);

        if (this.valueForm.value == undefined)
          this.valueForm.setValue(50);

        break;

      case "Max passive users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(5000)]);
        this.valueForm.setValue(this.customer.maxPassiveUsers);
        break;

      case "Max webinar users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(500)]);
        this.valueForm.setValue(this.customer.maxWebinarUsers);
        break;

      case "Max conference users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(200)]);
        this.valueForm.setValue(this.customer.maxConferenceUsers);
        break;

      case "Max webinar registered users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(1000)]);
        this.valueForm.setValue(this.customer.maxWebinarRegisteredUsers);
        break;

      case "Max conference registered users":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(400)]);
        this.valueForm.setValue(this.customer.maxConferenceRegisteredUsers);
        break;
      
      case "Storage size":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(0),Validators.max(5000)]);
        this.valueForm.setValue(this.customer.maxCourseSize);
        break;

      case "Max recording time":
        this.popupType = 'numberform';

        this.valueForm.setValidators([Validators.min(1),Validators.max(10)]);
        this.valueForm.setValue(this.customer.maxRecordingTimeHours);
        break;
     
      case "Font name":
        this.popupType = 'fontselect';

        this.valueForm.setValue(this.customer.fontName);

        if (this.valueForm.value == undefined)
          this.valueForm.setValue("Sans");

        break;

      case "Billing period":
        this.popupType = 'boolean';

        this.value = this.customer.invoicePerMonth === 1 ? "quarterly" : "monthly";
        break;

      case "Billing":
        this.popupType = 'boolean';

        this.value = this.customer.invoicePerMonth === 0 ? "enabled" : "disabled";
        break;
        
      default:
        break;
    }
  }

  checkEnable($event: any) {
     if ($event.checked) {
      this.valueForm.setValue(undefined);
      this.valueForm.disable();

      return;
    }
    
    this.valueForm.enable();
  }
  
  disableBtn() {
    if (this.popupType === 'textformandcheckbox')
      return (!this.valueForm.valid && this.valueForm.enabled)
          || (this.valueForm.disabled && this.valueCheckbox);

    if (this.popupType === 'numberform' || this.popupType === 'textform' || this.popupType === 'fontselect')
      return !this.valueForm.valid;
             
    return false;
  }
}
