import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { AzureStorageService } from './storage.service';
import { Helper } from 'src/app/helpers/helper';
import { User } from 'src/app/models/user';
import { NavBarService } from './nav-bar.service';
import { SideNavService } from './sidenav.service';
import { DarkThemeService } from './dark-theme.service';
import { UserDeletionDTO } from 'src/app/models/dto/userDeletionDTO';
import { WarningService } from './warning.service';
import { DeviceCloudSettings } from '../models/deviceCloudSettings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConferenceService } from './conference.service';
import { EduPlanService } from './eduplan.service';
import { GenericPopupComponent, GenericPopupData } from '../popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChangeUserInfoComponent } from '../pages/user-list/change-user-info/change-user-info.component';
import { SelectionCancel, SelectionService } from './selection.service';
import { SocialUser } from '@abacritt/angularx-social-login';
import { EnvironmentHelper } from '../models/environmentVariables';
import { ContentHelper } from '../helpers/contentHelper';
import { CourseService } from './course.service';

const AUTH_CHECK_INTERVAL: number = 30000; //ms
const MAX_USER_INACTIVITY_SECONDS: number = 1800;

const DT_CUSTOM_LINKS_CLASS: string = 'dt-links';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private _authUrl: string = `${environment.apiUrl}/api/authenticate`;
    private _user: User;
    private _airtimeInterval: any;
    private _token: string;
    private _requestedPath: RequestUrl;

    private _fromApp: boolean = false;
    public get fromApp(): boolean { return this._fromApp; }

    private _mainPage: string = 'showcase';
    public get mainPage(): string { return this._mainPage; }

    private _lostConnection: boolean = false;
    public get lostConnection(): boolean { return this._lostConnection; }
    public set lostConnection(value: boolean) {
        this._lostConnection = value;
    }

    private userInactivityTimer: any;
    private userInactivitySeconds: number = 0;
    private userInactivityPopup: boolean = false;

    constructor(private router: Router,
                private http: HttpClient,
                private navBar: NavBarService,
                private azureService: AzureStorageService,
                private sideNav: SideNavService,
                private warningService: WarningService,
                private themeService: DarkThemeService,
                private snackBar: MatSnackBar,
                private conferenceService: ConferenceService,
                private eduPlanService: EduPlanService,
                private courseService: CourseService,
                private dialog: MatDialog,
                private translate: TranslateService,
                private selectionService: SelectionService) {
    }

    public async login(username: string, password: string, customerId?: number): Promise<User> {
        const user = await firstValueFrom(this.http.post<User>(this._authUrl, { "username": username, "password": password, "customerId": customerId, "noTimezone": true }));

        await this.setCurrentUser(user, password);

        return user;
    }

    public async loginSocial(userSocial: SocialUser): Promise<User> {
        const user = await firstValueFrom(this.http.post<User>(`${this._authUrl}/social`, userSocial));

        await this.setCurrentUser(user);

        return user;
    }

    public async loginIButton(rawToken: string): Promise<User> {
        this._token = rawToken;

        console.log("Coded token", this._token);
        let decoded = atob(decodeURIComponent(this._token));
        console.log("Decoded token", decoded);

        const user: User = JSON.parse(decoded);

        await this.setCurrentUser(user);

        return user;
    }

    public async loginAuthToken(authToken: string): Promise<User> {
        const user = await firstValueFrom(this.http.get<User>(`${this._authUrl}/user`, { headers:  Helper.getAuthHeader(authToken) }));

        await this.setCurrentUser(user);

        return user;
    }

    public async loginStation(username: string, password: string, config: DeviceCloudSettings): Promise<User> {
        const user = await firstValueFrom(this.http
            .post<User>(`${this._authUrl}/station`, {
                    username: username,
                    password: password,
                    authToken: null,
                    roomId: config.roomId,
                    stationId: config.stationId,
                    macAddress: config.macAddress,
                    customerUserName: config.customerCode,
                    noTimezone: true
                }
            ));

        await this.setCurrentUser(user, password);

        return user;
    }

    public async confirmSubscriptionLevelUser(levelId: number, token: string) {
        const user = await firstValueFrom(this.http.post<User>(`${this._authUrl}/subscriptionLevel/${levelId}/confirm/${token}`, null));

        await this.setCurrentUser(user);

        return user;
    }

    async confirmUser(token: string, password?: string) {
        const user = await firstValueFrom(this.http.post<User>(`${this._authUrl}/user/confirm/${token}`, { password: password, timezone: Helper.getTimezone(), noTimezone: true }));

        await this.setCurrentUser(user);

        return user;
    }

    public logout(redirect: boolean = true): void {
        
        if (this._airtimeInterval) {
            clearInterval(this._airtimeInterval);
            this._airtimeInterval = undefined;
        }

        this._mainPage = 'showcase';
        this.navBar.hide('sidenav');
        this.sideNav.unsetCurrentUser();
        this.themeService.onLogOut();
        this.selectionService.cancelSelection(SelectionCancel.logout);
        this.azureService.clearCurrentUploads(true);
        this.warningService.warnings = [];
        this.stopInactivityCheck();

        firstValueFrom(this.http.get(`${this._authUrl}/logout`, { headers: Helper.getAuthHeader() }))
            .then(() => {

                sessionStorage.removeItem('token');
                this._user = null;

                console.log("Logged out");

                if (redirect)
                    this.router.navigateByUrl('/login');

                })
                .catch((err) => {

                    sessionStorage.removeItem('token');
                    this._user = null;

                    if (err.status != 404)
                        console.log("Logged out");

                    if (redirect)
                        this.router.navigateByUrl('/login');

                });
    }

    public isAuthenticated(): boolean {
        return (this._user ? true : false);
    }
    
    public isAdmin(): boolean {
        return this.isAuthenticated() && this._user.isAdmin;
    }

    public isCustomerAdmin(): boolean {
        return this.isAuthenticated() && this._user.isCustomerAdmin;
    }

    public isTutor(): boolean {
        return this.isAuthenticated() && this._user.isTutor;
    }

    public isTeacher(): boolean {
        return this.isAuthenticated() && this._user.isTeacher;
    }

    public isSpeaker(): boolean {
        return this.isAuthenticated() && this._user.isSpeaker;
    }

    public isStudent(): boolean {
        return this.isAuthenticated() && this._user.isStudent;
    }

    public isStripeAble(): boolean {
        return this.isAuthenticated() && this._user.ownStripeAccount;
    }

    public updateCurrentUser(user: User) {
        if (!this.isAuthenticated())
            return;

        this._user.name = user.name;
        this._user.surname = user.surname;
        this._user.username = user.username;
        this._user.email = user.email;
        this._user.timezone = user.timezone;
        this._user.userSummary = user.userSummary;
        this._user.profilePictureUrl = user.profilePictureUrl;
        this._user.isAdmin = user.isAdmin;
        this._user.isCustomerAdmin = user.isCustomerAdmin;
        this._user.isTutor = user.isTutor;
        this._user.isTeacher = user.isTeacher;
        this._user.isSpeaker = user.isSpeaker;
        this._user.isStudent = user.isStudent;
        this._user.isAuthor = user.isAuthor;
    }

    private async setCurrentUser(user: User, password?: string): Promise<void> {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(user);

        sessionStorage.setItem('token', user.token);

        this._user = new User(user);
        this._mainPage = 'showcase';

        sessionStorage.setItem('forrelogin', JSON.stringify({
            UserName : user.username,
            Password : password
        }));

        if (this._airtimeInterval) {
            clearInterval(this._airtimeInterval);
            this._airtimeInterval = undefined;
        }

        this.authCheck().then(() => this._airtimeInterval = setInterval(() => this.authCheck(), AUTH_CHECK_INTERVAL));

        if (user.isStudent && user.eduPlanEnabled) {
            await firstValueFrom(this.eduPlanService.getLatestEduPlansOfUser())
                .then(res => {
                    if (res.length > 0)
                        this._mainPage = 'eduplans';
                });
        }

        let classes = [];

        if (this.isDT())
            classes.push(DT_CUSTOM_LINKS_CLASS);

        this.azureService.setToken(user.azureToken);
        this.azureService.updateCustomerStorage(user.idCustomer);
        this.themeService.onLogin(classes);
        this.sideNav.setCurrentUser(user);
        this.navBar.show('topbar');
        this.navBar.show('bottombar');
        this.warningService.updateWarnings();
        this.startInactivityCheck();

        if (user.firstPassword === 1)
            this.dialog.open(ChangeUserInfoComponent,
                {
                    width: '600px',
                    hasBackdrop: true,
                    disableClose: true
                }
            );
    }

    private async authCheck(): Promise<boolean> {

        try {

            await firstValueFrom(this.http.get(`${this._authUrl}/authCheck`, { headers: Helper.getAuthHeader() }));

            console.log("AuthCheck request sent");

        } catch (e) {

            if (this.lostConnection)
                return false;

            if (e.status === 403)
                this.snackBar.open("You have been disconnected due to inactivity or because you have logged in from another location.\n If it wasn't you, we recommend that you change your password", 'Dismiss', {
                    duration: 30000,
                    verticalPosition: 'top'
                });

            this.logout();

            return false;
        }

        return true;
    }

    public getCurrentUser(): User {
        return this._user;
    }

    public toggleGoogleAccess(enable: boolean): void {
        if (this._user != null)
            this._user.googleEnabled = enable;
    }

    public getDeletionRequest(tokenRequest:String): Observable<UserDeletionDTO> {
        return this.http.get<UserDeletionDTO>(`${this._authUrl}/deletion/${tokenRequest}`, { headers: Helper.getAuthHeader() });
    }

    public startInactivityCheck() {
        if (!this.isAuthenticated())
            return;

        this.stopInactivityCheck();

        console.log("Activity check start");

        this.userInactivityTimer = setInterval(async () => {
            this.userInactivitySeconds++;

            if (this.userInactivitySeconds >= MAX_USER_INACTIVITY_SECONDS && !this.userInactivityPopup) {
                this.userInactivityPopup = true;

                const dialogRef = this.dialog.open(GenericPopupComponent,
                {
                    width: '400px',
                    data: <GenericPopupData>{
                        title: await firstValueFrom(this.translate.get('Warning')),
                        body: await firstValueFrom(this.translate.get('Are you still there?')),
                        hideCancelBtn: true
                    }
                });

                let dialogTimeout = setTimeout(() => {
                    dialogRef.close(false);
                }, 60000);

                dialogRef.afterClosed()
                    .subscribe((res: any) => {
                        clearTimeout(dialogTimeout);
                        this.userInactivityPopup = false;

                        res == true ?
                        this.resetInactivityCheck() :
                        this.logout();
                    });
            }
        }, 1000);
    }

    public stopInactivityCheck() {
        if (!this.userInactivityTimer)
            return;

        clearInterval(this.userInactivityTimer);
        this.userInactivityTimer = null;
        this.userInactivityPopup = false;
        this.resetInactivityCheck();

        console.log("Activity check stop");
    }

    public resetInactivityCheck() {
        this.userInactivitySeconds = 0;
    }

    // RIABILITARE PER SOCIAL SHARE
    public setRequestedPath(path: string, params?: any): void {
        if (Helper.isNullOrEmpty(path)) return;

        let urlParams = new URLSearchParams(window.location.search);
        this._fromApp = urlParams.get('isFromApp') === 'true';
        
        let splittedPath = path.split('/');
        if (splittedPath.length < 2)
            return;

        let page = splittedPath[1];
        if (page !== 'course' &&
            page !== 'master' &&
            page !== 'package' &&
            page !== 'webinar-showcase' &&
            page !== 'conference' &&
            page !== 'webinar' &&
            page !== 'course-content')
            return;
        // && splittedPath[1] !== 'course-content') return;

        // Rimozione query parameters
        path = path.split('?')[0];

        this._requestedPath = new RequestUrl(path, params ?? { autoredirect: true });
    }

    public async navigateToRequestedPath(): Promise<void> {
        let route: string = this.mainPage;
        let navigationExtras: NavigationExtras = { };

        if (this._requestedPath?.path === '/conference' || this._requestedPath?.path === '/webinar') {

            await firstValueFrom(this.conferenceService.joinConference(this._requestedPath.params))
                .then(res => {
                    this._requestedPath.path += `/${res.id}`;
                    this._requestedPath.params = null;
                })
                .catch(err => {
                    console.error(err);
                    this._requestedPath = null;
                });

        }

        if (this._requestedPath?.path.includes('/course-content')) {

            let id = Number(this._requestedPath.path.split('/').pop());

            try {

                let content = await firstValueFrom(this.courseService.getContentById(id));

                let course = await firstValueFrom(this.courseService.getCourseContent(content.idCourse, this.isAuthenticated()));

                let ch = new ContentHelper(
                    this.router,
                    this.dialog,
                    this.courseService,
                    this.getCurrentUser(),
                    content,
                    course.courseContent,
                    course.idAuthor,
                    course.mode
                );

                ch.goTo();

            } catch (e) {
                console.error(e);
            }

            this._requestedPath = null;

            return;

        }

        if (this._requestedPath != null) {
            route = this._requestedPath.path;
            navigationExtras = { queryParams: this._requestedPath.params };
        }

        this.router.navigate([route], navigationExtras);
        this._requestedPath = null;
    }

    public requestPathHasValue() {
        return this._requestedPath != null;
    }

    public isGD() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('gammadonna') || EnvironmentHelper.isGD();
    }

    public isDT() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('dental trey') || EnvironmentHelper.isDT();
    }

    public isVR() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('saratoga hitech') || EnvironmentHelper.isVR();
    }

    public isSL() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('sunlight_academy') || EnvironmentHelper.isSL();
    }

    public isSC360() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('saratoga hitech') || EnvironmentHelper.isSC360();
    }

    public isFitxp() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('mylife360') || EnvironmentHelper.isFitxp();
    }

    public isHRO() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('hr&o') || EnvironmentHelper.isHRO();
    }

    public isHR() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('track & trace') || EnvironmentHelper.isHF();
    }

    public isAA() {
        return this.getCurrentUser()?.customerName.toLowerCase().includes('aixp academy') || EnvironmentHelper.isAA();
    }

}

class RequestUrl {
    path: string;
    params: any;

    constructor(path: string, params: any) {
        this.path = path;
        this.params = params;
    }
}
