import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { Connection, ConnectionEvent, Device, OpenVidu, Publisher, Session, SessionDisconnectedEvent, SignalEvent, StreamEvent, StreamManager } from 'openvidu-browser';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { SignalAskTempPresenter } from 'src/app/models/conference-session/signalAskTempPresenter';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { SignalPriorityUpdate } from 'src/app/models/conference-session/signalPriorityUpdate';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { SignalTempPresenter } from 'src/app/models/conference-session/signalTempPresenter';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { CONFERENCE_DURATION_MINUTES, CONFERENCE_START_CHECK_INTERVAL, DEVICES_CONNECTED_CHECK_INTERVAL, MAX_OPERATION_LOCK_TIME, MIN_OPERATION_LOCK_TIME, PRESENTER_RESOLUTION, PRIORITY_ADD_VALUE, PRIORITY_CHECK_TIMEOUT, PRIORITY_MAX_VALUE, PRIORITY_MIN_VALUE, PRIORITY_QUEUE_LENGTH, PRIORITY_REMOVE_VALUE, STREAMING_QUALITY_CHECK_INTERVAL } from 'src/app/models/conference-session/conferenceConstants';
import { SourceSelection } from '../conference-session/source-selection/source-selection.component';
import { MatDrawer } from '@angular/material/sidenav';
import { DirectionalChat } from 'src/app/models/conference-session/directionalChat';
import { ForwardedMessage } from 'src/app/models/conference-session/forwardedMessage';
import { Platform } from '@angular/cdk/platform';
import { NetworkInformation } from 'src/app/helpers/networkInformation';
import { JoinSession } from 'src/app/models/joinSession';

const JOIN_CONFERENCE_TIMEOUT: number = 1000; //ms

@Component({
  selector: 'app-webinars-session',
  templateUrl: './webinar-session.component.html',
  styleUrls: ['./webinar-session.component.scss']
})
export class WebinarSessionComponent implements OnInit {

  id: number;
	currentUser: User;
	conference: ConferenceDTO;
	conferenceStartDate: Date;
	conferenceEndDate: Date;
  httpLink: string;

	OV: OpenVidu;
	session: Session;
	token: string;
	videoDevices: Device[] = [];
	audioDevices: Device[] = [];
	localParticipant: ClientStream;
  presenters: ClientStream[] = [];
  participants: ClientStream[] = [];
	localParticipantVideoDeviceId: string | false = null;
	localParticipantAudioDeviceId: string | false = null;
  localParticipantMirror: boolean = false;
	conferenceInterval: any = null;
	checkConferenceStopDateInterval: any = null;
	priorityCheckTimeout: any = null;

  streamingQualityInterval: any = undefined;
  streamingQualityValues: string[] = [];
  validStreamingQualities: string[] = ['3g', '4g'];

	OVShare: OpenVidu;
	sessionShare: Session;
	localParticipantShare: ClientStream;
	publisherShare: ClientStream;
	localParticipantShareVideoDeviceId: string = null;
	priorityPosition: number = 0;

	visualType: string;
	isFullScreen: boolean = true;
	hasScreenShareCapabilities: boolean = false;
  isRecording: boolean = false;
  togglingRecording: boolean = false;

	initialSelection: boolean = true;
	lockJoin: boolean = true;
	switchPublisherLock: boolean = false;
	publishingInfo: string = null;
	publisherName: string = null;

  newMPresentersMessages: number = 0;
  newMParticipantsMessages: number = 0;
  newPPMesagges: number = 0;
	newHandNotifications: number = 0;

	toolbarOpened: boolean = false;
  mpresentersChatOpened: boolean = false;
  mparticipantsChatOpened: boolean = false;
  ppChatOpened: boolean = false;

  participantsListOpened: boolean = false;

  presentersChats: DirectionalChat[] = [];
  forwardedMessage: ForwardedMessage = null;

	private _isSpeaking: boolean = false;
	private _isHandRaised: boolean = false;
  private _highlightPresenters: ClientStream[] = [];

	get isHandRaised(): boolean { return this._isHandRaised; }
	set isHandRaised(value: boolean) {

		if (value && this._isHandRaised !== value)
			this.updatePriority("add");

		this._isHandRaised = value;

	}

  get highlightPresenters(): ClientStream[] { return this._highlightPresenters; }
  set highlightPresenters(value: ClientStream[]) {

    if (value)
      value.sort((a, b) => a.position - b.position);

    this._highlightPresenters = value;

  }

  @ViewChild('directionalChat') public directionalChat: MatDrawer;

  constructor(private routeActive: ActivatedRoute,
              private router: Router,
              private auth: AuthService,
              private conferenceService: ConferenceService,
              private videoSessionService: VideoSessionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translate: TranslateService,
              private navBar: NavBarService,
              private calendar: CalendarService,
              private platform: Platform) { }

  async ngOnInit(): Promise<void> {
    this.navBar.hide('all');
    this.auth.stopInactivityCheck();

    this.id = Number(this.routeActive.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();

    this.OV = new OpenVidu();

    try {

      this.conference = await firstValueFrom(this.conferenceService.getConference(this.id));

      this.conferenceStartDate = new Date(this.conference.lessonSession.startDate);
      this.conferenceEndDate = addMinutes(this.conferenceStartDate, CONFERENCE_DURATION_MINUTES);

      this.httpLink = `${window.location.origin}/webinar-showcase/${this.conference.id}`;
      
      if (this.isPublisher() || this.isModerator())
        await this.videoSessionService.createSession(this.conference.idLesson)
          .catch(() => this.leave());

      //if (this.isPresenter())
      //	this.auth.stopInactivityCheck();

      this.token = (await this.getToken())?.token;
      
      this.lockJoin = false;

      this.checkConferenceIsFinished();
      
    } catch (e) {
      console.error(e);

      this.snackBar.open('conference not found', 'Dismiss', {
        duration: 5000,
        verticalPosition: 'top'
      });

      this.leave();
    }

    if (this.isPresenter() || this.isModerator()) {

      let userMedia: MediaStream = null;
      let tries: number = 0;
  
      while (!userMedia) {
  
        // Senza questo controllo il loop continua anche con pagina distrutta
        if (!this.OV)
          return;
  
        console.log(`read devices, try ${++tries}`);
  
        try {
  
          userMedia = await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

          userMedia.getTracks().forEach(t => t.stop());
  
        } catch (e) {
          console.error(e);
  
          this.snackBar.open('please connect a device and allow camera and microphone access to continue', 'Dismiss', {
            duration: DEVICES_CONNECTED_CHECK_INTERVAL,
            verticalPosition: 'top'
          });
  
          await Helper.sleep(DEVICES_CONNECTED_CHECK_INTERVAL);
        }
  
      }
  
      let devices = await this.OV.getDevices();
  
      this.videoDevices = devices.filter(d => d.kind === "videoinput");
      this.audioDevices = devices.filter(d => d.kind === "audioinput");

    }

    window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${(window.innerHeight - 40) * 0.01}px`));
  }

  async ngOnDestroy(): Promise<void> {
    if (this.isFullScreen)
      this.isFullScreen = false;

    if (this.conferenceInterval != null) {
      clearInterval(this.conferenceInterval);
      this.conferenceInterval = null;
    }

    if (this.checkConferenceStopDateInterval != null) {
      clearInterval(this.checkConferenceStopDateInterval);
      this.checkConferenceStopDateInterval = null;
    }

    if (this.priorityCheckTimeout != null) {
      clearTimeout(this.priorityCheckTimeout);
      this.priorityCheckTimeout = null;
    }

    await this.disposePublisher(this.localParticipantShare?.manager as Publisher);
		this.localParticipantShare = null;

		await this.disposePublisher(this.localParticipant?.manager as Publisher);
		this.localParticipant = null;

    this.sessionShare?.disconnect();
    this.session?.disconnect();

    this.presenters = [];
    this.highlightPresenters = [];
    this.participants = [];

    this.localParticipantVideoDeviceId = null;
    this.localParticipantAudioDeviceId = null;
    this.localParticipantShareVideoDeviceId = null;
    this.localParticipantMirror = false;

    delete this.localParticipantShare;
    delete this.localParticipant;
    delete this.publisherShare;
    delete this.sessionShare;
    delete this.session;
    delete this.OVShare;
    delete this.OV;

    this.stopStreamingQualityCheck();
    this.auth.startInactivityCheck();
    this.auth.lostConnection = false;

    this.navBar.show('topbar');
    this.navBar.show('bottombar');
    this.dialog.closeAll();
  }

  async leave(fromUser: boolean = false, route: any[] = this.conference ? ['/webinar-showcase', this.conference.id] : ['/showcase']) {
    if (fromUser) {

      const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Leave conference')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to leave conference name?', { value: this.conference?.name ?? '' }))
        }
      });

      if (!(await firstValueFrom(dialogRef.afterClosed())))
        return;

    }

    //if (this.isPublishing())
    //	this.setPublisherReplacement();

    await this.leaveConferenceStream();
    this.router.navigate(route);
  }

  isPublisher(userId: number = this.currentUser.id) {
    return this.conference?.lessonSession.teacherId === userId;
  }

  isModerator(userId: number = this.currentUser.id) {
    return this.conference?.conferencePresenter.findIndex(p => p.idPresenter === userId && p.role === ConferencePresenterRole.Moderator) !== -1;
  }

  isPresenter(userId: number = this.currentUser.id) {
    return this.conference?.conferencePresenter.findIndex(p => p.idPresenter === userId && p.role === ConferencePresenterRole.Presenter) !== -1;
  }

  isPublishing(userId: number = this.currentUser.id) {
    return this.highlightPresenters.findIndex(p => p.userId === userId) !== -1;
  }

  isSharing(userId: number = this.currentUser.id) {
    return this.publisherShare?.userId === userId;
  }

  isHighlightEnabled(userId: number = this.currentUser.id) {
    return this.highlightPresenters.findIndex(hp => hp.userId === userId) !== -1;
  }

  isHighlight() {
    return this.localParticipant?.highlight ?? false;
  }

  async startShare(deviceId: string) {
    if (!this.OVShare)
      this.OVShare = new OpenVidu();
    
    let prevStreamId: string = this.localParticipantShare
                             ? this.localParticipantShare.manager.stream.streamId
                             : null;

    await this.stopShare();

    this.sessionShare = this.OVShare.initSession();

    let res = await this.getToken();

    let clientData = new ClientData(this.currentUser.id,
                                    this.getUsername(),
                                    this.currentUser.profilePictureUrl,
                                    this.getRole(),
                                    'share',
                                    deviceId === 'screen' ? 'screen' : 'camera');

    let videoSource: string | boolean | MediaStreamTrack = deviceId;
    let audioSource: string | boolean | MediaStreamTrack = false;

    try {

      if (deviceId === 'screen') {

        let screen = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
  
        videoSource = screen.getVideoTracks()[0];

        if (screen.getAudioTracks()?.length > 0)
          audioSource = screen.getAudioTracks()[0];
      }

      let localParticipantShare = this.OVShare.initPublisher(undefined, {
        audioSource: audioSource, // The source of audio. If undefined default microphone
        videoSource: videoSource, // The source of video. If undefined default webcam
        publishAudio: audioSource != null && audioSource != false, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: true, // Whether you want to start publishing with your video enabled or not
        resolution: PRESENTER_RESOLUTION, // The resolution of your video
        frameRate: 25, // The frame rate of your video
        mirror: false
      });

      this.localParticipantShare = new ClientStream(localParticipantShare, clientData);
      this.localParticipantShareVideoDeviceId = deviceId;

      if (deviceId === 'screen') {

        localParticipantShare.once('accessDenied', async () => {
          console.log('Screen share access denied');
          
          await this.stopShare();
        });

        localParticipantShare.once('accessAllowed', async () => {
          console.log('Screen share access allowed');
  
          localParticipantShare.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', async () => {
            console.log('Screen share stop');
  
            await this.stopShare();
          });

          try {

            await this.sessionShare.connect(res.token, clientData);

            await this.sessionShare.publish(localParticipantShare);
    
            this.joinConferenceStream(this.sessionShare.connection.connectionId,
                                      this.sessionShare.connection?.stream?.streamId,
                                      2,
                                      prevStreamId);

          } catch (e) {
            console.error(e);

            await this.stopShare();
          }
  
        });

      } else {

        await this.sessionShare.connect(res.token, clientData);
  
        await this.sessionShare.publish(localParticipantShare);
  
        this.joinConferenceStream(this.sessionShare.connection.connectionId,
                                  this.sessionShare.connection?.stream?.streamId,
                                  2,
                                  prevStreamId);
  
      }

    } catch (e) {
      console.error(e);

      await this.stopShare();
    }

  }

  async stopShare() {
    if (!this.isSharing())
      return;

    if (this.localParticipantShare?.manager?.stream?.isLocalStreamPublished ?? false) {
      let connectionId: string = this.localParticipantShare.manager.stream.connection.connectionId;
      let streamId: string = this.localParticipantShare.manager.stream.streamId;

      //await this.sessionShare?.unpublish(<Publisher>this.localParticipantShare.manager);

      await this.leaveConferenceStream(connectionId);
    }

    this.sessionShare?.disconnect();
    this.sessionShare = null;

    await this.disposePublisher(this.localParticipantShare?.manager as Publisher);

    this.localParticipantShare = null;
    this.localParticipantShareVideoDeviceId = null;

    await this.setPublisherShare(null);
  }

  getUsername() {
    return `${this.currentUser.name} ${this.currentUser.surname}`;
  }

  async joinConference(streamConfig: SourceSelection) {

    this.lockJoin = true;

    if (this.conference.lessonSession.state !== 1 && this.isModerator()) {

      try {

        await firstValueFrom(this.calendar.activateLesson(this.conference.idLesson));
        await this.ngOnInit();
        
      } catch (e) {
        console.error(e);

        this.snackBar.open(e.error, 'Dismiss', {
          duration: 5000,
          verticalPosition: 'top'
        });

        this.lockJoin = false;

        return;
      }

      this.snackBar.open('Conference started', 'Dismiss', {
        duration: 3000,
        verticalPosition: 'top'
      });

    }

    this.InitOpenviduSession();

    await Helper.sleep(JOIN_CONFERENCE_TIMEOUT);

    let clientData = new ClientData(this.currentUser.id,
                                    this.getUsername(),
                                    this.currentUser.profilePictureUrl,
                                    this.getRole(),
                                    'subject',
                                    'camera');

    let localParticipant: Publisher | undefined = undefined;

    if (this.isPresenter() || this.isModerator()) {

      localParticipant = this.OV.initPublisher(undefined, {
        audioSource: streamConfig.audioDeviceId, // The source of audio. If undefined default microphone
        videoSource: streamConfig.videoDeviceId, // The source of video. If undefined default webcam
        publishAudio: streamConfig.audioEnabled, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: streamConfig.videoEnabled, // Whether you want to start publishing with your video enabled or not
        resolution: PRESENTER_RESOLUTION, // The resolution of your video
        frameRate: 25, // The frame rate of your video
        mirror: streamConfig.mirror 
      });

      this.localParticipantVideoDeviceId = streamConfig.videoDeviceId;
      this.localParticipantAudioDeviceId = streamConfig.audioDeviceId;
      this.localParticipantMirror = streamConfig.mirror;

      this.hasScreenShareCapabilities = this.OV.checkScreenSharingCapabilities();

      (<StreamManager>localParticipant).on('publisherStopSpeaking', () => this._isSpeaking = false);
      (<StreamManager>localParticipant).on('publisherStartSpeaking', () => {

        this._isSpeaking = true;
        this.updatePriority("add");

      });
    }

    this.localParticipant = new ClientStream(localParticipant, clientData);

    this.session.connect(this.token, clientData)
      .then(async () => {

        this.initialSelection = false;
        this.lockJoin = false;

        if (localParticipant)
          await this.session.publish(localParticipant);

        this.joinConferenceStream(this.session.connection.connectionId,
                                  this.session.connection?.stream?.streamId,
                                  1);

        clearInterval(this.conferenceInterval);
        this.conferenceInterval = null;

        clearInterval(this.checkConferenceStopDateInterval);
        this.checkConferenceStopDateInterval = null;

        this.startStreamingQualityCheck();

      }).catch(error => {
        console.log('There was an error connecting to the session', error.code, error.message);
        this.ngOnInit();
      });

  }

  async changeSource(deviceId: string | false, type: 'video' | 'audio') {

    try {

			let audioDevice = type === 'audio' ? deviceId : this.localParticipantAudioDeviceId;
			let videoDevice = type === 'video' ? deviceId : this.localParticipantVideoDeviceId;

			let hasAudioActive = this.localParticipant.manager.stream.audioActive;
			let hasVideoActive = this.localParticipant.manager.stream.videoActive;

      let prevStreamId = (this.localParticipant.manager as Publisher).stream.streamId;

      await this.session.unpublish(this.localParticipant.manager as Publisher);

			await this.disposePublisher(this.localParticipant.manager as Publisher);

			this.localParticipant.manager = this.OV.initPublisher(undefined, {
				audioSource: audioDevice, // The source of audio. If undefined default microphone
				videoSource: videoDevice, // The source of video. If undefined default webcam
				publishAudio: hasAudioActive, // Whether you want to start publishing with your audio unmuted or not
				publishVideo: hasVideoActive, // Whether you want to start publishing with your video enabled or not
				resolution: PRESENTER_RESOLUTION, // The resolution of your video
				frameRate: 25, // The frame rate of your video
				mirror: this.localParticipantMirror 
			});

			this.localParticipantAudioDeviceId = audioDevice;
			this.localParticipantVideoDeviceId = videoDevice;

      await this.session.publish(this.localParticipant.manager as Publisher);

      await this.joinConferenceStream(this.session.connection.connectionId,
                                      this.session.connection.stream.streamId,
                                      1,
                                      prevStreamId);

		} catch (err) {
			console.error(err);

			this.snackBar.open(`error changing ${type} source`, 'Dismiss', {
				duration: 5000,
				verticalPosition: 'top'
			});
		}
    
  }

  askUserToBePresenter(userId: number, name: string) {
    let data: SignalAskTempPresenter = {
      destUserId: userId,
      destUserName: name,
      askingUserId: this.currentUser.id,
      askingUserName: this.getUsername(),
      enable: true,
			position: this.getHightlightPosition()
    };

    this.sendData(JSON.stringify(data), [], SignalType.askTempPresenter);
  }

  setUserAsPresenter(userId: number, enable: boolean, connection?: Connection) {
    let data: SignalTempPresenter = {
      userId: userId,
      enable: enable,
			position: 0,
      activate: !this.publisherShare,
      type: 'all'
    };

    data.position = this.isModerator()
                  ? this.getHightlightPosition()
                  : this.localParticipant?.position ?? 0;

    this.sendData(JSON.stringify(data), connection ? [connection] : [], SignalType.tempPresenter);
  }

  toggleHand(userId: number, raise: boolean) {
    let data: SignalRaiseHand = {
      userId: userId,
      raise: raise
    };

    this.sendData(JSON.stringify(data), [], SignalType.toggleHand);
  }

  getAllNotifications() {
    return this.newMPresentersMessages + this.newMParticipantsMessages + this.newPPMesagges + this.newHandNotifications;
  }

  getPresenterHeight() {
    let perc = 100;
    let gap = 0;

    if (this.presenters.length > 1) {
      perc /= this.presenters.length;
      gap = (5 * (this.presenters.length - 1)) / this.presenters.length;
    }

    return `calc(var(--vh, 1vh) * ${perc} - ${gap}px)`;
  }

  conferenceElapsedMinutes() {
    if (!this.conference)
      return 0;

    let minutes = differenceInMinutes(new Date(), this.conferenceStartDate);

    return minutes < 0
         ? 0
         : minutes;
  }

  conferenceRemainingMinutes() {
    if (!this.conference)
      return 0;

    let minutes = differenceInMinutes(this.conferenceEndDate, new Date());

    return minutes > 0
         ? minutes
         : 0;
  }

  getDevices(type: 'video' | 'audio', mode: 'subject' | 'share' | 'all') {
    let devices = type === 'video'
                ? this.videoDevices
                : type === 'audio'
                ? this.audioDevices
                : [];

    if (mode === 'subject')
      return devices.filter(d => d.deviceId !== this.localParticipantShareVideoDeviceId);

    if (mode === 'share')
      return devices.filter(d => d.deviceId !== this.localParticipantVideoDeviceId
                              && d.deviceId !== this.localParticipantAudioDeviceId);

    return devices;
  }

  isVideoOverride() {
    return !this.isPresenter()
        && !this.isModerator()
        && !this.isPublishing()
        && this.priorityPosition >= PRIORITY_QUEUE_LENGTH;
  }

  async close() {
    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Close conference')),
        body: await firstValueFrom(this.translate.get('Are you sure you want to close conference?', { name: this.conference.name }))
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.videoSessionService.closeLesson(this.conference.idLesson)
        .then(() => this.leave());
    });
  }

  async kickParticipant(connectionId: string) {
    if (!connectionId)
      return;

    let participant = this.presenters.find(p => p.manager?.stream?.connection?.connectionId === connectionId);

    if (!participant)
      participant = this.highlightPresenters.find(p => p.manager?.stream?.connection?.connectionId === connectionId);

    if (!participant)
      participant = this.participants.find(p => p.manager?.stream?.connection?.connectionId === connectionId);

    if (!participant)
      return;

    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Kick user')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to kick user?', { name: participant.name }))
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;
  
        this.videoSessionService.kickUser(connectionId)
          .catch(err => {

            console.error(err);
            this.snackBar.open(err.error.Message, undefined, { duration: 3000, verticalPosition: 'top' });

          });
      });
  }

  toggleChats(type: 'presenters' | 'participants' | 'moderators') {

    if (type === 'presenters') {

      this.mpresentersChatOpened = !this.mpresentersChatOpened;

      if (this.mpresentersChatOpened) {
        this.mparticipantsChatOpened = false;
        this.ppChatOpened = false;
      }

    }

    if (type === 'participants') {

      this.mparticipantsChatOpened = !this.mparticipantsChatOpened;

      if (this.mparticipantsChatOpened) {
        this.mpresentersChatOpened = false;
        this.ppChatOpened = false;
      }

    }

    if (type === 'moderators') {

      this.ppChatOpened = !this.ppChatOpened;

      if (this.ppChatOpened) {
        this.mpresentersChatOpened = false;
        this.mparticipantsChatOpened = false;
      }

    }

    if (this.mpresentersChatOpened || this.mparticipantsChatOpened || this.ppChatOpened)
      this.directionalChat.open();

    if (!this.mpresentersChatOpened && !this.mparticipantsChatOpened && !this.ppChatOpened)
      this.directionalChat.close();
  }

  closeChats(toggle: boolean) {
    if (!toggle) {
      this.mpresentersChatOpened = false;
      this.mparticipantsChatOpened = false;
      this.ppChatOpened = false;
    }
  }

  openParticipantList() {
    if (!this.participantsListOpened) {

      if (!this.isModerator())
        return;

      if (this.participants.length === 0) {
        this.snackBar.open('No users connected', undefined, { duration: 3000, verticalPosition: 'top' });
        return;
      }

    }

    this.participantsListOpened = !this.participantsListOpened;
  }

  getConnectedParticipantsIds() {
    return this.participants.map(p => p.userId);
  }

  truncate(value: string, nullValue: string, maxLength: number) {
    return Helper.getTruncateShowcase(value, nullValue, maxLength);
  }

  async toggleRecording(toggle: boolean) {

		const dialogRef = this.dialog.open(GenericPopupComponent,
		{
			width: '400px',
			data: <GenericPopupData>{
				title: await firstValueFrom(this.translate.get(toggle ? 'Start recording' : 'Stop recording')),
				body: await firstValueFrom(this.translate.get(toggle ? 'Are you sure to start the recording?' : 'Are you sure to stop the recording?', { minutes: this.currentUser.maxRecordingMinutes }))
			}
		});

		dialogRef.afterClosed()
			.subscribe(async res => {

				if (!res)
					return;

        this.togglingRecording = true;

				try {

					toggle ?
					await this.videoSessionService.startRecording(this.conference.idLesson) :
					await this.videoSessionService.stopRecording(this.conference.idLesson);

					this.isRecording = toggle;

          /*
					this.snackBar.open(
						await firstValueFrom(this.translate.get(toggle ? 'Recording started' : 'Recording stopped')),
						undefined,
						{ duration: 3000, verticalPosition: 'top' }
					);
          */

				} catch (err) {
					console.error(err);

					let msg = err.error.Message ?? await firstValueFrom(this.translate.get(toggle ? 'Cannot start recording' : 'Cannot stop recording'));

					this.snackBar.open(msg, undefined, { duration: 5000, verticalPosition: 'top' });
				}

        this.togglingRecording = false;

			});
	}

  lostConnection() {
    return this.auth.lostConnection;
  }

  private sendData(data: string, remoteConnections: Connection[], signalType: SignalType) {
    if (!remoteConnections || data == null)
      return;

    this.session.signal({ data: data, to: remoteConnections, type: signalType })
      .catch(err => console.error(err));
  }

  private async getToken() {
    try {

      return await firstValueFrom(this.conferenceService.generateTokenConference(this.id));

    } catch (e) {

      console.error(e);

      if (e.status === 507) {

				this.dialog.open(GenericPopupComponent,
				{
					width: '400px',
					data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Warning')),
            body: e.error,
            hideCancelBtn: true,
            btnAlign: 'center'
					}
				});

				return;

			}

      if (this.isModerator())
        return;

      let title = this.conference.lessonSession.state !== 1
                ? 'Conference not started'
                : 'Conference paused';

      let body = this.conference.lessonSession.state !== 1
               ? 'The organizer has not started the conference yet. Wait here until the conference begins.'
               : 'The organizer paused the conference. Wait here until the conference begins again.';

      const dialogRef = this.dialog.open(GenericPopupComponent, {
        width: '320px',
        height: '270px',
        autoFocus: false,
        hasBackdrop: true,
        disableClose: true,
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get(title)),
          body: await firstValueFrom(this.translate.get(body)),
          hideOkBtn: true,
          hideCancelBtn: true,
          showGoBtn: true,
          btnAlign: 'center',
          page: this.conference ? `webinar-showcase/${this.conference.id}` : 'showcase'
        }
      });

      this.checkOnlineStreaming(dialogRef);	

    }

    return null;
  }

  private checkOnlineStreaming(dialogRef: any) {
    this.conferenceInterval = setInterval(() => {

      this.conferenceService.getOnlinePresenters(this.conference.idLesson)
        .subscribe({
          next: async presenters => {

            if (this.conference.lessonSession.state === 1) {
              try {

                await firstValueFrom(this.conferenceService.generateTokenConference(this.id));

              } catch (err) {
                console.error(err);

                return;
              }
            }

            if (presenters.length > 0) {
              dialogRef.close();

              this.snackBar.open('Conference started', 'Dismiss', {
                duration: 3000,
                verticalPosition: 'top'
              });

              clearInterval(this.conferenceInterval);
              this.conferenceInterval = null;

              this.ngOnInit();
            }

          },
          error: err => console.error(err)
        });

    }, CONFERENCE_START_CHECK_INTERVAL);
  }

  private checkConferenceIsFinished() {
    if (this.checkConferenceStopDateInterval != null) {
      clearInterval(this.checkConferenceStopDateInterval);
      this.checkConferenceStopDateInterval = null;
    } 

    this.checkConferenceStopDateInterval = setInterval(() => {
    
      this.conferenceService.getConference(this.id)
      .subscribe({
        next: async conference => {
      
          if (conference.lessonSession.stopDate != null) {

            this.dialog.closeAll();
            clearInterval(this.conferenceInterval);
            this.conferenceInterval = null;

            const dialogRef = this.dialog.open(GenericPopupComponent, {
              width: '290px',
              height: '200px',
              autoFocus: false,
              hasBackdrop: true,
              disableClose: true,
              data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Conference finished')),
                body: await firstValueFrom(this.translate.get('The conference is over')),
                hideOkBtn: true,
                hideCancelBtn: true,
                showGoBtn: true,
                btnAlign: 'center',
                page: this.conference ? `webinar-showcase/${this.conference.id}` : 'showcase'
              }
            });

            clearInterval(this.checkConferenceStopDateInterval);
            this.checkConferenceStopDateInterval = null;
          }
        },
        error: err => console.error(err)
      });
    
    }, CONFERENCE_START_CHECK_INTERVAL);
  }

  private InitOpenviduSession() {

    this.session = this.OV.initSession();

    this.session.on('streamCreated', async (event: StreamEvent) => {
      console.warn('STREAM CREATED!');
      console.warn(event);

      let clientData = <ClientData>JSON.parse(event.stream.connection.data);

      if (clientData.userId === this.currentUser.id)
				return;

      if (clientData.mode === 'share') {

        if (this.publisherShare)
          this.publisherShare.manager = this.session.subscribe(event.stream, undefined);

        return;

      } else {

        let index = this.participants.findIndex(p => p.userId === clientData.userId);

        if (index !== -1) {
          this.participants[index].manager = this.session.subscribe(event.stream, undefined);
          return;
        }
  
        index = this.presenters.findIndex(p => p.userId === clientData.userId);
  
        if (index !== -1) {
          this.presenters[index].manager = this.session.subscribe(event.stream, undefined);
          return;
        }
  
        index = this.highlightPresenters.findIndex(p => p.userId === clientData.userId);
  
        if (index !== -1) {
          this.highlightPresenters[index].manager = this.session.subscribe(event.stream, undefined);
          return;
        }

      }
      
    });

    this.session.on('streamDestroyed', async (event: StreamEvent) => {
      console.warn('STREAM DESTROYED!');

      //let clientData = <ClientData>JSON.parse(event.stream.connection.data);

      if (this.publisherShare?.manager?.stream.streamId === event.stream.streamId) {
        this.publisherShare.manager = undefined;
        return;
      }

      let index = this.participants.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);

      if (index !== -1) {
        this.participants[index].manager = undefined;
        return;
      }

      index = this.presenters.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);

      if (index !== -1) {
        this.presenters[index].manager = undefined;
        return;
      }

      index = this.highlightPresenters.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);

      if (index !== -1) {
        this.highlightPresenters[index].manager = undefined;
        return;
      }

    });

    this.session.on('connectionCreated', async (event: ConnectionEvent) => {
      console.warn(event.connection.connectionId === this.session.connection.connectionId ?
                   'YOUR OWN CONNECTION CREATED!' :
                   'OTHER USER\'S CONNECTION CREATED!');

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.mode === 'share') {

        //Il connectionId di sessionShare risulta ancora a null in questo punto quindi identifico per userId
        let participant = clientData.userId === this.currentUser.id
                        ? this.localParticipantShare
                        : new ClientStream(undefined, clientData);

        await this.setPublisherShare(participant);

      } else {

        let participant = event.connection.connectionId === this.session.connection.connectionId
                        ? this.localParticipant
                        : new ClientStream(undefined, clientData);

        if (this.isModerator(clientData.userId) && this.highlightPresenters.length === 0 && !this.publisherShare) {

          this.setPublisher(participant);
  
        } else if ((this.isPresenter(clientData.userId) || this.isModerator(clientData.userId)) && this.presenters.findIndex(p => p.userId === clientData.userId) === -1) {
  
          this.presenters.push(participant);
  
        } else if (!this.isPresenter(clientData.userId) && !this.isModerator(clientData.userId) && this.participants.findIndex(p => p.userId === clientData.userId) === -1) {

          this.participants.push(participant);
  
        }

        if (clientData.userId !== this.currentUser.id) {

          if (this._isHandRaised)
            setTimeout(() => this.toggleHand(this.currentUser.id, this._isHandRaised), MIN_OPERATION_LOCK_TIME);
  
          if (clientData.mode === "subject" && this.localParticipant)
            this.updatePriority("remove");
  
          // Se sto trasmettendo allora comunico che sto presentando io
          if (this.isHighlight())
            setTimeout(() => this.setUserAsPresenter(this.currentUser.id, true, event.connection), MIN_OPERATION_LOCK_TIME);
  
        }

      }
      
    });

    this.session.on('connectionDestroyed', async (event: ConnectionEvent) => {
      console.warn(event.connection.connectionId === this.session.connection.connectionId ?
                   'YOUR OWN CONNECTION DESTROYED!' :
                   'OTHER USER\'S CONNECTION DESTROYED!');

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.mode === 'share') {

        await this.setPublisherShare(null);

      } else {

        let index = this.participants.findIndex(p => p.userId === clientData.userId);

        if (index !== -1)
          this.participants.splice(index, 1);

        index = this.presenters.findIndex(p => p.userId === clientData.userId);

        if (index !== -1)
          this.presenters.splice(index, 1);

        index = this.highlightPresenters.findIndex(p => p.userId === clientData.userId);

        if (index !== -1)
          this.publisherLeft(this.highlightPresenters.splice(index, 1)[0].name);

      }

      if (this.highlightPresenters.length === 0 && !this.publisherShare)
        this.setPublisherReplacement();
      
    });

    this.session.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
      console.warn('SESSION DISCONNECT!');

      if (event.reason !== "disconnect") {

        let msg = event.reason === "networkDisconnect"
                ? await firstValueFrom(this.translate.get('No connection. Go to an area with a better connection'))
                : `Disconnected from conference: ${event.reason}`;
        
        this.snackBar.open(msg, 'Dismiss', {
          duration: 10000,
          verticalPosition: 'top'
        });

        this.leave();
      
      }

    });

    this.session.on('recordingStarted', () => {
			console.warn('RECORDING STARTED');

			this.isRecording = true;

      this.snackBar.open(this.translate.instant('Recording started'), undefined, { duration: 6000, verticalPosition: 'top' });
		});

		this.session.on('recordingStopped', () => {
			console.warn('RECORDING STOPPED');

			this.isRecording = false;

      this.snackBar.open(this.translate.instant('Recording stopped'), undefined, { duration: 6000, verticalPosition: 'top' });
		});

    this.session.on("reconnecting", () => {
      console.warn('RECONNECTING!');

      this.auth.lostConnection = true;
    });

    this.session.on('reconnected', () => {
      console.warn('RECONNECTED!');

      this.auth.lostConnection = false;
    });

    this.session.on(SignalType.askTempPresenter, async (event: SignalEvent) => {

      let data: SignalAskTempPresenter = JSON.parse(event.data);

      if (data.enable) {

        if (this.switchPublisherLock)
          return;

        this.switchPublisherLock = true;
        setTimeout(() => this.switchPublisherLock = false, MAX_OPERATION_LOCK_TIME);

        if (data.destUserId === this.currentUser.id) {

          let pubInfo = this.publishingInfo;

          this.publishingInfo = await firstValueFrom(this.translate.get('x invited you to present the conference', { user: data.askingUserName }));

          const dialogRef = this.dialog.open(GenericPopupComponent,
          {
            width: '400px',
            data: <GenericPopupData>{
              title: await firstValueFrom(this.translate.get('Present request')),
              body: this.publishingInfo
            }
          });

          let waitTimeout = setTimeout(() => dialogRef.close(false), MAX_OPERATION_LOCK_TIME);
        
          if (!(await firstValueFrom(dialogRef.afterClosed()))) {
            clearTimeout(waitTimeout);

            data.enable = false;
            this.sendData(JSON.stringify(data), [], SignalType.askTempPresenter);

            this.publishingInfo = pubInfo;

            return;
          }

          this.setUserAsPresenter(data.destUserId, true);

        } else if (data.askingUserId === this.currentUser.id) {

          this.snackBar.open(`request sent to ${data.destUserName}`, 'Dismiss', {
            duration: 5000,
            verticalPosition: 'top'
          });

        }

      } else {

        let msg = data.askingUserId === this.currentUser.id
                ? `${data.destUserName} canceled the present request`
                : data.destUserId === this.currentUser.id
                ? `present request from ${data.askingUserName} canceled`
                : null;

        if (msg != null)
          this.snackBar.open(msg, 'Dismiss', {
            duration: 5000,
            verticalPosition: 'top'
          });

      }

    });

    this.session.on(SignalType.tempPresenter, async (event: SignalEvent) => {

      let data: SignalTempPresenter = JSON.parse(event.data);

      if (data.userId === this.currentUser.id) {

        this.localParticipant.position = data.enable ? data.position : 0;
        this.localParticipant.highlight = data.enable;

      }

      let refUsers = this.presenters;
      let index = refUsers.findIndex(p => p.userId === data.userId);

      if (index === -1) {
        refUsers = this.highlightPresenters;
        index = refUsers.findIndex(p => p.userId === data.userId);
      }

      if (index !== -1) {

        refUsers[index].position = data.enable ? data.position : 0;
        refUsers[index].highlight = data.enable;

        if (data.activate) {

          let newHighlight = refUsers.splice(index, 1)[0];

          if (data.enable) {

            this.highlightPresenters.push(newHighlight);

            let moderatorIndex = this.highlightPresenters.findIndex(p => p.role === 'moderator');

            if (moderatorIndex !== -1)
              this.presenters.push(this.highlightPresenters.splice(moderatorIndex, 1)[0]);

          } else {

            this.presenters.push(newHighlight);

          }

          if (this.highlightPresenters.length > 1 || !this.isSharing(newHighlight.userId))
            await this.stopShare();
  
        }

      }

      if (this.highlightPresenters.length === 0 && !this.publisherShare)
        this.setPublisherReplacement();

    });

  }

  private async joinConferenceStream(connectionId: string, streamId: string, position: number, prevStreamId: string = null) {
    try {
			await this.videoSessionService.joinLesson(
				this.conference.idLesson,
				<JoinSession>{
					role: this.isModerator() || this.isPresenter() ? 'PUBLISHER' : 'SUBSCRIBER',
					connectionId: connectionId,
					streamId: streamId,
					position: position,
					prevStreamId: prevStreamId
				});
		} catch (e) {
			console.error(e);
		}
  }

  private async leaveConferenceStream(connectionId?: string) {
    try {

      if (this.conference != null)
        await this.videoSessionService.leaveLesson(this.conference.idLesson, connectionId);

    } catch (e) {
      console.error(e);
    }
  }

  private getRole(userId: number = this.currentUser.id): "presenter" | "moderator" | "participant" {
    return this.isModerator(userId)
         ? 'moderator'
         : this.isPresenter(userId)
         ? 'presenter'
         : 'participant';
  }

  private async setPublisher(newPublisher: ClientStream) {
    let isNewPublisher = this.highlightPresenters.length > 0 || this.highlightPresenters.findIndex(p => p.userId === newPublisher.userId) === -1;

    if (newPublisher != null)
      this.setPublishingInfo(newPublisher, isNewPublisher);
    else if (this.publisherShare != null)
      this.setPublishingInfo(this.publisherShare, isNewPublisher);

    this.presenters = this.presenters.concat(this.highlightPresenters);
    this.highlightPresenters = newPublisher ? [newPublisher] : [];

    this.publishingInfo = null;
  }

  private async setPublisherShare(newPublisherShare: ClientStream) {
    let isNewPublisher = this.publisherShare?.userId !== newPublisherShare?.userId;

    this.publisherShare = newPublisherShare;
    this.publishingInfo = null;

    if (this.publisherShare != null)
      this.setPublishingInfo(newPublisherShare, isNewPublisher);

    this.toggleHighlightShare(newPublisherShare != null);
  }

  private async setPublishingInfo(newPublisher: ClientStream, isNewPublisher: boolean) {
    let user = `${await firstValueFrom(this.translate.get(this.getRoleString(newPublisher.role)))} ${newPublisher.name}`;

    this.publishingInfo = await firstValueFrom(this.translate.get('x is presenting', { user: user }));

    if (isNewPublisher)
      this.snackBar.open(`${user} is presenting`, 'Dismiss', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private publisherLeft(name: string) {
    this.snackBar.open(`Publisher ${name} has left the conference`, 'Dismiss', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private setPublisherReplacement() {
    let refArray: ClientStream[] = [];
    let index: number = -1;

    // PARTECIPANTI SENZA STREAM
    //if (index === -1 && this.participants.length > 0) {
    //  refArray = this.participants;
    //  index = refArray.findIndex(p => this.isPublisher(p.userId));
    //}

    if (index === -1 && this.presenters.length > 0) {
      refArray = this.presenters;
      index = refArray.findIndex(p => this.isModerator(p.userId));

      //if (index === -1)
      //  index = 0;
    }

    if (index !== -1)
      this.setPublisher(refArray.splice(index, 1)[0]);
  }

  private updatePriority(operation: "add" | "remove") {
    let updatedPriority = this.localParticipant.priority;

    if (operation === "add")
      updatedPriority += PRIORITY_ADD_VALUE;

    if (operation === "remove")
      updatedPriority -= PRIORITY_REMOVE_VALUE;

    if (updatedPriority < PRIORITY_MIN_VALUE)
      updatedPriority = PRIORITY_MIN_VALUE;

    if (updatedPriority > PRIORITY_MAX_VALUE)
      updatedPriority = PRIORITY_MAX_VALUE;

    this.localParticipant.priority = updatedPriority;

    this.sendData(JSON.stringify(<SignalPriorityUpdate>{ priority: this.localParticipant.priority }), [], SignalType.priorityUpdate);
    this.setPriorityTimeout();
  }

  private setPriorityTimeout() {
    if (this.priorityCheckTimeout != null) {
      clearTimeout(this.priorityCheckTimeout);
      this.priorityCheckTimeout = null;
    }

    this.priorityCheckTimeout = setTimeout(() => {

      if (this.localParticipant.priority === PRIORITY_MIN_VALUE)
        return;

      //this.localParticipant.priority === this._priorityCurrentValue
      if (!this._isHandRaised && !this._isSpeaking) {

        this.updatePriority("remove");

      } else if (this._isHandRaised || this._isSpeaking) {

        this.updatePriority("add");

      }

      this.setPriorityTimeout();

    }, PRIORITY_CHECK_TIMEOUT);
  }

  private getHightlightPosition() {
    return this.highlightPresenters.length > 0
         ? this.highlightPresenters.reduce((prev, current) => (prev.position > current.position) ? prev : current).position
         : 0;
  }

  private toggleHighlightShare(toggle: boolean) {

    if (toggle) {

      this.presenters = this.presenters.concat(this.highlightPresenters.filter(p => this.isPresenter(p.userId) || this.isModerator(p.userId)));
      this.participants = this.participants.concat(this.highlightPresenters.filter(p => !this.isPresenter(p.userId) && !this.isModerator(p.userId)));

      this.highlightPresenters = [];

    } else if (this.highlightPresenters.length === 0) {

      this.highlightPresenters = this.presenters.filter(p => p.highlight === true);
      this.presenters = this.presenters.filter(p => p.highlight === false);

    }

  }

  private getRoleString(role: 'publisher' | 'moderator' | 'presenter' | 'participant') {
    return role === 'moderator'
         ? 'Moderator'
         : role === 'presenter'
         ? 'Speaker'
         : 'Participant';
  }

  private startStreamingQualityCheck() {
    this.stopStreamingQualityCheck();

    this.calendar.addStreamingQuality(this.conference.idLesson)
      .subscribe({
        next: () => console.log('Stream quality check'),
        error: err => console.error(err)
      });

    this.streamingQualityInterval = setInterval(() => {

      if (this.platform.BLINK) { // Se Chrome o derivato

        let connType = NetworkInformation.connectionType();

        if (this.validStreamingQualities.includes(connType)) {
  
          this.streamingQualityValues = [];
  
        } else {
  
          if (this.streamingQualityValues.length >= 3)
            this.leave();
  
          this.streamingQualityValues.push(connType);
  
        }

      }

      this.calendar.addStreamingQuality(this.conference.idLesson)
        .subscribe({
          next: () => console.log('Stream quality check'),
          error: err => console.error(err)
        });

    }, STREAMING_QUALITY_CHECK_INTERVAL);

    console.log('Stream quality check start');
  }

  private stopStreamingQualityCheck() {
    if (this.streamingQualityInterval)
      clearInterval(this.streamingQualityInterval);

    this.streamingQualityInterval = undefined;
    this.streamingQualityValues = [];

    console.log('Stream quality check stop');
  }

  private async disposePublisher(publisher: Publisher) {
		if (publisher?.stream.getMediaStream() == undefined)
			return;

		if (publisher.stream.hasVideo)
			await publisher.publishVideo(false, true);

		publisher.stream.getMediaStream()?.getTracks().forEach(t => t.stop());
	}

}
