<mat-drawer-container hasBackdrop="false" id="lesson-container">

    <mat-drawer #directionalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="closeChats($event)">

        <app-directional-chat
            *ngIf="isPublisher()"
            [hidden]="!participantsChatOpened"
            [session]="session"
            [read]="participantsChatOpened"
            (unread)="newParticipantsMessages = $event"
            (onChatsChange)="currentChats = ($event?.length ?? 0)"
            (onClose)="directionalChat.close()"
            enabledRole="participant"
            title="Participants">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="!isPublisher()"
            [hidden]="!publisherChatOpened"
            [session]="session"
            [read]="publisherChatOpened"
            (unread)="newPublisherMesagges = $event"
            (onChatsChange)="currentChats = ($event?.length ?? 0)"
            (onClose)="directionalChat.close()"
            enabledRole="publisher"
            title="Teacher">
        </app-directional-chat>

    </mat-drawer>

    <ng-container [ngTemplateOutlet]="streamLayout"></ng-container>

    <div class="bidirectional-stream">
        <app-user-video *ngIf="bidirectionalClient()"
        [streamManager]="bidirectionalClient().manager"
        [name]="bidirectionalClient().name"
        [color]="bidirectionalClient().color"
        [visualType]="visualType">

        <button mat-mini-fab color="warn"
            (click)="stopBidirectional()"
            [matTooltip]="'Close Video' | translate"
            optionBtn>
            <mat-icon>
                videocam_off
            </mat-icon>
        </button>

        </app-user-video>
    </div>

</mat-drawer-container>

<ng-template #streamLayout>

    <div *ngIf="teachers.length === 0 && participants.length > 0" class="messageBanner" style="top: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: publisherName ? (('Lesson paused by' | translate) + ' ' + publisherName) : ('Lesson paused' | translate),
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <app-user-list-video
        *ngIf="lesson"
        [hidden]="!participantsListOpened"
        [classId]="lesson?.classId"
        [lessonId]="lesson?.id"
        [session]="session"
        [(participants)]="participants"
        [read]="participantsListOpened"
        [lowerAllHandsActive]="isPublisher()"
        [lowerHandEnabled]="isPublisher()"
        (onClose)="participantsListOpened = false"
        (onLowerHand)="toggleHand($event, false)"
        (unread)="newHandNotifications = $event"
        (onCurrentUserHandRaised)="isHandRaised = $event"
        (onLowerAllHands)="toggleHand(null, false)">
    </app-user-list-video>

    <div *ngIf="!currentContent; else contentsContainer" fxLayout="column" fxLayoutGap="20px" class="layout-container">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex>

            <div *ngFor="let teacher of teachers" class="presenter-stream" [style.width]="100 / (teachers.length + shareStreams().length)">
                <app-user-video
                    [streamManager]="teacher.manager"
                    [name]="teacher.name"
                    [color]="teacher.color"
                    [displayOutline]="teacher.userId === currentUser.id"
                    [visualType]="visualType"
                    [handRaised]="teacher.handRaised"
                    [showScreenShare]="currentUser.id === teacher.userId && teacher.manager?.stream?.typeOfVideo === 'SCREEN'"
                    [lowerHandEnabled]="isPublisher()"
                    (onLowerHand)="toggleHand(teacher.userId, false)">

                    <ng-container *ngTemplateOutlet="
                        participantsOptionsBtn;
                        context: {
                            participant: teacher,
                            compactMode: false,
                            disableOptions: false,
                            disableSetAs: false,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>

                </app-user-video>
            </div>

            <div *ngFor="let share of shareStreams()" class="presenter-stream" [style.width]="100 / (teachers.length + shareStreams().length)">
                <app-user-video *ngIf="share"
                    [streamManager]="share.manager"
                    [name]="share.name"
                    [color]="share.color"
                    [displayOutline]="share.userId === currentUser.id"
                    [visualType]="visualType"
                    [displayAudio]="share.manager?.stream?.typeOfVideo === 'SCREEN'"
                    [showScreenShare]="currentUser.id === share.userId && share.manager?.stream?.typeOfVideo === 'SCREEN'">
        
                    <ng-container *ngTemplateOutlet="
                        participantsOptionsBtn;
                        context: {
                            participant: share,
                            compactMode: false,
                            disableOptions: false,
                            disableSetAs: true,
                            disableKickUser: true
                        }" optionBtn>
                    </ng-container>
        
                </app-user-video>
            </div>

        </div>

    </div>

    <app-session-toolbar
        [localParticipant]="localParticipant?.manager"
        [shareActive]="false"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="!isPublisher()"
        [(handRaise)]="isHandRaised"
        [isRecording]="isRecording"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        (onClose)="close()"
        class="bottom-container mat-elevation-z24">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxFlex leftBtns>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>

                <span style="font-weight: bold">{{ publishingInfo ? publishingInfo : '' }}</span>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div *ngIf="isRecording" class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                        <button mat-icon-button>
                            <mat-icon color="warn">
                                fiber_manual_record
                            </mat-icon>
                        </button>
                        <span>{{ 'Recording' | translate }}</span>
                    </div>

                    <div class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                        <button mat-icon-button (click)="openParticipantList()" [matTooltip]="'Participants' | translate">
                            <mat-icon>
                                people
                            </mat-icon>
                        </button>
                        <span>{{ participants.length }}</span>
                    </div>
                </div>

            </div>

            <button mat-mini-fab
                *ngIf="isPublisher()"
                (click)="participantsListOpened = !participantsListOpened"
                [color]="participantsListOpened ? 'accent' : 'primary'"
                [matTooltip]="'Participants' | translate"
                [matBadge]="newHandNotifications"
                [matBadgeHidden]="participantsListOpened || newHandNotifications === 0"
                matBadgeColor="accent">
                <mat-icon>
                    people
                </mat-icon>
            </button>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex rightBtns>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                <button mat-mini-fab
                    *ngIf="isPublisher() && contents.length > 0"
                    [matMenuTriggerFor]="lessonContentMenu"
                    [matTooltip]="'Choose content to display' | translate"
                    [color]="currentContent ? 'accent' : 'primary'">
                    <mat-icon>
                        add_to_queue
                    </mat-icon>
                </button>

                <button mat-mini-fab
                    *ngIf="isPublisher()"
                    (click)="toggleChats('participants')"
                    [disabled]="currentChats === 0"
                    [color]="participantsChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Participants' | translate"
                    [matBadge]="newParticipantsMessages"
                    [matBadgeHidden]="newParticipantsMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>

                <button mat-mini-fab
                    *ngIf="!isPublisher()"
                    (click)="toggleChats('publisher')"
                    [disabled]="currentChats === 0"
                    [color]="publisherChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Teacher' | translate"
                    [matBadge]="newPublisherMesagges"
                    [matBadgeHidden]="newPublisherMesagges === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>

            </div>

            <span style="font-weight: bold">{{ 'Lesson' | translate }} "{{ truncate(lesson?.name, 'No name', 30) }}" {{ 'has started x minutes ago' | translate: { value: lessonElapsedMinutes() } }}</span>

        </div>

    </app-session-toolbar>

</ng-template>

<mat-menu #streamOptionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-userId="userId" let-name="name" let-mode="mode" let-handRaised="handRaised" let-index="index">
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'subject'), type: 'video' }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject'"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('audio', 'subject'), type: 'audio' }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'share'"
            [matMenuTriggerFor]="shareDevicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'share'), type: 'video', i: index }">
            <mat-icon>videocam</mat-icon>
            {{ 'Sharing Device' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="changeSource(device.deviceId, type)"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === device.deviceId || localParticipantAudioDeviceId === device.deviceId">
            {{ device.label }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<mat-menu #shareDevicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type" let-i="i">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="startShare(device.deviceId, i)"
            [class.mat-menu-highlight]="localParticipantShareVideoDeviceId[i] === device.deviceId || localParticipantShareVideoDeviceId[i] === device.deviceId">
            {{ device.label }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableOptions="disableOptions"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactOptionsBtn [ngIf]="currentUser.id === participant?.userId && !disableOptions">
            <button mat-icon-button
                *ngIf="compactMode; else standardOptionsBtn"
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardOptionsBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant"
                color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <!--ng-template #compactKickUserBtn [ngIf]="isPublisher() &&
                                                 currentUser.id !== participant?.userId &&
                                                 !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template-->

        <!--ng-template #compactSetAsPublisherBtn [ngIf]="isPublisher() &&
                                                       !isPublisher(participant?.userId) &&
                                                       !disableSetAs &&
                                                       !shareStreams()">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template-->

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #lessonContentMenu="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item *ngFor="let content of contents" (click)="resetContent(); setContent(content.id, true);">
            <!--app-profile-picture [url]="content.headerImageUrl" size="40px"></app-profile-picture-->
            <mat-icon>
                {{
                    content.type === 1 ?
                    'smart_display' : 
                    content.type === 2 ?
                    'picture_as_pdf' :
                    'insert_drive_file'
                }}
            </mat-icon>
            <span class="trucate-text" style="max-width: 60px">{{ content.name }}</span>
        </button>
        <button mat-menu-item *ngIf="currentContent" (click)="setContent(currentContent.id, false)">
            <mat-icon>remove_from_queue</mat-icon>
            <span>{{ 'Remove the content displayed' | translate }}</span>
        </button>
    </ng-template>
</mat-menu>

<ng-template #contentsContainer>
    <app-player-events *ngIf="currentContent.type === 1 && isPublisher()"
        [media]="currentContent.mediaUrl"
        [enableControls]="true"
        (time)="currentContentTime = $event"
        (paused)="currentContentPause = true; updateContent($event);"
        (played)="currentContentPause = false; updateContent($event);"
        (seek)="updateContent(currentContentTime)"
        controls="nofullscreen nodownload noremoteplayback noplaybackrate"
        height="100%"
        class="content-container">
    </app-player-events>
    <app-player-events *ngIf="currentContent.type === 1 && !isPublisher()"
        [media]="currentContent.mediaUrl"
        [enableControls]="false"
        [pause]="currentContentPause"
        [disableSeekCheck]="true"
        [startTime]="currentContentMarker"
        (time)="currentContentTime = $event"
        height="100%"
        class="content-container">
    </app-player-events>
    <ngx-extended-pdf-viewer *ngIf="currentContent.type === 2"
        [useBrowserLocale]="true"
        [src]="currentContent.mediaUrl"
        [textLayer]="true"
        [showBorders]="false"
        [showOpenFileButton]="false"
        [showBookmarkButton]="false"
        [showPresentationModeButton]="true"
        [filenameForDownload]="currentContent.name"
        [enablePrint]="false"
        [showDownloadButton]="false"
        [contextMenuAllowed]="false"
        (pageChange)="isPublisher() ? updateContent($event) : undefined"
        [(page)]="currentContentMarker"
        [theme]="isDark() ? 'dark' : 'light'"
        class="content-container">
    </ngx-extended-pdf-viewer>
</ng-template>
