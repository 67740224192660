import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { CourseService } from 'src/app/services/course.service';
import { Location } from '@angular/common';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { firstBy } from 'thenby';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { CourseViews, UserCourseViews } from 'src/app/models/courseView';
import { CourseView, CsvExporterService, HASHTAGS_HEADERS, StudentView } from 'src/app/services/csv-exporter.service';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { UserRole } from 'src/app/models/userRole';
import { TranslateService } from '@ngx-translate/core';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewGraphComponent, ViewGraphData } from 'src/app/popup/view-graph/view-graph.component';

@Component({
  selector: 'app-course-analytics',
  templateUrl: './course-analytics.component.html',
  styleUrls: ['./course-analytics.component.scss']
})
export class CourseAnalyticsComponent implements OnInit {

  previewViewsChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    events: [],
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  viewsChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  barChartType: ChartType = 'bar';
  barChartPlugins = [DataLabelsPlugin];

  totalViewsData: ChartDataset[] = [];
  totalViewsLabels: string[] = [];

  totalUserViewsData: ChartDataset[] = [];
  totalUserViewsLabels: string[] = [];

  months: { name: string, value: number }[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];

  courseId: number;
  course: CourseDTO;

  allViewsYears: number[] = [];
  allViewsYear: FormControl<number> = new FormControl<number>(0, { nonNullable: true });

  courseViews: CourseViews[] = [];
  userCourseViews: UserCourseViews[];
  totalViewsCount: number = 0;

  data: UserDTO[] = [];
  dataSrc = new MatTableDataSource<{
    user: UserDTO,
    userViews: UserCourseViews[],
    totalViews: number,
    firstView: UserCourseViews,
    lastView: UserCourseViews,
  }>();

  displayedColumns = ['name', 'totalViews', 'firstView', 'lastView', 'actions'];

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.dataSrc.sort = matSort;
  }

  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.dataSrc.paginator = matPaginator;
  }

  showGraph: 'totalviews' | 'users' = undefined;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private exporter: CsvExporterService,
    private courseService: CourseService,
    private translate: TranslateService,
    private rolePipe: RolePipe,
    private darkService: DarkThemeService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.courseId = +this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit() {
    this.courseService
      .getCourseContent(this.courseId, true)
      .subscribe(output => {
        this.course = output;
      });

    this.courseService
      .getCourseView(this.courseId)
      .subscribe(output => {
        this.courseViews = output;
        this.courseViews.sort(firstBy((cv: CourseViews) => cv.year, 1).thenBy(cv => cv.month, 1));

        this.courseViews
          .map(cv => {
            if (!this.allViewsYears.includes(cv.year))
              this.allViewsYears.push(cv.year);

            return cv;
          });

        this.totalViewsCount = this.courseViews
          .map(cv => cv.totalViews)
          .reduce((partialSum, v) => partialSum + v, 0);

        this.updateTotalViews();
      });

    this.courseService
      .getCourseParticipants(this.courseId)
      .subscribe(res => {
        this.data = res;

        this.dataSrc.sortingDataAccessor = (element, property) => {
          switch (property) {
            case 'name':
              return `${element.user.name} ${element.user.surname}`;
            case 'firstView':
              if (!element.firstView)
                return 0;

              return element.firstView.year + element.firstView.month;
            case 'lastView':
              if (!element.lastView)
                return 0;

              return element.lastView.year + element.lastView.month;
            default: 
              return element[property];
          }
        };

        this.dataSrc.data = res.map(u => <{
          user: UserDTO,
          userViews: UserCourseViews[],
          totalViews: number,
          firstView: UserCourseViews,
          lastView: UserCourseViews
        }>{
            user: u,
            userViews: [],
            totalViews: 0,
            firstView: undefined,
            lastView: undefined
          });

        this.courseService
          .getCourseStudentsView(this.courseId)
          .subscribe(res => {
            this.userCourseViews = res;

            this.dataSrc.data = this.dataSrc.data.map(d => {

              d.userViews = res.filter(ucv => ucv.idUser === d.user.id);
              d.totalViews = d.userViews
                .map(uv => uv.totalViews)
                .reduce((partialSum, v) => partialSum + v, 0);

              if (d.userViews.length > 0) {
                d.firstView = d.userViews[0];
                d.lastView = d.userViews[d.userViews.length - 1];
              }

              return d;

            });

            this.updateTotalUserViews();
          });
      });
  }

  updateTotalViews() {
    let data = this.courseViews
      .filter(cv => this.allViewsYear.value !== 0 ? cv.year === this.allViewsYear.value : true);

    if (this.allViewsYear.value !== 0)
      data = this.months.map(m => {

        let check = data.find(d => d.month === m.value);

        if (check == undefined)
          return <CourseViews>{
            idCourse: this.courseId,
            totalViews: 0,
            year: this.allViewsYear.value,
            month: m.value
          }

        return check;

      });

    this.totalViewsLabels = this.allViewsYear.value !== 0
                            ? data.map(cv => this.translate.instant(this.getMonthName(cv.month)) ?? '-')
                            : data.map(cv => `${this.translate.instant(this.getMonthName(cv.month)) ?? '-'} ${cv.year}`);
    this.totalViewsData = [{
      data: data.map(cv => cv.totalViews),
      label: this.translate.instant('Views'),
      backgroundColor: '#ac92ec'
    }];
  }

  updateTotalUserViews() {
    let courseView = this.userCourseViews.find(ucv => ucv.totalViews > 0);
    let data = this.getUserViews(courseView != undefined ? courseView.idUser : undefined); //this.allViewsYear.value !== 0 ? this.allViewsYear.value : undefined

    this.totalUserViewsLabels = data.labels;
    this.totalUserViewsData = data.dataset;
  }

  getUserViews(userId?: number, year?: number): { dataset: ChartDataset[], labels: string[] } {
    let data = this.userCourseViews
      .filter(ucv => userId != undefined ? ucv.idUser === userId : true)
      .filter(ucv => year != undefined ? ucv.year === year : true);

    if (year == undefined) {
      let views: UserCourseViews[] = [];

      data
        .map(view => {

          let i = views.findIndex(v => v.year === view.year && v.month === view.month);
  
          if (i === -1)
            views.push(view);
          else
            views[i].totalViews += view.totalViews;

        });

      data = views.slice();
    }

    return {
      labels: year !== undefined
            ? data.map(cv => this.translate.instant(this.getMonthName(cv.month)) ?? '-')
            : data.map(cv => `${this.translate.instant(this.getMonthName(cv.month)) ?? '-'} ${cv.year}`),
      dataset: [{
        data: data.map(ucv => ucv.totalViews),
        label: this.translate.instant('Views'),
        backgroundColor: '#ac92ec'
      }]
    };
  }

  viewUserGraph(user: UserDTO, totalViews: number) {
    let data = this.getUserViews(user.id); //this.allViewsYear.value !== 0 ? this.allViewsYear.value : undefined

    this.dialog.open(ViewGraphComponent, {
      data: <ViewGraphData>{
        title: this.translate.instant('Data of user', { user: `${user.name} ${user.surname}` }) + ` (${totalViews})`,
        width: '950px',
        height: '475px',
        dataset: data.dataset,
        labels: data.labels,
        plugins: this.barChartPlugins,
        options: this.viewsChartOptions,
        type: this.barChartType
      },
      width: '1000px'
    });
  }

  exportTotalViewsToCsv() {
    let data: CourseView[] = this.courseViews.map(element => {
      return <CourseView>{
        name: this.course.name,
        author: `${this.course.author.name} ${this.course.author.surname}`,
        description: this.course.description,
        header: this.course.header,
        footer: this.course.footer,
        summary: this.course.showcaseSummary,
        year: element.year,
        month: element.month,
        totalViews: element.totalViews,
        hashtag1: this.course.hashTag[0] ?? "",
        hashtag2: this.course.hashTag[1] ?? "",
        hashtag3: this.course.hashTag[2] ?? "",
        hashtag4: this.course.hashTag[3] ?? "",
        hashtag5: this.course.hashTag[4] ?? "",
        hashtag6: this.course.hashTag[5] ?? "",
        hashtag7: this.course.hashTag[6] ?? "",
        hashtag8: this.course.hashTag[7] ?? "",
        hashtag9: this.course.hashTag[8] ?? "",
        hashtag10: this.course.hashTag[9] ?? "",
      };
    });

    this.exporter.exportDataToCsv(
      data,
      ["Name", "Author", "Description", "Header", "Footer", "Summary", "Year", "Month", "Total Views", ...HASHTAGS_HEADERS],
      `Total Views of ${this.course.name}`
    );
  }

  exportTotalUserViewsToCsv() {
    let data: StudentView[] = this.data.map(user => {
      return <StudentView>{
        name: user.name,
        surname: user.surname,
        email: user.email,
        role: this.rolePipe.transform(UserRole.role(user)),
        city: user?.idprovinceNavigation?.name === undefined ? null : user?.idprovinceNavigation?.name,
        qualification: user?.idqualificationNavigation?.name  === undefined ? null : user?.idqualificationNavigation?.name,
        telephone: user.telephone,
        views: this.getUserViews(user.id)
                .dataset[0].data
                .reduce((partialSum: number, v: number) => partialSum + v, 0),
        courseName: this.course.name,
        courseAuthor: `${this.course.author.name} ${this.course.author.surname}`,
        courseDescription: this.course.description,
        courseHeader: this.course.header,
        courseFooter: this.course.footer,
        courseSummary: this.course.showcaseSummary,
        hashtag1: this.course.hashTag[0] ?? "",
        hashtag2: this.course.hashTag[1] ?? "",
        hashtag3: this.course.hashTag[2] ?? "",
        hashtag4: this.course.hashTag[3] ?? "",
        hashtag5: this.course.hashTag[4] ?? "",
        hashtag6: this.course.hashTag[5] ?? "",
        hashtag7: this.course.hashTag[6] ?? "",
        hashtag8: this.course.hashTag[7] ?? "",
        hashtag9: this.course.hashTag[8] ?? "",
        hashtag10: this.course.hashTag[9] ?? "",
      };
    });

    this.exporter.exportDataToCsv(
      data,
      ["Name", "Surname", "Email", "Role", "Province", "Qualification", "Telephone", "Views", "Course Name", "Course Author", "Course Description", "Course Header", "Course Footer", "Course Summary", ...HASHTAGS_HEADERS],
      `Total Views of participants of ${this.course.name}`
    );
  }

  getMonthName(month: number) {
    return this.months.find(m => m.value === month)?.name;
  }

  doFilter(e: any) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }

  goBack() {
    if (this.showGraph != undefined) {
      this.showGraph = undefined;

      this.allViewsYear.reset();

      this.updateTotalViews();

      return;
    }

    this.location.back();
  }

}
