import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationExtras } from '@angular/router';
import { addDays, differenceInMilliseconds, differenceInSeconds, isToday } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { LessonService } from 'src/app/services/lessons.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { JoinSessionDialogComponent, JoinSessionDialogData } from './join-session-dialog/join-session-dialog.component';
import { AppLessonPopUpComponent } from './app-lesson-pop-up/app-lesson-pop-up.component';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { EmergencyService } from 'src/app/services/emergency.service';
import { LessonNotePopupComponent } from './lesson-note-popup/lesson-note-popup.component';
import { User } from 'src/app/models/user';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { Helper } from 'src/app/helpers/helper';
import { PageEvent } from '@angular/material/paginator';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { MasterType } from 'src/app/models/dto/masterDTO';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ClassroomService } from 'src/app/services/classroom.service';
import { LessonPost } from 'src/app/models/lessonPost';
import { MatAccordion } from '@angular/material/expansion';
import { FormControl } from '@angular/forms';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { ConnectionEvent, OpenVidu } from 'openvidu-browser';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { CourseService } from 'src/app/services/course.service';

const QUICK_LESSON_INTERVAL_TIME: number = 10000; //ms
const ACTIVE_LESSON_INTERVAL_TIME: number = 5000; //ms
const CHECK_PUBLISHER_CONNECTED_TIMEOUT: number = 2000; //ms

@Component({
    selector: 'app-lessons',
    templateUrl: './lessons.component.html',
    styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {

    currentUser: User = this.auth.getCurrentUser();

    selectedCustomer: FormControl<number> = new FormControl(-1);
    selectedCustomerSearch: FormControl<string> = new FormControl();

    backupLessons: LessonSessionDTO[] = [];
    lessons: LessonSessionDTO[] = [];
    scheduledLessons: LessonSessionDTO[] = [];
    lessonActiveStreaming: LessonSessionDTO[] = [];
    classrooms: ClassroomDTO[] = [];
    rooms: RoomDTO[] = [];
    customers: CustomerDTO[] = [];

    attachment: CourseContentDTO[] = [];
    userWithSimlyRec: UserDTO[] = [];
    
    quickLessonInterval: any = undefined;
    activeLessonInterval: any = undefined;

    showAgenda: boolean = false;

    @ViewChild('lessonsList') accordion: MatAccordion;

    value: string;
    pageIndex: number = 0;
    pageNo: number = 0;
    pageSize: number = 10;
    pageStart: number = 0;
    pageEnd: number = this.pageSize;
    
    constructor(
        private lesson: LessonService,
        private calendar: CalendarService,
        private classroomService: ClassroomService,
        private router: Router,
        private auth: AuthService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private videoSessionService: VideoSessionService,
        private emergency: EmergencyService,
        private darkService: DarkThemeService,
        private surveyService: SurveyService,
        private translate: TranslateService,
        private courseService: CourseService
    ) { }

    async ngOnInit() {
        this.showAgenda = this.isStudent()
                       || this.isSpeaker()
                       || this.isTeacher()
                       || this.isTutor();

        this.fetchClassrooms();
        this.fetchRooms();
        
        this.getLessons();

        if (this.isAdmin())
            this.fetchCustomers();

        if (this.showAgenda)
            this.getScheduledLessons();

        if (!this.isAdmin() && !this.isCustomerAdmin()) {

            this.clearQuickLessonInterval();

            this.quickLessonInterval = setInterval(() => {

                this.lesson
                    .getQuickLessons()
                    .subscribe(output => {

                        if (!output || output.length === 0)
                            return;

                        let lesson = output[0];

                        if (lesson.teacherId === this.currentUser.id)
                            return;

                        this.clearQuickLessonInterval();

                        this.router.navigate(['/lesson', lesson.id]);
                        
                    });

            }, QUICK_LESSON_INTERVAL_TIME);

        }

        this.clearActiveLessonInterval();

        this.activeLessonInterval = setInterval(() => {

            this.lesson
                .getActiveStreamingLessons()
                .subscribe(lessons => this.lessonActiveStreaming = lessons);

        }, ACTIVE_LESSON_INTERVAL_TIME);
    }

    ngOnDestroy() {
        this.clearQuickLessonInterval();
        this.clearActiveLessonInterval();
    }

    private clearQuickLessonInterval() {
        if (!this.quickLessonInterval)
            return;

        clearInterval(this.quickLessonInterval);
        this.quickLessonInterval = undefined;
    }

    private clearActiveLessonInterval() {
        if (!this.activeLessonInterval)
            return;

        clearInterval(this.activeLessonInterval);
        this.activeLessonInterval = undefined;
    }

    isStudent() {
        return this.currentUser.isStudent;
    }

    isSpeaker() {
        return this.currentUser.isSpeaker;
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isTutor() {
        return this.currentUser.isTutor;
    }

    isCustomerAdmin() {
        return this.currentUser.isCustomerAdmin;
    }

    isAdmin() {
        return this.currentUser.isAdmin;
    }

    isDark() {
        return this.darkService.isSetDark;
    }

    isLessonPublisher(lesson: LessonSessionDTO) {
        return lesson?.teacherId === this.currentUser.id;
    }

    isClassPublisher(lesson: LessonSessionDTO) {
        return lesson?.classroom?.teacherClassroom.some(t => t.userId === this.currentUser.id && t.publishAbility === 1) ?? false;
    }

    isTodayLesson(lesson: LessonSessionDTO): boolean {
        return isToday(lesson.startPlanned);
    }

    isActiveLesson(idLesson: number) {
        return this.lessonActiveStreaming.some(lesson => lesson.id === idLesson);
    }

    getLessons() {
        this.calendar
            .getLessons(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                1,
                this.isAdmin() && this.selectedCustomer.value !== -1 ? this.selectedCustomer.value : this.isCustomerAdmin() ? this.currentUser.idCustomer : undefined,
                [1, 3, 4],
                undefined,
                true,
                true
            )
            .subscribe({
                next: lessons => {
                    this.backupLessons = lessons.reverse();
                    this.resetLessons();
                },
                error: err => {
                    console.log(err);

                    this.backupLessons = [];
                    this.lessons = [];
                }
            });
    }

    getScheduledLessons() {
        let today: Date = new Date();

        today.setHours(0, 0, 0, 0);

        this.calendar
            .getLessons(
                today,
                addDays(today, 16),
                undefined,
                undefined,
                undefined,
                2,
                undefined,
                [1, 3, 4]
            ).subscribe({
                next: output => this.scheduledLessons = output.slice(),
                error: err => {
                    console.log(err);
                    this.scheduledLessons = [];
                }
            });
    }

    getLessonContents(lesson: LessonSessionDTO) {
        this.attachment = [];

        this.calendar
            .getLessonContents(lesson.id)
            .subscribe(contents => this.attachment = contents);
    }

    getLessonSmilyRecordings(lesson: LessonSessionDTO) {
        this.userWithSimlyRec = [];

        if (!this.isLessonPublisher(lesson) || !lesson.smilyRecording)
            return;

        this.calendar.getLessonUsersSmilyRecording(lesson.id)
            .subscribe(res => this.userWithSimlyRec = res);
    }

    fetchClassrooms() {
        this.classroomService
            .getClassrooms()
            .subscribe(output => this.classrooms = output as ClassroomDTO[]);
    }

    fetchRooms() {
        this.calendar
            .getRoomsForAll()
            .subscribe(output => this.rooms = output);
    }

    fetchCustomers() {
        let res = this.isAdmin()
                ? this.calendar.getCustomerForAdmin()
                : this.calendar.getCustumerForAdmin(this.currentUser.idCustomer);

        res.subscribe(output => this.customers = output);
    }

    addStandardLesson() {
        let eventData: EventDialogData = {
            classId: this.classrooms[0].id,
            classrooms: this.classrooms,
            rooms: this.rooms,
            type: 1,
            isPlanned: false
        };
          
        const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
            width: '600px',
            data: { eventData }
          });

        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            this.lesson.createLesson(result.classId, <LessonPost>{
                name: result.title,
                description: result.description,
                roomId: result.roomId,
                recording: result.recordPlan,
                privateR: result.private,
                smilyRec: result.smilyRec
            })
            .subscribe({
                next: () => {
                    this.snackBar.open('Lesson added!', undefined, { duration: 3000 });
                    this.getLessons();
                },
                error: err => {
                    console.error(err);
                    this.snackBar.open(err.error, undefined, { duration: 5000 });
                }
            });
        });
    }

    async addEasyLesson() {

        let dialogRef = this.dialog.open(JoinSessionDialogComponent, {
            data: {
                easylesson: true
            }
        });

        dialogRef
            .afterClosed()
            .subscribe((joinSessionData: JoinSessionDialogData) => {

                if (!joinSessionData)
                    return;

                this.lesson.createLesson(joinSessionData.classroomId, <LessonPost>{ name: 'Quick Lesson', description: 'Easy Lesson', quickLesson: true })
                    .subscribe({
                        next: (lesson: any) => {
                            this.router.navigate(['/lesson', lesson.id], {
                                queryParams: {
                                    scenario: joinSessionData.scenario,
                                    resolution: joinSessionData.options.quality,
                                    enableVideo: joinSessionData.options.videoEnabled,
                                    enableAudio: joinSessionData.options.audioEnabled,
                                    audioDev0: joinSessionData.options.audioDevice,
                                    videoDev0: joinSessionData.options.scenario[0]
                                }
                            });
                        },
                        error: error => {
                            console.error(error);
                            this.snackBar.open(error.error, undefined, { duration: 5000 });
                        }
                    });
        });
    }

    addSurvey() {
        let eventData: EventDialogData = {
            dialogTitle: 'Add new survey',
            classId: this.classrooms[0].id,
            rooms: this.rooms,
            classrooms: this.classrooms,
            teacherId: this.currentUser.id,
            teachers: [],
            type: 4,
            isPlanned: false
        };

        const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
            width: '500px',
            data: { eventData, page: "master" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            let surveyLesson: SurveyLessonDTO = new SurveyLessonDTO();

            surveyLesson.teacherId = result.teacherId;
            surveyLesson.classId = result.classId;
            surveyLesson.startPlanned = result.startData;
            surveyLesson.endPlanned = result.endData;
            surveyLesson.name = result.title;
            surveyLesson.description = result.description;
            surveyLesson.typeId = 4;
            surveyLesson.survey = new SurveyDTO();
            surveyLesson.survey.surveyJSON = '';

            if (result.survey) {
                surveyLesson.name = result.survey.name;
                surveyLesson.description = result.survey.description;
                surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
            }

            this.surveyService.AddSurveyToLesson(surveyLesson)
                .subscribe({
                    next: (res: ResDTO) => {

                        if (res.Message)
                            this.router.navigate(['/survey-creator', res.Message]);

                    },
                    error: err => {
                        console.error(err);
                        this.snackBar.open(err.message, '', { duration: 5000 });
                    }
                });
        });
    }

    async closeLesson(lesson: LessonSessionDTO) {
        let dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Close lesson')),
                body: await firstValueFrom(this.translate.get(`Are you sure you want to close lesson?`, { name: lesson.name }))
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;

            let noteDialog = this.dialog.open(LessonNotePopupComponent, {
                width: '400px',
                data: { description: lesson.description }
            });

            noteDialog.afterClosed().subscribe(async result => {
                if (result)
                    await this.lesson.addLessonNote(lesson.id, result);

                this.videoSessionService.closeLesson(lesson.id)
                    .then(() => this.getLessons());
            });
        });
    }

    async forceCloseLessons() {
        let dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Close lesson')),
                body: await firstValueFrom(this.translate.get('Are you sure you want to close your open lessons?'))
            }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (!result)
                return;

            await firstValueFrom(this.emergency.UnlockLessosOnTeacher(this.currentUser.id))
                .then(success => {
                    this.snackBar.open('Session closed successfully', '', { duration: 3000 });
                }).catch(err => {
                    console.error(err);
                    this.snackBar.open('Error closing session', '', { duration: 3000 });
                }).finally(() => {
                    this.getLessons();
                });
        });
    }

    async deleteLesson(id: number, name: string, record?: boolean) {

        let msg = record
                ? `Are you sure you want to delete lesson? All associated records will be removed`
                : `Are you sure you want to delete lesson?`;

        const dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Delete lesson')),
                body: await firstValueFrom(this.translate.get(msg, { name: name }))
            }
        });
      
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;

            await this.lesson.deleteLesson(id);
            this.getLessons();
        });
    }

    activateLesson(lesson: LessonSessionDTO) {
        this.calendar.activateLesson(lesson.id)
            .subscribe({
                next: () => {
                    this.getLessons();
                    this.getScheduledLessons();
                },
                error: err => {
                    console.error(err);
                    this.snackBar.open(err.error, undefined, { duration: 5000 });
                }
            });
    }

    activateLessonDisabled(lesson: LessonSessionDTO): boolean {
        if (!this.isLessonPublisher(lesson))
            return true;

        if (!isToday(lesson.startPlanned))
            return true;

        let startDate = new Date(lesson.startPlanned);
        let now = new Date();

        if (lesson.typeId === 4)
            return now < startDate
                || now > new Date(lesson.endPlanned);

        return false;
    }

    async goToLesson(lesson: LessonSessionDTO) {
        if (this.isLessonPublisher(lesson) || this.isClassPublisher(lesson)) {

            if (await this.checkForPublisherAlreadyConnected(lesson.id)) {
                this.router.navigate(['/lesson', lesson.id], { queryParams: { publisher: true } });
                return;
            }

            let dialogRef = this.dialog.open(JoinSessionDialogComponent, {
                data: {
                    easylesson: false,
                    lesson: lesson
                }
            });

            dialogRef
                .afterClosed()
                .subscribe((joinSessionData: JoinSessionDialogData) => {

                    if (!joinSessionData)
                        return;
                    
                    this.router.navigate(['/lesson', lesson.id], {
                        queryParams: {
                            scenario: joinSessionData.scenario,
                            resolution: joinSessionData.options.quality,
                            enableVideo: joinSessionData.options.videoEnabled,
                            enableAudio: joinSessionData.options.audioEnabled,
                            audioDev0: joinSessionData.options.audioDevice,
                            videoDev0: joinSessionData.options.scenario[0],
                            videoDev1: joinSessionData.scenario > 1 ? joinSessionData.options.scenario[1] : undefined,
                            videoDev2: joinSessionData.scenario > 2 ? joinSessionData.options.scenario[2] : undefined
                        }
                    });
            });

            return;
        }

        this.router.navigate(['/lesson', lesson.id]);
    }

    goToVideo(lesson: LessonSessionDTO) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                lessonId: lesson.id,
                title: lesson.name
            }
        };

        this.router.navigate(['/lessonvideo'], navigationExtras);
    }

    async goToAttachment(attachment: CourseContentDTO) {
        try {

            let course = await firstValueFrom(this.courseService.getCourseContent(attachment.idCourse, this.auth.isAuthenticated()));
      
            let ch = new ContentHelper(
              this.router,
              this.dialog,
              this.courseService,
              this.auth.getCurrentUser(),
              attachment,
              course.courseContent,
              course.idAuthor,
              course.mode
            );
      
            ch.goTo();
      
        } catch (e) {
            console.error(e);
        }
    }

    goToReport(lesson: LessonSessionDTO) {
        this.router.navigate(['/calendar', lesson.id]);
    }

    goToSurvey(lesson:LessonSessionDTO, preview: boolean = false) {
        this.router.navigate(['/survey', lesson.idSurvey], preview ? { queryParams: { view: 'preview' } } : { queryParams: { idLesson: lesson.id } });
    }

    goToSurveyDisabled(lesson: LessonSessionDTO) {
        return !this.isLessonPublisher(lesson)
             ? (new Date() < new Date(lesson.startPlanned) || lesson.surveyAnswer?.length > 0)
             : false;
    }

    goToSurveyAnswerDisabled(lesson: LessonSessionDTO) {
        return !lesson.surveyAnswer
            || lesson.surveyAnswer.length === 0
            || !lesson.surveyAnswer[0].score;
    }

    goToSurveyResult(lesson: LessonSessionDTO) {
        this.router.navigate(['/survey-result', lesson.id], { queryParams: { idSurvey: lesson.idSurvey } });
    }

    showResultSurvey(lesson: LessonSessionDTO) {
        this.router.navigate(['/survey-results', lesson.idSurvey], { queryParams: { idLesson: lesson.id, idClassroom: lesson.classId } } );
    }

    showAnalyticsSurvey(lesson: LessonSessionDTO) {
        this.router.navigate(['/survey-analytics', lesson.idSurvey], { queryParams: { idLesson: lesson.id, idClassroom: lesson.classId } } );
    }

    changePage(event?: PageEvent) {
        //this.accordion?.closeAll();
    
        let minIndex = 0; 
        let maxIndex = this.pageSize;
        
        if (event) {
          this.pageIndex = event.pageIndex;
          this.pageSize = event.pageSize;

          minIndex = event.pageIndex * event.pageSize;
          maxIndex = (event.pageIndex + 1) * event.pageSize;
        }
    
        this.pageStart = minIndex;
        this.pageEnd = maxIndex;
        
        this.lessons = this.backupLessons.slice();

        if (!Helper.isNullOrEmpty(this.value))
            this.lessons = this.lessons.filter(l => l.name?.toLowerCase().includes(this.value) || l.description?.toLowerCase().includes(this.value));
    }

    resetLessons(resetSearch: boolean = true) {
        if (resetSearch)
            this.value = undefined;
        
        this.pageIndex = 0;
        this.pageStart = 0;
        this.pageEnd = this.pageSize;
        this.changePage();
    }

    getLessonType(lesson: LessonSessionDTO) {
        if (lesson.typeId === 3)
            return lesson.classroom.master.type === MasterType.oneToOne ? 'Personal Live Digital Academy' : 'Live Digital Academy'

        if (lesson.typeId === 4)
            return 'Survey';

        return 'Lesson';
    }

    getLessonIcon(lesson: LessonSessionDTO) {
        if (lesson.typeId === 3)
            return lesson.classroom.master.type === MasterType.oneToOne ? 'person' : 'people';

        if (lesson.typeId === 4)
            return 'assignment';

        return 'school';
    }

    border(lesson: LessonSessionDTO) {
        if (lesson.stopDate)
          return null;
    
        let border = 'outline: solid thick ';

        if (lesson.state === 1)
            return border += this.lessonActiveStreaming.some(l => l.id === lesson.id) ? 'green' : 'orange';

        let diff = differenceInSeconds(lesson.startPlanned, new Date());
        
        if (lesson.state === 2 && diff < 7200)
            return border += 'red';
    
        return null;
    }

    getTextStatus(lesson: LessonSessionDTO) {
        if (lesson.stopDate)
          return null;

        if (lesson.state === 1) {

            if (lesson.typeId === 1 || lesson.typeId === 3)
                return this.lessonActiveStreaming.some(l => l.id === lesson.id) ? 'Lesson in progress' : 'Lesson paused';

            if (lesson.typeId === 4)
                return (this.isLessonPublisher(lesson) || !lesson.surveyAnswer || lesson.surveyAnswer.length === 0)
                     ? 'Survey in progress'
                     : 'Survey answered';

            return null;
        }

        let diff = differenceInSeconds(lesson.startPlanned, new Date());
        
        if (lesson.state === 2 && diff < 7200)
            return Helper.convertToHMS(diff < 0 ? 0 : (diff * 1000));

        return null;
    }

    dateDiff(start: Date, end: Date) {
        return Helper.convertToHMS(differenceInMilliseconds(new Date(end), new Date(start)));
    }

    private async checkForPublisherAlreadyConnected(lessonId: number): Promise<boolean> {

		return new Promise(async (resolve) => {

			let checkTimeout = setTimeout(() => resolve(false), CHECK_PUBLISHER_CONNECTED_TIMEOUT);

			try {

				let previewClientData = new ClientData(
					this.currentUser.id,
					`${this.currentUser.name} ${this.currentUser.surname}`,
					this.currentUser.profilePictureUrl,
					'publisher',
					'subject',
					'camera',
					undefined,
					true
				);
	
				let previewToken =  await firstValueFrom(this.videoSessionService.generateToken(lessonId, undefined, true));
				
				let previewOV = new OpenVidu();
	
				let previewSession = previewOV.initSession();
	
				previewSession.on('connectionCreated', (event: ConnectionEvent) => {
					let clientData = <ClientData>JSON.parse(event.connection.data);
	
					if (clientData.hidden)
						return;
	
					if (clientData.userId !== this.currentUser.id &&
                        clientData.role === 'publisher' &&
                        clientData.mode === 'subject') {

						clearTimeout(checkTimeout);

						previewSession.disconnect();

						previewOV = undefined;
						previewSession = undefined;

						resolve(true);

					}
				});
	
				await previewSession.connect(previewToken.token, previewClientData);
		  
			} catch (e) {
				resolve(false);
			}

		});
		
	}
    
}
