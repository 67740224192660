import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Session, SignalEvent } from 'openvidu-browser';
import { Helper } from 'src/app/helpers/helper';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { SignalPriorityUpdate } from 'src/app/models/conference-session/signalPriorityUpdate';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

const PARTICIPANT_POSITION_KEY: string = "participantPositionConfig";
const NOTIFICATION_SOUND: string = "../../assets/sound/note.mp3";

// Per griglia
const GRID_ELEMENT_PADDING: number = 10; //px

@Component({
  selector: 'app-session-participants',
  templateUrl: './session-participants.component.html',
  styleUrls: ['./session-participants.component.scss']
})
export class SessionParticipantsComponent implements OnInit, OnDestroy {

  private _session: Session;
  private _readNotifications: boolean = true;
  private _unreadNotifications: number = 0;
  private _notificationSound = new Audio();

  @Input()
  set session (value: Session) {

    if (!value)
      return;

    this._session?.off(SignalType.toggleHand);

    this._session = value;

    this._session.on(SignalType.toggleHand, (event: SignalEvent) => {

      let data: SignalRaiseHand = JSON.parse(event.data);
      let clientData: ClientData = JSON.parse(event.from.data);

      // Corrisponse al messaggio lowerAllHands
      if (data.userId == null) {

        if ((this.mode === 'conference' && (clientData.role === 'presenter' || clientData.role === 'publisher')) ||
            (this.mode === 'webinar' && clientData.role === 'moderator')) {

          this.onCurrentUserHandRaised.emit(false);
          this.participants.forEach(p => p.handRaised = false);

        }

        return;
      }

      if (data.userId === this.currentUser.id)
        this.onCurrentUserHandRaised.emit(data.raise);

      let index = this.participants.findIndex(p => p.userId === data.userId);

      if (index === -1)
        return;

      this.participants[index].handRaised = data.raise;
      this.participantsChange.emit(this.participants);

      if (!this._readNotifications && clientData.userId !== this.currentUser.id) {

        if (data.raise) {

          this.unread.emit(++this._unreadNotifications);
          this._notificationSound.play();

        } else if (this._unreadNotifications > 0) {

          this.unread.emit(--this._unreadNotifications);
          
        }

      }

    });

    this._session.on(SignalType.priorityUpdate, (event: SignalEvent) => {

      let data: SignalPriorityUpdate = JSON.parse(event.data);
      let clientData: ClientData = JSON.parse(event.from.data);

      let index = this.participants.findIndex(p => p.userId === clientData.userId);

      if (index === -1)
        return;

      this.participants[index].priority = data.priority;

      this.participantsChange.emit(this.participants);

    });

  }

  @Input()
  set read (value: boolean) {
    
    this._readNotifications = value;

    if (this._readNotifications) {
      this._unreadNotifications = 0;
      this.unread.emit(this._unreadNotifications);
    }
      
  }

  @Input()
  participants: ClientStream[] = [];

  @Input()
  presenters: ClientStream[] = [];

  @Input()
  dragBoundary: string = null;

  @Input()
  optionMenuBtn: any = null;

  @Input()
  lowerAllHandsActive: boolean = false;

  @Input()
  lowerHandEnabled: boolean = false;

  @Input()
  mode: 'conference' | 'webinar' = 'conference';

  @Input()
  overlay: boolean = true;

  @Input()
  selectionEnabled: boolean = false;

  @Input()
  selectedParticipants: number[] = [];

  @Input()
  selectedParticipantsDisabled: number[] = [];

  @Input()
  visualType: string = 'cover';

  // Inizio - Per griglia
  @Input()
  set updateParticipants(value: boolean) {
    this.calculateGridData();
  }

  @Input()
  maxColumns: number = undefined;

  @Input()
  maxRows: number = undefined;

  @Input()
  aspectRatio: number = undefined;
  // Fine

  @Output()
  unread: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  participantsChange: EventEmitter<ClientStream[]> = new EventEmitter<ClientStream[]>();

  @Output()
  selectedParticipantsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  onCurrentUserHandRaised: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerAllHands: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerHand: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onPriorityPosition: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUser: User;
  currentParticipant: ClientStream;
  refresh: boolean = false;
  isDragging: boolean = false;
	participantsClass: 'top-container' | 'left-container' | 'right-container' = 'left-container';

  // Per griglia
  grid: { columns: number, rows: number } = { columns: 1, rows: 1 };
	gridRowLength: number = 1;
	gridElementsLength: number = 0;
	gridElementsLengthLast: number = 0;

	gridElementPadding: string = `${GRID_ELEMENT_PADDING}px`;
	gridElementHeight: string = '0px';
	gridElementWidth: string = '0px';

  constructor(private auth: AuthService) {
    this._notificationSound.src = NOTIFICATION_SOUND;
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.overlay)
      this.participantsClass = (localStorage.getItem(PARTICIPANT_POSITION_KEY) as 'top-container' | 'left-container' | 'right-container') ?? 'top-container';
  }

  ngOnDestroy(): void {

    this._session?.off(SignalType.toggleHand);

    this.participants = [];
    this._readNotifications = true;
    this._unreadNotifications = 0;

  }

  dragStart(event: CdkDragStart) {
		this.isDragging = true;
	}

	dragEnd(event: CdkDragEnd) {
		this.isDragging = false;
		this.refresh = true;

		let height = window.innerHeight;
		let width = window.innerWidth;
		let heightArea = (height / 100) * 5; // 5% dell'altezza
		let widthArea = (width / 100) * 5; // 5% della larghezza
		
		let newDragPosition = event.dropPoint;

		if (newDragPosition.y < heightArea)
			this.participantsClass = 'top-container';
		//else if (newDragPosition.y > (height - heightArea))
		//	this.participantsClass = 'bottom-container';
		else if (newDragPosition.x < widthArea)
			this.participantsClass = 'left-container';
		else if (newDragPosition.x > (width - widthArea))
			this.participantsClass = 'right-container';

    if (this.overlay)
		  localStorage.setItem(PARTICIPANT_POSITION_KEY, this.participantsClass);
			
		setTimeout(() => this.refresh = false);
	}

  getOtherParticipants(type: 'presenters' | 'participants') {
    let currentParticipant = this.participants.find(p => p.userId === this.currentUser.id);

    if (currentParticipant == null)
      currentParticipant = this.presenters.find(p => p.userId === this.currentUser.id);

    if (this.currentParticipant != currentParticipant)
      this.currentParticipant = currentParticipant;

    return (type === 'presenters' ? this.presenters : this.participants).filter(p => p.userId !== this.currentUser.id);
  }

  lowerHand(userId: number) {
    if (this.lowerHandEnabled)
      this.onLowerHand.emit(userId);
  }

  isSelectedParticipant(userId: number) {
    return this.selectedParticipants.some(sp => sp === userId);
  }

  isSelectedParticipantDisabled(userId: number) {
    return this.currentUser.id === userId
        || this.selectedParticipantsDisabled.some(spd => spd === userId);
  }

  onSelectedParticipant(userId: number) {
    if (!this.selectionEnabled || this.isSelectedParticipantDisabled(userId))
      return;

    let index = this.selectedParticipants.findIndex(sp => sp === userId);

    index === -1 ?
    this.selectedParticipants.push(userId) :
    this.selectedParticipants.splice(index, 1);

    this.selectedParticipantsChange.emit(this.selectedParticipants);
  }

  close() {
    this.onClose.emit(true);
  }

  // Per griglia

  isLastRowElement(index: number) {
		return ++index > (this.grid.columns * (this.grid.rows - 1));
	}

	private calculateGrid(elements: number, maxColumns?: number, maxRows?: number): { columns: number, rows: number } {
		if (!elements || elements === 0)
			return { columns: 1, rows: 1 };

		let columns = Math.ceil(Math.sqrt(elements));

		if (maxColumns && columns > maxColumns)
			columns = maxColumns;

		let rows = Math.ceil(elements / columns);

		if (maxRows && rows > maxRows) {

			rows = maxRows;
			columns = Math.ceil(elements / rows);

			if (maxColumns && columns > maxColumns)
				columns = maxColumns;

		}

		return { columns: columns, rows: rows };
	}

	private calcGridRowLength(elements: number, cols: number, rows: number) {
		let rowLength = cols;
		let lastRowElements = elements - (cols * (rows - 1));

		function gcd(a: number, b: number) {
			for (let i = b; b !== 0;) {
			  b = a % b;
			  a = i;
			  i = b;
			}
		  
			return a;
		}

		return { length: (rowLength * lastRowElements) / gcd(rowLength, lastRowElements), lastRowElements: lastRowElements };
	}

	private async calculateGridData() {
		await Helper.sleep(200);

		let container = document.getElementById('grid-container');

		let gridWidth = container.offsetWidth;
		let gridHeight = container.offsetHeight;
		let grid = this.calculateGrid(this.participants.length, this.maxColumns, this.maxRows);

		let gridElementWidth = gridWidth / grid.columns;
		let gridElementHeight = 0;

		if (this.aspectRatio) {

			gridElementHeight = gridElementWidth / this.aspectRatio;

		} else {

			gridElementHeight = gridHeight / grid.rows;
			gridElementHeight -= (grid.rows - 1) * GRID_ELEMENT_PADDING / grid.rows;

		}

		this.gridElementWidth = `${gridElementWidth}px`;
		this.gridElementHeight = `${gridElementHeight}px`;
		this.grid = grid;

		let gridRowLength = this.calcGridRowLength(this.participants.length, grid.columns, grid.rows);

		this.gridElementsLength = gridRowLength.length / grid.columns;
		this.gridElementsLengthLast = gridRowLength.length / gridRowLength.lastRowElements;

		this.gridRowLength = gridRowLength.length;
	}

}
