<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'My Products' | translate }}</h2>
    </div>
    <div class="mat-elevation-z8">
        <div fxLayout="row" fxLayoutGap="20px" style="padding: 10px 20px 0px 20px">
            <div fxLayoutGap="20px" fxFlex>
                <mat-form-field color="primary">
                    <mat-label>{{ 'Search' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field color="primary">
                    <mat-label>{{ 'Type' | translate }}</mat-label>
                    <mat-select [(value)]="selectedType" (selectionChange)="getPurchases()">
                        <mat-option value="all">{{ 'All' | translate }}</mat-option>
                        <mat-option *ngFor="let item of types" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="primary">
                    <mat-label>{{ 'Source' | translate }}</mat-label>
                    <mat-select [(value)]="selectedSource" (selectionChange)="getPurchases()">
                        <mat-option value="all">{{ 'All' | translate }}</mat-option>
                        <mat-option *ngFor="let item of sources" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        
        <mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort>

            <ng-container matColumnDef="picture">
                <mat-header-cell *matHeaderCellDef>{{ 'Thumbnail' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <img [src]="element.cartItemImage" loading="lazy"/>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element;">{{ element.created | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cartItemName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.cartItemName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Amount' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.amount === 0 ? element.amount : element.amount | number : '1.2-2'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="currency">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Currency' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.currency }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="paymentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.paymentType }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="paymentSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Source' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.paymentSource }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="goToEntity(element.idsubscription)" [matTooltip]="'View' | translate">
                        <mat-icon>launch</mat-icon>
                    </button>
                    <button mat-icon-button
                        (click)="toggleRow(element)"
                        [disabled]="!element.subscriptionSessions || element.subscriptionSessions.length === 0"
                        [matTooltip]="(selectedSubSession?.id === element?.id ? 'Collapse' : 'Expand') | translate">
                        <mat-icon>{{ selectedSubSession === element ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="element-detail" [@detailExpand]="selectedSubSession === element ? 'expanded' : 'collapsed'">

                        <mat-table #table [dataSource]="element.subscriptionSessions" style="padding: 20px 0 20px 0">

                            <ng-container matColumnDef="picture">
                                <mat-header-cell *matHeaderCellDef>{{ 'Thumbnail' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <img [src]="element.cartItemImage" loading="lazy"/>
                                </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="cartItemName">
                                <mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span>
                                        {{ element.cartItemName }}
                                    </span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cartItemDescription">
                                <mat-header-cell *matHeaderCellDef>{{ 'Description' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span>
                                        {{ element.cartItemDescription?.length > 100 ? ((element.cartItemDescription | slice:0:97) + '...') : element.cartItemDescription }}
                                    </span>
                                </mat-cell>
                            </ng-container>
                
                            <mat-header-row *matHeaderRowDef="subDisplayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: subDisplayedColumns;"></mat-row>
                        </mat-table>

                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="collapseRow(row)"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" color="primary">
        </mat-paginator>
    </div>
</div>
