<mat-drawer-container hasBackdrop="false" id="conference-container">

    <mat-drawer #globalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="globalChatOpened = !globalChatOpened">

        <app-directional-chat
            [session]="session"
            [addUsersOnConnection]="false"
            [aggregateChats]="false"
            [forwardActive]="false"
            [replyActive]="false"
            [hideActive]="false"
            [globalUsersChat]="true"
            [globalUsersChatEnabledRoles]="['publisher', 'presenter', 'participant']"
            [read]="globalChatOpened"
            (unread)="newChatMessages = $event"
            (onClose)="globalChat.close()"
            title="Messages">
        </app-directional-chat>

    </mat-drawer>

    <div *ngIf="initialSelection; else streamLayout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <app-source-selection
            [OV]="OV"
            [videoDevices]="getDevices('video', 'all')"
            [audioDevices]="getDevices('audio', 'all')"
            [audioEnabled]="isPublisher()"
            [username]="getUsername()"
            [entityName]="'conference'"
            [lockForm]="lockJoin"
            [acceptedNetwork]="validStreamingQualities"
            (result)="$event == null ? leave() : joinConference($event)"> <!--[audioDeviceEnabled]="isPresenter()"-->
        </app-source-selection>
    </div>

</mat-drawer-container>

<ng-template #streamLayout>

    <button mat-fab class="bottom-button"
        [style.bottom]="toolbarOpened ? '72px' : '0'"
        [color]="toolbarOpened ? 'accent' : 'primary'"
        (click)="toolbarOpened = !toolbarOpened"
        [matBadge]="getAllNotifications()"
        [matBadgeHidden]="toolbarOpened || getAllNotifications() === 0"
        matBadgeColor="accent">
        <mat-icon>{{ toolbarOpened ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>

    <button mat-mini-fab class="top-right-button"
        *ngIf="virtualRoomId && isPublisher()"
        (click)="leave(true, ['/conference', this.id])"
        [matTooltip]="'Leave room' | translate"
        color="warn">
        <mat-icon>output</mat-icon>
    </button>

    <div *ngIf="conferenceRemainingMinutes() <= 10" class="messageBanner" style="top: 40px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'The conference will be closed in x minutes' | translate: { value: conferenceRemainingMinutes() },
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="height: 100vh; padding: 20px;">

        <ng-container *ngIf="publisherShare.share" [ngTemplateOutlet]="shareLayout"></ng-container>

        <app-session-participants
            [hidden]="participants.length === 0"
            [session]="session"
            [participants]="participants"
            [optionMenuBtn]="participantsOptionsBtn"
            [read]="readParticipants"
            [selectionEnabled]="selectedRoomData"
            [(selectedParticipants)]="selectedUsers"
            [selectedParticipantsDisabled]="connectedUsers"
            [updateParticipants]="updateParticipants"
            [maxColumns]="publisherShare.share ? 1 : undefined"
            [aspectRatio]="publisherShare.share ? 16 / 9 : undefined"
            [visualType]="visualType"
            (onParticipantsChange)="orderParticipants()"
            (onLowerHand)="toggleHand($event, false)"
            (unread)="newHandNotifications = $event"
            (onCurrentUserHandRaised)="isHandRaised = $event"
            (onPriorityPosition)="priorityPosition = $event"
            mode="conference"
            [style.width]="publisherShare.share ? '20%' : '100%'"
            style="height: 100%">
        </app-session-participants>

    </div>

    <app-session-toolbar
        [hidden]="!toolbarOpened"
        [localParticipant]="localParticipant?.manager"
        [videoDevices]="getDevices('video', 'share')"
        [isVideoOverride]="isVideoOverride()"
        [hasScreenShareCapabilities]="hasScreenShareCapabilities"
        [shareActive]="isPublisher() || isPresenter() || isPublishing()"
        [shareEnabled]="(isPublisher() && !publisherShare.publisher && !publisherShare.tempPublisher) || isPublishing()"
        [shareDeviceId]="localParticipantShareVideoDeviceId"
        [isSharing]="isSharing()"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="!isPublisher() && !isPresenter() && !isPublishing()"
        [(handRaise)]="isHandRaised"
        [recordingActive]="isPublisher() && conference?.lessonSession?.recodingPlanned"
        [isRecording]="isRecording"
        [recordingEnabled]="!togglingRecording"
        [httpLink]="httpLink"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onRecordingChange)="toggleRecording($event)"
        (onStartShare)="startShare($event)"
        (onStopShare)="stopShare()"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        class="bottom-container mat-elevation-z24"> <!--[toggleAudioActive]="isPublisher() || isPresenter() || isPublishing()"-->

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex leftBtns>

            <span style="font-weight: bold">{{ publishingInfo ? publishingInfo : '' }}</span>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

                <div *ngIf="isRecording" class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                    <button mat-icon-button>
                        <mat-icon color="warn">
                            fiber_manual_record
                        </mat-icon>
                    </button>
                    <span>{{ 'Recording' | translate }}</span>
                </div>

                <div class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                    <button mat-icon-button [matTooltip]="'Participants' | translate">
                        <mat-icon>
                            people
                        </mat-icon>
                    </button>
                    <span>{{ participants.length }}</span>
                </div>

                <div *ngIf="isPublisher() || isPresenter()">
                    <button mat-mini-fab
                        (click)="toggleHand(null, false); readParticipants = !readParticipants;"
                        [color]="newHandNotifications > 0 ? 'accent' : 'primary'"
                        [matTooltip]="'Lower all hands' | translate"
                        [matBadge]="newHandNotifications"
                        [matBadgeHidden]="newHandNotifications === 0"
                        color="primary">
                        <mat-icon>
                            do_not_touch
                        </mat-icon>
                    </button>
                </div>

            </div>
            
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex rightBtns>

            <div fxLayput="row" fxLayoutGap="10px">
                <button mat-mini-fab
                    (click)="globalChat.toggle()"
                    [color]="globalChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Chat' | translate"
                    [matBadge]="newChatMessages"
                    [matBadgeHidden]="newChatMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>

                <!-- <button mat-mini-fab
                    *ngIf="isPublisher()"
                    [matMenuTriggerFor]="roomsMenu"
                    [matMenuTriggerData]="{ rooms: virtualRooms }"
                    [matTooltip]="'Room' | translate"
                    color="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button> -->

                <button mat-mini-fab 
                    *ngIf="isPublisher() || isPresenter()"
                    (click)="toggleRoomsToolbar()"
                    [matTooltip]="'Virtual rooms' | translate"
                    [color]="toolbarRoomsOpened ? 'accent' : 'primary'">
                    <mat-icon>groups</mat-icon>
                </button>

                <button mat-mini-fab
                    *ngIf="virtualRoomId"
                    (click)="leave(true, ['/conference', this.id])"
                    [matTooltip]="'Leave room' | translate"
                    color="warn">
                    <mat-icon>output</mat-icon>
                </button>
            </div>

            <span style="font-weight: bold" *ngIf="!virtualRoomId">
                {{ 'Conference' | translate }} "{{ conference?.name }}" {{ 'has started x minutes ago' | translate: { value: conferenceElapsedMinutes() } }}
            </span>

            <span style="font-weight: bold" *ngIf="virtualRoomId">
                {{ 'Room' | translate }} "{{ room?.name }}"
            </span>
        
        </div>

    </app-session-toolbar>

    <app-rooms-toolbar
        [hidden]="!toolbarRoomsOpened"
        [rooms]="virtualRooms"
        [conferenceId]="id"
        [currentRoomId]="virtualRoomId"
        [currentUserId]="currentUser.id"
        [selectEnabled]="isPublisher()"
        (onSelect)="selectedRoomData = $event"
        (onJoin)="handleJoinEvent($event, currentUser.id)"
        (onClose)="toggleRoomsToolbar(false)"
        class="right-container">
    </app-rooms-toolbar>

    <ng-container *ngIf="selectedRoomData">
        <app-room-card
            [roomData]="selectedRoomData"
            [conferenceId]="id"
            [(selectedUsers)]="selectedUsers"
            (onData)="handleDataEvent($event)"
            (onConnectedUsers)="handleConnectedUsers($event)"
            (onClose)="clearSelectedRoom()"
            class="room-card-container">
        </app-room-card>
    </ng-container>
   
</ng-template>

<ng-template #shareLayout>
    <div class="presenter-stream">

        <app-user-video
            [streamManager]="publisherShare.share.manager"
            [name]="publisherShare.share.name"
            [color]="publisherShare.share.color"
            [displayOutline]="publisherShare.share.userId === currentUser.id"
            [visualType]="visualType"
            [displayName]="false"
            [displayAudio]="publisherShare.share.type === 'screen'"
            [showScreenShare]="currentUser.id === publisherShare.share.userId && publisherShare.share.type === 'screen'">

            <ng-container *ngTemplateOutlet="
                participantsOptionsBtn;
                context: {
                    participant: publisherShare.publisher,
                    compactMode: false,
                    disableOptions: true,
                    disableSetAs: false,
                    disableKickUser: publisherShare.share.userId === currentUser.id
                }" optionBtn>
            </ng-container>

        </app-user-video>

        <div class="share-stream">
            <ng-container *ngTemplateOutlet="publisherStream; context: { displayOutline: false, sharingMode: true }"></ng-container>
        </div>

    </div>
</ng-template>

<ng-template #publisherStream let-displayOutline="displayOutline" let-sharingMode="sharingMode">
    <app-user-video *ngIf="publisherShare.publisher"
        [streamManager]="publisherShare.publisher.manager"
        [name]="publisherShare.publisher.name"
        [color]="publisherShare.publisher.color"
        [compactMode]="sharingMode"
        [dragEnabled]="sharingMode"
        [cursor]="sharingMode ? 'move' : ''"
        [z_index]="sharingMode ? 10 : 1 "
        [displayOutline]="displayOutline"
        [visualType]="visualType"
        [handRaised]="publisherShare.publisher.handRaised"
        [lowerHandEnabled]="isPublisher() || isPresenter()"
        (onLowerHand)="toggleHand(publisherShare.publisher.userId, false)"
        dragBoundary="#conference-container">

        <ng-container *ngTemplateOutlet="
            participantsOptionsBtn;
            context: {
                participant: publisherShare.publisher,
                compactMode: sharingMode,
                disableOptions: false,
                disableSetAs: sharingMode,
                disableKickUser: sharingMode
            }" optionBtn>
        </ng-container>

    </app-user-video>
</ng-template>

<mat-menu #streamOptionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-userId="userId" let-name="name" let-mode="mode" let-handRaised="handRaised">
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'subject'), type: 'video' }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject'"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('audio', 'subject'), type: 'audio' }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            (click)="localParticipantMirror = !localParticipantMirror; changeSource(localParticipantVideoDeviceId, 'video');"
            [class.mat-menu-highlight]="localParticipantMirror">
            <mat-icon>switch_video</mat-icon>
            {{ 'Mirror' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="changeSource(device.deviceId, type)"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === device.deviceId || localParticipantAudioDeviceId === device.deviceId">
            {{ device.label }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableOptions="disableOptions"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactOptionsBtn [ngIf]="currentUser.id === participant?.userId && !disableOptions">
            <button mat-icon-button
                *ngIf="compactMode; else standardOptionsBtn"
                (click)="isFullScreen = false"
                [matTooltip]="'Settings' | translate"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardOptionsBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false"
                [matTooltip]="'Settings' | translate"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant"
                color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactKickUserBtn [ngIf]="isPublisher() &&
                                                 currentUser.id !== participant?.userId &&
                                                 !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactSetAsPublisherBtn [ngIf]="!disableSetAs &&
                                                       (
                                                        (isPresenter(participant) && isSharedPresenters()) ||
                                                        (!isPublisher(participant) && isExclusivePresenter())
                                                       ) &&
                                                       isPublisher() &&
                                                       !isPublisher(participant) &&
                                                       (isSharing(participant?.userId) || !publisherShare.share)">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>
