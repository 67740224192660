<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h2 style="align-self: center;">
            {{ (isAdmin() || isCustomerAdmin() ? 'Conferences' : 'My Conferences') | translate }}
        </h2>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 10px">
        <div fxLayout="row" fxFlex="20">
            <mat-form-field color="primary">
                <mat-label>{{ 'Search' | translate }}...</mat-label>
                <input matInput [(ngModel)]="value" (ngModelChange)="resetConferences(false)">
                <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Clear' | translate" (click)="resetConferences(true)">
                    <mat-icon>clear</mat-icon>
                </button>  
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="60" fxLayoutGap="15px" style="margin-bottom: 14.6px">
            <button mat-fab extended color="primary"
                *ngIf="isSpeaker() || isTeacher() || isTutor() || isCustomerAdmin() || isAdmin()"
                (click)="addPublicConference()"
                [disabled]="(!isSpeaker() && !isTeacher() && !isTutor()) || !currentUser.streamingEnabled"
                [matTooltip]="'Add public conference' | translate">
                <mat-icon>add</mat-icon>
                {{ 'Public conference' | translate }}
            </button>
            <button mat-fab color="primary"
                (click)="ngOnInit()"
                [matTooltip]="'Refresh' | translate">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-fab extended color="accent"
                *ngIf="isSpeaker() || isTeacher() || isTutor() || isCustomerAdmin() || isAdmin()"
                (click)="addPrivateConference()"
                [disabled]="(!isSpeaker() && !isTeacher() && !isTutor()) || !currentUser.streamingEnabled"
                [matTooltip]="'Add private conference' | translate">
                <mat-icon>add</mat-icon>
                {{ 'Private conference' | translate }}
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
            <mat-form-field color="primary">
                <mat-label>{{ 'Mode' | translate }}</mat-label>
                <mat-select [(value)]="mode" (selectionChange)="getConferences()">
                    <mat-option value="incoming">{{ 'Incoming' | translate }}</mat-option>
                    <mat-option value="performed">{{ 'Performed' | translate }}</mat-option>
                    <mat-option value="expired">{{ 'Expired' | translate }}</mat-option>
                    <mat-option value="all">{{ 'All' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div style="width: 100%">
        <mat-accordion> 
            <mat-expansion-panel *ngFor="let conference of conferences | slice:pageStart:pageEnd"
                (afterExpand)="getUsersByClassroom(conference.lessonSession.classId)"
                (closed)="classroom = null"
                [style]="border(conference)"
                style="overflow: visible; margin-bottom: 10px; border-radius: 4px;">
                <mat-expansion-panel-header>

                    <mat-panel-title class="truncate-text">
                        <mat-icon [matTooltip]="(conference.isPublic ? 'Public conference' : 'Private conference') | translate">
                            {{ conference.isPublic ? 'people' : 'person' }}
                        </mat-icon>
                        <span class="conference-title truncate-text" style="white-space: normal">
                            {{ conference.name }}
                        </span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div class="conference-description truncate-text" style="width: 25%">
                            {{ conference.lessonSession.teacher.name }} {{ conference.lessonSession.teacher.surname }}
                        </div>

                        <div class="conference-description" style="width: 30%">
                            {{ conference.lessonSession.startPlanned | date: 'dd/MM/yyyy' }}
                            {{ 'from' | translate }}
                            {{ conference.lessonSession.startPlanned | date: 'HH:mm' }}
                            {{ 'to' | translate }}
                            {{ conference.lessonSession.endPlanned | date: 'HH:mm' }}
                        </div>

                        <div *ngIf="conference.lessonSession.startDate && conference.lessonSession.stopDate; else conferenceStatus" class="conference-description" style="width: 25%">
                            {{ 'Performed ' | translate }}{{ 'from' | translate }} {{ conference.lessonSession.startDate | date: 'HH:mm' }}
                            {{ 'to' | translate }} {{ conference.lessonSession.stopDate | date: 'HH:mm' }}
                        </div>

                        <ng-template #conferenceStatus>
                            <div class="conference-description" style="width: 25%">
                                {{ (getTextStatus(conference) | translate) ?? '&nbsp;' }}
                            </div>
                        </ng-template>  

                        <div style="text-align: end; width: 25%;">
                            &nbsp;

                            <ng-template [ngIf]="showRecording(conference.lessonSession, conference.conferencePresenter)">
                                <button mat-icon-button color="warn"
                                    *ngIf="conference.lessonSession.recordingElaborationError"
                                    [matTooltip]="'totE recording processing errors' | translate : { totE: conference.lessonSession.recordingElaborationError }">
                                    <mat-icon>error</mat-icon>
                                </button>

                                <button mat-icon-button
                                    [matTooltip]="'totA of tot recording available' | translate: { totA: conference.lessonSession.recordingElaborationSucceeded, tot: conference.lessonSession.recordingTotal }"
                                    [style.color]="getRecordingColor(conference.lessonSession)">
                                    <mat-icon
                                        [matBadge]="conference.lessonSession.recordingElaborationQueue + conference.lessonSession.recordingElaborating"
                                        [matBadgeHidden]="conference.lessonSession.recordingElaborationQueue + conference.lessonSession.recordingElaborating < 1"
                                        matBadgeSize="small">
                                        fiber_manual_record
                                    </mat-icon>
                                </button>
                            </ng-template>

                            <button mat-icon-button color="primary"
                                *ngIf="!conference.lessonSession.stopDate &&
                                       !isExpired(conference.lessonSession)"
                                (click)="urlCopied(); $event.stopPropagation();"
                                [cdkCopyToClipboard]="copyLink(conference.httpLink)"
                                [matTooltip]="'Copy link' | translate">
                                <mat-icon>link</mat-icon>
                            </button>

                            <button mat-icon-button
                                *ngIf="!conference.lessonSession.stopDate &&
                                       isToday(conference.lessonSession)"
                                (click)="goToConference(conference); $event.stopPropagation();"
                                [disabled]="!activePlayButton(conference.lessonSession)"
                                [matTooltip]="'Go to conference' | translate"
                                [class.link-btn]="activePlayButton(conference.lessonSession)">                                        
                                <mat-icon [style.color]="checkStreamingStatus(conference) ? '' : 'orange'">
                                    ondemand_video
                                </mat-icon>
                            </button>
                            
                            <button mat-icon-button
                                *ngIf="(isAuthor(conference.lessonSession) || isSubstitute(conference.conferencePresenter)) &&
                                       conference.lessonSession.state !== 2 &&
                                       !conference.lessonSession.stopDate"
                                (click)="closeConference(conference); $event.stopPropagation();"
                                [matTooltip]="'Close' | translate">
                                <mat-icon>close</mat-icon>
                            </button>

                            <button mat-icon-button
                                *ngIf="isAuthor(conference.lessonSession) &&
                                       conference.lessonSession.state === 2 &&
                                       !conference.lessonSession.stopDate"
                                (click)="updateConference(conference.id); $event.stopPropagation();"
                                [matTooltip]="'Edit' | translate">
                                <mat-icon>edit</mat-icon>
                            </button>

                            <button mat-icon-button color="warn"
                                *ngIf="isAuthor(conference.lessonSession) &&
                                       (conference.lessonSession.state === 2 || conference.lessonSession.stopDate)"
                                (click)="deleteConference(conference); $event.stopPropagation();"
                                [matTooltip]="'Delete' | translate">
                                <mat-icon>
                                    delete
                                </mat-icon>
                            </button>

                            <button mat-icon-button 
                                *ngIf="conference.lessonSession.startDate &&
                                       conference.lessonSession.stopDate"
                                (click)="openReport(conference); $event.stopPropagation();"
                                [matTooltip]="'Go to report' | translate">
                                <mat-icon>assessment</mat-icon>
                            </button>

                        </div>
                    </mat-panel-description>

                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>

                    <mat-divider style="margin-bottom: 20px" [horizontal]="true"></mat-divider>

                    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">

                        <div fxLayout="column" fxLayoutGap="20px" fxFlex="15">
                            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
                                <span fxLayoutAlign="start center" fxLayout="column" class="conference-info-title">
                                    {{ 'Presenters' | translate }}
                                </span>
    
                                <div *ngFor="let presenter of classroom?.teacherClassroom; let first = first;" class="tooltip-text-width">
                                    <ng-container *ngTemplateOutlet="userInfo; context:
                                        {
                                            participant: presenter,
                                            cardTooltip: isAuthor(conference.lessonSession) || isSubstitute(conference.conferencePresenter),
                                            highlight: first
                                        }">
                                    </ng-container>
                                </div>       
                            </div>

                            <div *ngIf="conference.lessonSession?.lessonVirtualRoom.length > 0" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
                                <span fxLayoutAlign="start center" fxLayout="column" class="conference-info-title">
                                    {{ 'Virtual rooms' | translate }}
                                </span>

                                <span *ngFor="let room of conference.lessonSession?.lessonVirtualRoom" class="tooltip-text" class="tooltip-text-width">
                                    {{ room.virtualRoom.name }}
                                </span>  
                            </div>
                        </div>

                        <mat-divider class="divider" [vertical]="true"></mat-divider>

                        <div *ngIf="isAuthor(conference.lessonSession) || isSubstitute(conference.conferencePresenter)" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex="75">
                            <span fxLayoutAlign="center center" class="conference-info-title">
                                {{ 'Participants' | translate }}
                            </span>

                            <div style="display: flex; flex-wrap: wrap; gap: 10px; padding: 0px 10px 0px 10px;">
                                <div *ngFor="let participant of classroom?.classroomContent">
                                    <ng-container *ngTemplateOutlet="userInfo; context:
                                        {
                                            participant: participant,
                                            cardTooltip: isAuthor(conference.lessonSession) || isSubstitute(conference.conferencePresenter),
                                            highlight: false
                                        }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showRecording(conference.lessonSession, conference.conferencePresenter)"
                        fxLayout="column"
                        fxLayoutGap="20px"
                        style="margin-top: 20px">

                        <mat-divider [horizontal]="true"></mat-divider>

                        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxFlex>
                            <span class="conference-info-title">{{ 'Recordings' | translate }}</span>
                            <button mat-raised-button color="primary"
                                (click)="goToVideo(conference)"
                                [disabled]="!conference.lessonSession.recordingUrl">
                                <mat-icon>launch</mat-icon> 
                                {{ 'Go to recording' | translate }}
                            </button>
                        </div>

                    </div>

                </ng-template>
                
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div *ngIf="conferences.length === 0" fxLayoutAlign="center center">
        <app-error-message customClass="warning"
            [errorTitle]="'You do not have any conferences' | translate"
            [errorContent]="(isStudent() ? 'There are no active conferences available for you' : 'You can add a conference by clicking on the icons above') | translate"
            [center]="false">
        </app-error-message>
    </div>

    <mat-paginator 
        *ngIf="conferences.length > 0"
        [showFirstLastButtons]="true"
        [length]="conferences.length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]"
        [color]="darkService.isSetDark ? 'accent' : 'primary'"
        (page)="changePage($event)"
        class="mat-elevation-z3">
    </mat-paginator>
</div>

<ng-template #userInfo let-participant="participant" let-cardTooltip="cardTooltip" let-highlight="highlight">
    <span
        (mouseover)="cardTooltip ? showCard(true, participant.user.id) : null"
        (mouseout)="showCard(false)"
        [class.tooltip-highlight]="highlight"
        class="tooltip-text">
        {{ participant.user.name }} {{ participant.user.surname }}                                                                                                                                                                             
    </span>
    <mat-card *ngIf="selectedUser === participant.user.id" appearance="outlined" class="tooltip-card-text">
        <mat-card-header>
            <app-profile-picture
                mat-card-avatar
                [url]="participant.user.profilePictureUrl"
                size="40px">
            </app-profile-picture>
            <mat-card-title>
                {{ participant.user.name }}
            </mat-card-title>
            <mat-card-subtitle>
                {{ participant.user.surname }}
            </mat-card-subtitle>
        </mat-card-header>
        <!--mat-divider style="margin-bottom: 10px"></mat-divider>
        <mat-card-content style="display: flex; flex-direction: column">
            <span>{{ 'Email' | translate }}: {{ selectedUser?.email }}</span>
            <span>{{ 'Username' | translate }}: {{ selectedUser?.username }}</span>
        </mat-card-content-->
    </mat-card>
</ng-template>
